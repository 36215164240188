import {
  eachDay,
  format,
  isPast,
  lastDayOfISOWeek,
  lastDayOfMonth,
  startOfISOWeek
} from 'date-fns'

import {
  CargoDeparture,
  CargoNameListRow,
  CargoJourneyConnectedBookingRow,
  CargoDescription,
  UpdateCargoNameListRowRequest
} from '../api/cargoSelfServiceAPI'
import authStore from '../stores/AuthStore'
import errorStore from '../stores/ErrorStore'
import { Departure } from '../views/infoScreen/types'
import {
  AllotmentStatus,
  emailPattern,
  numberPatternExp,
  regNumberPatternExp,
  strongPasswordPattern
} from './constants'
import initI18n, { LangKeyword, LanguageTypes, t } from '../locales'
import { Parameter } from '../api/externalPaymentsAPI'
import { AxiosError } from 'axios'
import { Action } from '../services/types'

export const isHalf = (availableLength: number, allottedLength: number): boolean =>
  availableLength / allottedLength >= 0.5

export const resolveStatus = (departure: CargoDeparture): string => {
  if (
    !departure ||
    isPast(departure.departureDatetime!) ||
    !departure.customerAllotments ||
    !departure.customerAllotments.allotments
  ) {
    return AllotmentStatus.empty
  }

  const {
    allotments: { highSpaceAllotment }
  } = departure.customerAllotments

  if (highSpaceAllotment && highSpaceAllotment.availableLength === 0) {
    return AllotmentStatus.empty
  } else if (
    highSpaceAllotment &&
    isHalf(
      highSpaceAllotment.availableLength || 0,
      highSpaceAllotment.allottedLength || 0
    )
  ) {
    return AllotmentStatus.full
  }

  return AllotmentStatus.available
}

export const sumObjects = (array: any, property?: string): any => {
  const sumObjectByKey = (object: any, collection: any, key: string) => {
    // Recursive
    if (object[key] === null) {
      return collection[key]
    } else if (Array.isArray(object[key])) {
      return sumObjects(object[key])
    } else if (object[key] && typeof object[key] === 'object') {
      return sumObject(object[key], collection[key])
    }
    // Default
    return (
      (collection[key] || 0) +
      (isNaN(object[key]) ? object[key] : parseInt(object[key], 10))
    )
  }

  const sumObject = (object: any, collection: any = {}) => {
    const temp = {}
    if (property) {
      temp[property] = sumObjectByKey(object, collection, property)
    } else {
      Object.keys(object).forEach((key) => {
        temp[key] = sumObjectByKey(object, collection, key)
      })
    }
    return temp
  }

  return array.reduce((collection: any, object: any) => {
    return sumObject(object, collection)
  }, {})
}

export const isEmail = (email: string): boolean => emailPattern.test(email)

export const isStrongPassword = (password: string): boolean =>
  strongPasswordPattern.test(password)

export const getQueryParams = (queryString: string, needle: string) => {
  return new URLSearchParams(queryString).get(needle)
}

export const firstDayOfMonth = (date: Date) =>
  new Date(date.getFullYear(), date.getMonth())

export const firstDayOfCalendar = (date: Date) =>
  startOfISOWeek(firstDayOfMonth(date))

export const lastDayOfCalendar = (date: Date) =>
  lastDayOfISOWeek(lastDayOfMonth(date))

export const calendarDays = (date: Date) =>
  eachDay(firstDayOfCalendar(date), lastDayOfCalendar(date))

export const countBookingsInDepartures = (departures: CargoDeparture[]) =>
  departures.reduce(
    (sum, departure) => sum + (departure.bookings ? departure.bookings.length : 0),
    0
  )

export const getDate = (ISOString: any): string => {
  const day = ISOString.substring(8, 10)
  const month = ISOString.substring(5, 7)
  const year = ISOString.substring(0, 4)
  return `${day}.${month}.${year}`
}

export const getISODate = (ISOString: any): string => {
  return ISOString.substring(0, 10)
}

export const getTime = (ISOString: any): string => {
  return ISOString.substring(11, 16)
}

export const handleApiError = (error: AxiosError<{ debugMessage?: string }>) => {
  const debugMessage = error.response?.data?.debugMessage
  const statusText = error.response?.statusText
  if (debugMessage) {
    errorStore.add(debugMessage)
  } else if (statusText) {
    errorStore.add(statusText)
  }
  if (error.response?.status === 401) {
    authStore.logout()
  }
  throw error.response?.data
}

export const handleError = async (response: Response) => {
  if (response.ok) {
    return response.json()
  } else if (response.status === 404) {
    return JSON.stringify({})
  } else if (response.status === 401) {
    authStore.logout()
  } else if (response.status === 500) {
    try {
      const json = await response.json()
      if (json && json.debugMessage) {
        errorStore.add(json.debugMessage)
        return JSON.stringify({})
      }
    } catch (e) {
      console.error(e)
    }
  }
  errorStore.add(response.statusText)
  return JSON.stringify({})
}

const notNumberPattern = new RegExp('[^0-9]*', 'g')

export const toNumber = (value: string, min?: number, max?: number): number => {
  const cleaned = Number(value.replace(notNumberPattern, ''))
  if (min !== undefined && cleaned < min) {
    return min
  } else if (max !== undefined && cleaned > max) {
    return max
  }
  return cleaned
}

export const sumCategorySpecificationQuantityByExtCategoryCode =
  (extCategoryCode = '') =>
  (sum: number, row: CargoJourneyConnectedBookingRow) => {
    if (
      row.extCategoryCode === extCategoryCode &&
      row.categorySpecificationQuantity
    ) {
      return sum + row.categorySpecificationQuantity
    }
    return sum
  }

export const saveDriversToLocalStorage = (newDrivers: CargoNameListRow[]) => {
  const savedDrivers = loadDriversFromLocalStorage()

  newDrivers.forEach(
    ({
      firstName,
      lastName,
      dateOfBirth,
      genderCode,
      mobile,
      nationalityExtCode,
      email
    }) => {
      if (!firstName || !lastName) {
        return
      }

      const driverIndex = savedDrivers.findIndex(
        (x) =>
          x.firstName === firstName &&
          x.lastName === lastName &&
          x.dateOfBirth === dateOfBirth
      )

      const driverData = {
        firstName,
        lastName,
        dateOfBirth,
        genderCode,
        mobile,
        nationalityExtCode,
        email
      }

      if (driverIndex === -1) {
        savedDrivers.push(driverData)
      } else {
        savedDrivers[driverIndex] = driverData
      }
    }
  )

  const sortedDrivers = sortDriversByName(savedDrivers)
  localStorage.setItem('drivers', JSON.stringify(sortedDrivers))
}

export const langCode = (language: LanguageTypes) => {
  switch (language) {
    case 'en':
      return 'e'
    case 'fi':
      return 'f'
    default:
      return 'e'
  }
}

export const isTranslated = (keyword: string = ''): keyword is LangKeyword =>
  initI18n.exists(keyword)

const sortDriversByName = (driverList: CargoNameListRow[]) => {
  return driverList.sort((a, b) => {
    if (a.lastName! < b.lastName!) {
      return -1
    }
    if (a.lastName! > b.lastName!) {
      return 1
    }
    if (a.firstName! < b.firstName!) {
      return -1
    }
    return 1
  })
}

export const loadDriversFromLocalStorage = (): CargoNameListRow[] => {
  try {
    return JSON.parse(localStorage.getItem('drivers') || '[]').map(
      ({
        firstName,
        lastName,
        dateOfBirth,
        genderCode,
        mobile,
        nationalityExtCode,
        email
      }: CargoNameListRow) => ({
        firstName,
        lastName,
        dateOfBirth,
        genderCode,
        mobile,
        nationalityExtCode,
        email
      })
    )
  } catch (e) {
    localStorage.setItem('drivers', '[]')
    return []
  }
}

export const makeCancelable = (promise: Promise<any>) => {
  let hasCanceled_ = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
    )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true
    }
  }
}

export const sumMetersByStatus = (
  bookedSpacePerBookingRowStatus: Departure['bookedSpacePerBookingRowStatus']
): { [statusCode: string]: number } => {
  return bookedSpacePerBookingRowStatus.reduce((sum, current) => {
    if (!current.bookingRowStatusCode) {
      return sum
    }
    return {
      ...sum,
      [current.bookingRowStatusCode]:
        (sum[current.bookingRowStatusCode] || 0) + current.bookedCargoSpace.high
    }
  }, {})
}

export const dateToString = (date: Date) => {
  return format(date, 'YYYY-MM-DD')
}

export const countBookings = (departures: { [time: string]: CargoDeparture }) => {
  const count = {
    total: 0,
    OK: 0,
    PS_CB: 0,
    PS_OK: 0,
    PS_RQ: 0,
    PS_CAN: 0,
    NSC: 0
  }

  Object.keys(departures).map((key) => {
    departures[key].bookings!.map((booking) => {
      const { statusCode } = booking
      if (statusCode) {
        count[statusCode]++
        count.total++
      }
      return null
    })
    return null
  })

  return count
}

export const departureToKey = (departure: CargoDeparture): string => {
  const time = getTime(departure.departureDatetime!)
  return `${time}-${departure.departurePortExtCode}`
}

export const keyToTime = (key: string) => {
  return key.split('-')[0]
}

// We don't use the date-fns functionality, because there we can't change the default label units
export const timeDiffCalc = (dateFuture: number, dateNow: number) => {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400)
  diffInMilliSeconds -= days * 86400

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24
  diffInMilliSeconds -= hours * 3600

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60

  let difference = ''
  if (days > 0) {
    difference += days === 1 ? `${days}d` : `${days}ds`

    if (days > 7) {
      difference = '>7ds'
    }

    return difference
  }

  if (hours > 0) {
    difference += hours === 0 || hours === 1 ? `${hours}hr` : `${hours}hrs`
    return difference
  }

  if (minutes > 0) {
    difference += minutes === 0 || hours === 1 ? `${minutes}min` : `${minutes}min`
    return difference
  }

  return difference
}

export const reqErrorHandler = (
  error: any | Error,
  defaultMsg = t('something_went_wrong')
): string => {
  if (error?.debugMessage) {
    return error?.debugMessage
  }

  if (!error || !error?.response || !error?.response?.data || !error?.message)
    return defaultMsg

  if (typeof error.response.data !== 'string') {
    return (
      error.response.data.error ||
      error.response.data.message ||
      error.response.data.msg ||
      defaultMsg
    )
  } else {
    return error.response.data || error.message || defaultMsg
  }
}

export const compareObjects = (o1: Record<string, any>, o2: Record<string, any>) => {
  for (const p in o1) {
    if (o1.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false
      }
    }
  }
  for (const p in o2) {
    if (o2.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false
      }
    }
  }
  return true
}

export const parseToDate = (input: string) => {
  const checkValue = (str: any, max: any) => {
    if (str.charAt(0) !== '0' || str === '00') {
      let num = parseInt(str, 10)
      if (isNaN(num) || num <= 0 || num > max) {
        num = 1
      }
      str =
        num > parseInt(max.toString().charAt(0), 10) && num.toString().length === 1
          ? '0' + num
          : num.toString()
    }
    return str
  }

  if (/\D\.$/.test(input)) {
    input = input.substr(0, input.length - 3)
  }
  const values = input.split('.').map((v) => v.replace(/\D/g, ''))
  if (values[0]) {
    values[0] = checkValue(values[0], 31)
  }
  if (values[1]) {
    values[1] = checkValue(values[1], 12)
  }
  return values
    .map((v, i) => (v.length === 2 && i < 2 ? v + ' . ' : v))
    .join('')
    .substr(0, 14)
}

export const removeCyrillicSymbols = (value: string) =>
  value
    .replace(/[\u0401\u0451\u0410-\u044f]/, '')
    .replace(/[\u0410-\u042F]+.*[\u0410-\u042F]+/iu, '')

export const removeSpecialSymbols = (value: string) =>
  value.replace(regNumberPatternExp, '')

export const validateEmail = (value: string | any): boolean | LangKeyword => {
  const message = 'email_is_not_valid'

  if (typeof value !== 'string') {
    return message
  }
  return isEmail(value) || message
}

export const validatePhoneNumber = (value: string | any) => {
  let isValid: boolean | LangKeyword
  const trimmedValue = ((value as string) || '')?.trim()

  if (!trimmedValue) {
    isValid = true
  } else {
    isValid = numberPatternExp.test(trimmedValue) ? true : 'only_numbers_and_plus'
  }

  if (trimmedValue === '+') {
    isValid = false
  }
  return isValid
}

export const isEmptyNameList = (
  nameList: UpdateCargoNameListRowRequest
): boolean => {
  const { firstName, lastName, genderCode, nationalityExtCode, dateOfBirth } =
    nameList
  return (
    !firstName && !lastName && !genderCode && !nationalityExtCode && !dateOfBirth
  )
}

export const driversToNameList = (
  drivers?: Partial<CargoNameListRow>[]
): CargoNameListRow[] => {
  if (!drivers?.length) {
    return []
  }

  let rowNumber = 0

  return drivers
    .filter((driver) =>
      Object.keys(driver).find(
        (key) => driver[key] && driver[key].length && key !== 'genderCode'
      )
    )
    .reduce((list, driver) => {
      if (isEmptyNameList(driver)) {
        return list
      }

      rowNumber = rowNumber + 1

      const { dateOfBirth = '', ...rest } = driver

      return [
        ...list,
        {
          ...rest,
          guestTypeCode: 'D', // Default type "Driver"
          dateOfBirth: dateOfBirth.split(' . ').reverse().join('-'),
          rowNumber
        }
      ]
    }, [] as CargoNameListRow[])
}

export const nameListToDrivers = (
  drivers?: CargoNameListRow[]
): CargoNameListRow[] => {
  return drivers?.length
    ? drivers.map(({ dateOfBirth = '', ...rest }) => ({
        ...rest,
        dateOfBirth: (dateOfBirth || '').split('-').reverse().join(' . ')
      }))
    : []
}

export const getPaytrailCompatibleLocale = (locale: string) => {
  switch (locale) {
    case 'fi':
      return 'fi_FI'
    default:
      return 'en_US'
  }
}

export const getParameterArrayFromURLParameters = (
  parameterString: string
): Parameter[] => {
  if (parameterString.charAt(0) === '?') {
    parameterString = parameterString.substring(1)
  }

  const stringPairs = parameterString.split('&')
  return stringPairs.map((pair) => {
    const [name, value] = pair.split('=')
    return { name, value }
  })
}

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const getQueryAction = (search: string): Action => {
  const action = getQueryParams(search, 'action')

  switch (action) {
    case Action.Edit:
      return Action.Edit
    case Action.Copy:
      return Action.Copy
    case Action.Move:
      return Action.Move
    case Action.New:
    default:
      return Action.New
  }
}

export const pickByKeyValues = <T>(
  items: T[],
  key: string,
  values: string[]
): [T[], T[]] => {
  return items.reduce(
    ([left, right], item) => {
      if (values.includes(item[key])) {
        return [[...left, item], right]
      }
      return [left, [...right, item]]
    },
    [[], []] as [T[], T[]]
  )
}

export const isAfterDays = (date: Date, days: number = 14) => {
  const today = new Date()
  const tomorrow = new Date()
  tomorrow.setHours(23)
  tomorrow.setMinutes(59)
  tomorrow.setDate(today.getDate() + days)

  return date > tomorrow
}

export const handleCargoDescriptions = (
  cargoDescription: string,
  options: {
    isUpdate?: boolean
    oldDescriptions?: CargoDescription[]
    grossWeight?: number
  }
): CargoDescription[] => {
  const { isUpdate = false, oldDescriptions, grossWeight } = options
  return [
    {
      grossWeight,
      cargoDescription,
      descriptionRowNumber:
        isUpdate && oldDescriptions && oldDescriptions.length ? 1 : undefined
    }
  ]
}
