import React from 'react'
import BaseInput from '../Form/BaseInput'
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  FieldValues,
  Path,
  UseFormStateReturn,
  UseControllerProps
} from 'react-hook-form'
import { FormLabelProps, InputProps } from '@chakra-ui/react'
import { extractErrorStatus } from '../../helpers/forms'
import { FieldPathValue } from 'react-hook-form/dist/types/path'

type Props<T extends FieldValues> = UseControllerProps<T, Path<T>> & {
  disabled?: boolean
  progressIcon?: boolean
  label?: React.ReactNode
  labelProps?: FormLabelProps
  placeholder?: string
  helperText?: string
  setValueAs?: (value: string) => FieldPathValue<T, Path<T>>
  children?: (props: {
    field: ControllerRenderProps<T, Path<T> & string>
    fieldState: ControllerFieldState
    formState: UseFormStateReturn<T>
    errorMessage: string
  }) => React.ReactElement
} & Omit<InputProps, 'children'>

const FormInput = <T extends FieldValues>(props: Props<T>) => {
  const {
    name,
    disabled,
    progressIcon,
    placeholder,
    helperText,
    label,
    control,
    rules,
    setValueAs,
    children,
    labelProps,
    ...rest
  } = props

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field: { onChange, ...field }, fieldState, formState }) => {
        const [errorMessage, isInvalid] = extractErrorStatus(fieldState)

        const onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
          return setValueAs
            ? onChange(setValueAs(event.currentTarget.value))
            : onChange(event.currentTarget.value)
        }

        if (typeof children === 'function') {
          return children({
            field: { ...field, onChange: onInputChange },
            fieldState,
            formState,
            errorMessage
          })
        }

        return (
          <BaseInput
            labelProps={labelProps}
            isRequired={!!rules?.required}
            disabled={disabled}
            progressIcon={progressIcon}
            isInvalid={isInvalid}
            placeholder={placeholder}
            helperText={errorMessage || helperText}
            label={label}
            {...field}
            {...rest}
            value={field.value ?? ''}
            onChange={onInputChange}
          />
        )
      }}
    />
  )
}

export default FormInput
