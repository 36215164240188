import { observer } from 'mobx-react'
import reservationStore from '../../../../stores/ReservationStore'
import { useTranslation } from 'react-i18next'
import css from '../../ReservationContainer.module.scss'
import Container from '../../../../components/Container/Container'
import { MdHotel, MdRestaurant, MdRoomService } from 'react-icons/md'
import React from 'react'
import ExtraServices from '../ExtraServices/ExtraServices'

const DriverServices = observer(() => {
  const { cabins, meals, services, productRestrictions } = reservationStore
  const { t } = useTranslation()

  const hasCabins = cabins.length > 0
  const hasMeals = meals.length > 0
  const hasServices = services.length > 0

  if (!hasCabins && !hasMeals && hasServices) {
    return null
  }

  return (
    <div>
      <div className={css.subtitle}>{t('services')}</div>
      <br />
      <div className={css.flexWrapper}>
        {hasCabins && (
          <Container
            icon={<MdHotel />}
            title={t('cabin')}
            auto
            isActive
            disabled={productRestrictions.includes('CABINS')}
            fill
          >
            <ExtraServices items={cabins} />
          </Container>
        )}
        {hasMeals && (
          <Container
            icon={<MdRestaurant />}
            title={t('meals')}
            auto
            isActive
            disabled={productRestrictions.includes('MEALS')}
            fill
          >
            <ExtraServices items={meals} />
          </Container>
        )}
        {hasServices && (
          <Container
            icon={<MdRoomService />}
            title={t('other_services')}
            auto
            isActive
            disabled={productRestrictions.includes('SERVICES')}
            fill
          >
            <ExtraServices items={services} />
          </Container>
        )}
      </div>
      <div className={css.line} />
    </div>
  )
})

export default DriverServices
