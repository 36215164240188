import React, { FunctionComponent } from 'react'
import { useParams, Link as ReactLink } from 'react-router-dom'
import View from '../../components/View/View'
import { Alert, AlertIcon, AlertTitle, Flex, Link, Text } from '@chakra-ui/react'
import useQuery from '../../hooks/useQuery'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import userStore from '../../stores/UserStore'

const ConfirmationContainer: FunctionComponent = observer(() => {
  const { bookingCode } = useParams<{ bookingCode: string }>()

  const query = useQuery()
  const { t } = useTranslation()

  const computeSendingStatus = (
    name: string,
    { success, error }: { success: string; error: string }
  ) => {
    const sent = query.has(name) ? query.get(name) === 'true' : undefined

    if (sent === undefined) return

    return (
      <Text
        align="center"
        color={sent ? 'green.300' : 'red.300'}
        fontSize="sm"
        my={1}
      >
        {sent ? success : error}
      </Text>
    )
  }

  return (
    <View title={t('reservation_confirmation')}>
      <Flex h="100%" justifyContent="center">
        <Alert
          status="success"
          bg="inherit"
          maxW="600px"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="2xl">
            {t('reservation_confirmation_title')}
          </AlertTitle>
          <Text align="center" fontSize="xl" my={3}>
            {t('reservation_confirmation_body')}
          </Text>
          {computeSendingStatus('smsSent', {
            success: t('reservation_sms_sent'),
            error: t('reservation_sms_not_sent')
          })}
          {computeSendingStatus('emailSent', {
            success: t('reservation_email_sent'),
            error: t('reservation_email_not_sent')
          })}
          <Text align="center" fontSize="xl">
            {t('reservation_code')}:{' '}
            <strong>
              <Link
                color="baseBlue"
                as={ReactLink}
                to={`/confirmation?action=edit&bookingCode=${bookingCode}`}
              >
                {bookingCode}
              </Link>
            </strong>
          </Text>
        </Alert>
      </Flex>
    </View>
  )
})

export default ConfirmationContainer
