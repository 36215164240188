import { CargoExtCategory } from '../api/cargoSelfServiceAPI'

export enum Action {
  Edit = 'edit',
  New = 'new',
  Copy = 'copy',
  Move = 'move'
}

export interface ExtraService extends CargoExtCategory {
  quantity: number
}
