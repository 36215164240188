import classNames from 'classnames'
import {
  addDays,
  addMonths,
  eachDay,
  format,
  isSameDay,
  isSameMonth,
  startOfISOWeek
} from 'date-fns'
import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'

import { calendarDays } from '../../utils/helpers'
import css from './Calendar.module.css'
import Day from './components/Day'
import { useTranslation } from 'react-i18next'
import { useUpdateEffect } from '@chakra-ui/react'

type Props = {
  selectedDate: Date
  onSelectDate: (date: Date) => void
  onSelectMonth: (month: Date) => void
  days?: React.ReactNode[]
  isLoading?: boolean
  defaultMonth?: Date
}

const Calendar: FunctionComponent<Props> = ({
  selectedDate,
  onSelectDate,
  onSelectMonth,
  days,
  isLoading,
  defaultMonth = new Date(new Date().getFullYear(), new Date().getMonth())
}) => {
  const { t } = useTranslation()
  const [currentMonth, setCurrentMonth] = useState(defaultMonth)

  const renderHeader = () => {
    const date: Date = startOfISOWeek(0)
    const days = eachDay(date, addDays(date, 6))

    return days.map((day, i) => (
      <div key={i} className={css.day}>
        {format(day, 'dd')}
      </div>
    ))
  }

  const getDays = (): Date[] => {
    return calendarDays(currentMonth)
  }

  const handleSelectDate = (date: Date) => {
    onSelectDate(date)
  }

  const renderDays = (): ReactNode[] => {
    const daysToRender = getDays()
    return daysToRender.map((date, index) => (
      <Day
        key={index}
        date={date}
        isSelected={isSameDay(date, selectedDate)}
        isVisible={isSameMonth(currentMonth, date)}
        onSelectDate={() => handleSelectDate(date)}
      >
        {days && days.length >= index ? days[index] : null}
      </Day>
    ))
  }

  const addMonth = (difference: number) => {
    const newMonth = addMonths(currentMonth, difference)
    setCurrentMonth(newMonth)
    onSelectMonth(newMonth)
  }

  const onPrevMonth = () => {
    addMonth(-1)
  }

  const onNextMonth = () => {
    addMonth(1)
  }

  useEffect(() => {
    setCurrentMonth(defaultMonth)
  }, [defaultMonth])

  return (
    <div
      className={classNames(css.component, {
        [css.isLoading]: isLoading
      })}
    >
      <div className={css.controls}>
        <MdKeyboardArrowLeft onClick={onPrevMonth} className={css.arrow} />
        <span>{format(currentMonth, t('month_format'))}</span>
        <MdKeyboardArrowRight onClick={onNextMonth} className={css.arrow} />
      </div>
      <div className={css.header}>{renderHeader()}</div>
      <div className={css.days}>{renderDays()}</div>
    </div>
  )
}

export default Calendar
