import classNames from 'classnames'
import { format, isPast } from 'date-fns'
import { observer } from 'mobx-react'
import React from 'react'

import ErrorStore from '../../stores/ErrorStore'
import reservationStore from '../../stores/ReservationStore'
import occasionalCustomerStore from '../../stores/OccasionalCustomerStore'
import { getTime } from '../../utils/helpers'
import { DownTimer } from '../DownTimer'
import Radio from '../Radio/Radio'
import css from './Departures.module.css'
import { FaCircle } from 'react-icons/fa'
import { Icon } from '@chakra-ui/react'
import PriceList from './PriceList'
import { useTranslation } from 'react-i18next'

const DeparturesOccasional = observer(() => {
  const { portDepartures } = occasionalCustomerStore
  const { departures: reservationDepartures } = reservationStore
  const { t } = useTranslation()

  const departures = reservationDepartures.map((departure, index) => {
    const {
      journeyCode,
      departureCode,
      departurePortExtCode,
      customerAllotments,
      departureDatetime
    } = departure

    const portName =
      reservationStore.findPortByCode(departurePortExtCode || '').portName || ''

    const releaseTime = customerAllotments?.releaseTime

    const isPastDeparture = isPast(new Date(departureDatetime!))

    const departureAllotment = portDepartures.find(
      (portDeparture) =>
        portDeparture.departureDate === departure.departureDatetime &&
        departure.departurePortExtCode === portDeparture.departurePortCode
    )

    // TODO: resolve the status using the availableCargoSpace
    const availableCargoSpace = departureAllotment?.availableCargoSpace?.high || 0
    const isDepartureActive = availableCargoSpace > 0 && !isPastDeparture

    return (
      <label
        key={index}
        className={classNames(css.item, {
          [css.selected]: reservationStore.departureCode === departureCode,
          [css.empty]: !isDepartureActive
        })}
      >
        <span className={classNames(css.time)}>
          {getTime(departureDatetime!)} &nbsp;
          <span>{portName}</span>
          {departureCode === reservationStore.bookedDeparture && (
            <span>&nbsp; Current</span>
          )}
        </span>

        {releaseTime ? (
          <DownTimer
            timeTo={releaseTime as unknown as Date}
            className={css.downTimer}
          />
        ) : (
          <span className={css.downTimer}>--</span>
        )}

        <Icon
          as={FaCircle}
          w={3}
          h={3}
          fill={isDepartureActive ? 'baseGreen' : 'baseGray'}
        />
        <Radio
          value={departureCode}
          checked={reservationStore.departureCode === departureCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            reservationStore.setJourneyCode(journeyCode || '')
            reservationStore.setSelectedDeparture(e.target.value)
          }}
          disabled={
            departureCode !== reservationStore.bookedDeparture &&
            (isPastDeparture || ErrorStore.isLoading)
          }
        />
      </label>
    )
  })

  return (
    <div
      className={classNames(css.select, {
        [css.isLoading]: ErrorStore.isLoading
      })}
    >
      <div className={css.selectHeader}>{t('departures')}</div>
      <div className={css.selectDate}>
        {format(reservationStore.selectedDate, t('date_format'))}
      </div>
      <ul className={css.list}>{departures}</ul>
      <PriceList />
    </div>
  )
})

export default DeparturesOccasional
