import { action, computed, makeAutoObservable, observable } from 'mobx'
import toast from '../helpers/toast'

export enum ErrorLevel {
  warning = 'warning',
  error = 'error',
  info = 'info'
}

export interface Error {
  message: string
  level: ErrorLevel
  title?: string
  isClosed?: boolean
}

const NOTIFICATION_ID = 'NOTIFICATION_ID'

// TODO rename to NotificationStore
class ErrorStore {
  @observable errors: { [id: string]: Error } = {}
  @observable loading: number = 0
  @observable isSaving: boolean = false

  timer?: number
  notificationTimer?: number

  @computed
  get isLoading(): boolean {
    return this.loading > 0
  }

  constructor() {
    this.fetchNotification()
    makeAutoObservable(this)
  }

  fetchNotification = () => {
    fetch('/admin/public/notifications')
      .then((r) => r.json())
      .then((data) => {
        if (!data.title && !data.body) {
          this.clear(NOTIFICATION_ID)
          setTimeout(() => this.fetchNotification(), 5000)
        } else {
          this.addNotification(data, ErrorLevel.info, false, true)
        }
      })
      .catch((error) => {
        this.add(error.message, ErrorLevel.warning)
      })
  }

  @action
  addLoader = () => {
    this.loading++
  }

  @action
  removeLoader = () => {
    this.loading--
  }

  @action
  add = (message: string, level: ErrorLevel = ErrorLevel.error) => {
    const toastId = `${message} - ${level}`
    if (toast.isActive(toastId)) return
    toast({ description: message, status: level, id: toastId })
  }

  @action
  addNotification = (
    data: { title: string; body: string },
    level: ErrorLevel = ErrorLevel.error,
    autoClose = false,
    keepClosed = true
  ) => {
    const { title, body: message } = data
    const { [NOTIFICATION_ID]: error, ...rest } = this.errors

    this.errors = {
      ...rest,
      [NOTIFICATION_ID]: {
        title,
        message,
        level,
        ...(keepClosed ? { isClosed: error && error.isClosed } : {})
      }
    }

    this.notificationTimer = window.setTimeout(() => {
      if (autoClose) {
        if (keepClosed) {
          this.close(NOTIFICATION_ID)
        } else {
          this.clear(NOTIFICATION_ID)
        }
      }
      this.fetchNotification()
    }, 5000)
  }

  @action
  clear = (id: string) => {
    const { [id]: _, ...rest } = this.errors
    this.errors = rest
  }

  @action
  close = (id: string) => {
    const { [id]: error, ...rest } = this.errors
    this.errors = {
      ...rest,
      [id]: { ...error, isClosed: true }
    }
  }

  @action
  setSaving = (saving: boolean) => {
    this.isSaving = saving
  }

  @computed
  get length() {
    return Object.keys(this.errors).length
  }

  map = (callback: (error: Error, id: string) => any) => {
    return Object.keys(this.errors).map((id) => {
      return callback(this.errors[id], id)
    })
  }
}

export default new ErrorStore()
