import { addMonths } from 'date-fns'
import { observer } from 'mobx-react'
import React from 'react'

import SearchForm from '../../components/Search/SearchForm'
import View from '../../components/View/View'
import { withTranslation, WithTranslation } from 'react-i18next'
import reservationsStore from '../../stores/ReservationsStore'
import SearchStore from '../../stores/SearchStore'
import DepartureGroup from './components/departureGroup/DepartureGroup'
import SearchResultsContainer from './components/searchResults/SearchResultsContainer'
import css from './ReservationsContainer.module.css'

type State = {
  activeIndex: number
  activeDate: string
}

@observer
class ReservationsContainer extends React.Component<WithTranslation, State> {
  state = {
    activeIndex: 0,
    activeDate: ''
  }

  componentDidMount() {
    reservationsStore.fetchDeparturePorts()
    this.setDepartureDates()
    reservationsStore.registerAutorun()
    SearchStore.reset()
    SearchStore.registerAutorun()
  }

  componentWillUnmount() {
    reservationsStore.disposeAutorun()
    SearchStore.disposeAutorun()
  }

  setDepartureDates() {
    const from = addMonths(new Date(), -1)
    const to = addMonths(new Date(), 1)
    reservationsStore.setFrom(from)
    reservationsStore.setTo(to)
  }

  resolveActiveIndex(activeDate: string): string {
    if (this.state.activeDate === activeDate) {
      return ''
    }
    return activeDate
  }

  toggleActive = (activeDate: string) => {
    this.setState({
      activeDate: this.resolveActiveIndex(activeDate)
    })
  }

  render() {
    const { groupedPastBookings, groupedFutureBookings } = reservationsStore
    const { t } = this.props
    return (
      <View title={t('reservations')}>
        <div className={css.container}>
          <div className={css.header}>
            <div className={css.headerButtons}>
              <SearchForm />
            </div>
          </div>
          {SearchStore.criteriaAsString && (
            <div>
              <div className={css.header}>
                <h1 className={css.title}>Search results</h1>
              </div>
              <SearchResultsContainer />
            </div>
          )}
          <div>
            <div className={css.header}>
              <h1 className={css.title}>{t('current_reservations')}</h1>
            </div>
            <DepartureGroup
              departures={groupedFutureBookings}
              activeDate={this.state.activeDate}
              onClick={(date) => {
                this.toggleActive(date)
              }}
            />
          </div>
          <div>
            <div className={css.header}>
              <h1 className={css.title}>{t('past_reservations')}</h1>
            </div>
            <DepartureGroup
              pastReservations
              departures={groupedPastBookings}
              activeDate={this.state.activeDate}
              onClick={(date) => {
                this.toggleActive(date)
              }}
            />
          </div>
        </div>
      </View>
    )
  }
}

export default withTranslation()(ReservationsContainer)
