import { observer } from 'mobx-react'
import React from 'react'

import { isTranslated } from '../../utils/helpers'
import reservationsStore from '../../stores/ReservationsStore'
import Button from '../Button/Button'
import css from './DeparturePortButtons.module.css'
import { useTranslation } from 'react-i18next'

const DeparturePortButtons = observer(() => {
  const { t } = useTranslation()

  const handleClick = (isSelected: boolean, countryCode: string) => {
    if (isSelected) {
      return () => {
        reservationsStore.removeDepartureCountry(countryCode)
      }
    }
    return () => {
      reservationsStore.addDepartureCountry(countryCode)
    }
  }

  return (
    <div className={css.container}>
      {reservationsStore.departureCountries.map((countryCode, index) => {
        const isSelected = reservationsStore.isDepartureCountrySelected(countryCode)
        return (
          <Button
            key={index}
            onClick={handleClick(isSelected, countryCode)}
            inverted={!isSelected}
          >
            {isTranslated(countryCode) ? <>{t(countryCode)}</> : countryCode}
          </Button>
        )
      })}
    </div>
  )
})

export default DeparturePortButtons
