import { observer } from 'mobx-react'
import React from 'react'

import Button from '../../../../components/Button/Button'
import { EDIT } from '../../../../utils/constants'
import ReservationComponentProps from '../../ReservationComponentProps'
import css from '../../ReservationContainer.module.scss'
import {
  Control,
  useFieldArray,
  UseFieldArrayUpdate,
  useFormContext
} from 'react-hook-form'
import { CargoNameListRow } from '../../../../api/cargoSelfServiceAPI'
import {
  driverDefaultValues,
  ReservationFormValues
} from '../../ReservationContainer'
import userStore from '../../../../stores/UserStore'
import ErrorStore from '../../../../stores/ErrorStore'
import { useTranslation } from 'react-i18next'
import DriverDetails from './DriverDetails'
import DriverServices from './DriverServices'

export type DriverListFormValues = { drivers: CargoNameListRow[] }

export type Props = ReservationComponentProps & { isInvalid: boolean }

const DriverAndServices = observer((props: Props) => {
  const {
    id,
    action,
    onMakeReservation,
    toggleActive,
    isInvalid: isDriverListInvalid
  } = props
  const {
    control,
    watch,
    formState: { isValid }
  } = useFormContext<ReservationFormValues>()
  const { t } = useTranslation()

  const watchFieldArray: CargoNameListRow[] = watch('drivers') || []

  const { fields, append, remove, update } = useFieldArray<
    ReservationFormValues,
    'drivers'
  >({
    control,
    name: 'drivers'
  })

  const addDriver = () => append(driverDefaultValues)

  const controlledFields = fields.map(({ id }, index) => ({
    id,
    ...watchFieldArray[index]
  }))

  const driverList = controlledFields.map((field, id) => (
    <DriverDetails
      id={id}
      key={field.id}
      driver={field}
      driverId={field.id}
      control={control}
      last={controlledFields.length - 1}
      removeDriver={
        // Allow remove if more than one driver
        controlledFields.length > 1 ? (id: number) => remove(id) : undefined
      }
      addDriver={addDriver}
      updateDriver={update}
    />
  ))

  return (
    <div className={css.componentBodyWrapper}>
      <div>{driverList}</div>
      <DriverServices />
      <div className={css.next}>
        {!userStore.isOccasional && action === EDIT && (
          <Button
            onClick={onMakeReservation}
            disabled={!isValid || ErrorStore.isLoading}
          >
            {t('save_changes')}
          </Button>
        )}
        <Button disabled={isDriverListInvalid} onClick={() => toggleActive(id + 1)}>
          {t('next')}
        </Button>
      </div>
    </div>
  )
})

export type DriverDetailsProps = {
  id: number
  driver: CargoNameListRow
  driverId: string
  control: Control<ReservationFormValues>
  last: number
  addDriver: () => void
  updateDriver: UseFieldArrayUpdate<ReservationFormValues, 'drivers'>
  removeDriver: ((id: number) => void) | undefined
}

export default DriverAndServices
