import {
  CargoAvailabilityMiscAvailableStatusCode,
  CargoAvailabilityMisc,
  CargoAvailabilitySearchResult,
  CargoBooking,
  UpdateCargoJourneyConnectedBookingRowRequest,
  CargoDeparture,
  CargoExtCategory,
  UpdateCargoRegistrationNumberRequest
} from '../api/cargoSelfServiceAPI'
import {
  CargoRegistrationNumber,
  CargoVehicleRegistrationNumberInfo,
  DriverCargoBooking,
  DriverCargoDescription,
  UpdateDriverBookingRequest
} from '../api/driverUpdateAPI'
import { driversToNameList } from './helpers'
import { CargoForm } from '../views/driverUpdate/DriverUpdateContainer'
import { format } from 'date-fns'

const getBookingPrice = (booking: CargoBooking) => {
  const totalPrice = booking?.cargoJourneys?.[0]?.journeyPrice || 0
  const priceToPay = booking.bookingBalance || 0

  const journeyBookingCustomerPrice =
    booking.cargoJourneys?.[0]?.journeyBookingRows?.[0]?.customerPrice || 0

  const serviceTotalPrice =
    booking.cargoJourneys?.[0]?.journeyBookingRows?.[0]?.connectedBookingRows?.reduce(
      (sum, row) => (row?.customerPrice || 0) + sum,
      0
    ) || 0

  const calculatedTotalPrice = journeyBookingCustomerPrice + serviceTotalPrice

  return {
    totalPrice,
    priceToPay,
    calculatedTotalPrice,
    journeyBookingCustomerPrice,
    serviceTotalPrice
  }
}

type PriceFromAvailability = {
  vehicleExtCategoryCode: string
  availability: CargoAvailabilitySearchResult
}

const getPriceFromAvailability = ({
  vehicleExtCategoryCode,
  availability
}: PriceFromAvailability) => {
  const journeyBookingCustomerPrice =
    availability.vehicleAvailability?.vehicle?.find(
      (vehicle) => vehicle.extCategoryCode === vehicleExtCategoryCode
    )?.displayRowPrice || 0

  const miscSum =
    availability?.miscAvailability?.misc?.reduce(
      (sum, misc) => (misc?.displayRowPrice || 0) + sum,
      0
    ) || 0

  return { journeyBookingCustomerPrice, miscSum }
}

type BookingRegistrationNumbersProps = {
  vehicleRN?: string
  trailerRN?: string
  vehicleCountryCode?: string
  trailerCountryCode?: string
}

const handleBookingRegistrationNumbers = (
  oldRegNumbers: CargoRegistrationNumber[] = [],
  props: BookingRegistrationNumbersProps
): CargoVehicleRegistrationNumberInfo[] => {
  const { vehicleRN, trailerRN, trailerCountryCode, vehicleCountryCode } = props

  const parsedVehicleRN = vehicleRN?.replace(/\s/g, '')
  const parsedTrailerRN = trailerRN?.replace(/\s/g, '')
  const newRegNumbers: CargoVehicleRegistrationNumberInfo[] = []

  const { vehicle, trailer } = decipherBookingRegNumbers(oldRegNumbers)

  if (parsedVehicleRN) {
    newRegNumbers.push({
      driversNameListRowNumber: vehicle?.driversNameListRowNumber,
      driverInfo: vehicle?.driverInfo,
      primary: true,
      stateCode: vehicle?.registrationStateCode,
      driverNote: vehicle?.driverNote,
      countryCode: vehicleCountryCode || undefined,
      value: parsedVehicleRN
    })
  }

  if (parsedTrailerRN) {
    newRegNumbers.push({
      driversNameListRowNumber: trailer?.driversNameListRowNumber,
      driverInfo: trailer?.driverInfo,
      primary: false,
      stateCode: trailer?.registrationStateCode,
      driverNote: trailer?.driverNote,
      countryCode: trailerCountryCode || undefined,
      value: parsedTrailerRN
    })
  }

  return newRegNumbers
}

export const convertToCargoRegNumbers = (
  regNumbers: CargoVehicleRegistrationNumberInfo[] = []
): UpdateCargoRegistrationNumberRequest[] => {
  return regNumbers.map((regNumber) => {
    return {
      driversNameListRowNumber: regNumber.driversNameListRowNumber,
      driverInfo: regNumber.driverInfo,
      driverNote: regNumber.driverNote,
      registrationNumberCode: regNumber.value,
      primary: regNumber.primary,
      registrationCountryCode: regNumber.countryCode,
      registrationStateCode: regNumber.stateCode
    }
  })
}

export type TypeDecipherBookingRegNumbers = ReturnType<
  typeof decipherBookingRegNumbers
>

export const decipherBookingRegNumbers = (
  registrationNumbers?: CargoRegistrationNumber[]
) => {
  const vehicle: {
    vehicleRegNumber?: string
    trailerRegNumber?: string
    vehicle?: CargoRegistrationNumber
    trailer?: CargoRegistrationNumber
  } = {}

  if (!registrationNumbers || !registrationNumbers.length) {
    return vehicle
  }

  const truckIndex = registrationNumbers.findIndex((x) => x.primary === true)
  if (truckIndex !== -1) {
    vehicle.vehicleRegNumber = registrationNumbers[truckIndex].registrationNumberCode
    vehicle.vehicle = registrationNumbers[truckIndex]
    vehicle.vehicle.primary = true

    registrationNumbers.splice(truckIndex, 1)

    if (registrationNumbers.length) {
      vehicle.trailerRegNumber = registrationNumbers[0].registrationNumberCode || ''
      vehicle.trailer = registrationNumbers[0]
    }
    return vehicle
  }

  vehicle.vehicleRegNumber = registrationNumbers[0]
    ? registrationNumbers[0].registrationNumberCode
    : ''
  vehicle.vehicle = registrationNumbers[0]
  vehicle.vehicle.primary = true

  if (registrationNumbers[1]) {
    vehicle.trailerRegNumber = registrationNumbers[1].registrationNumberCode || ''
    vehicle.trailer = registrationNumbers[1]
  }

  return vehicle
}

const isValidPrice = (price?: number): price is number => typeof price === 'number'

const handleCargoDescriptions = (
  cargoDescription: string,
  options: {
    isUpdate?: boolean
    oldDescriptions?: DriverCargoDescription[]
    loadWeight: number
  }
): DriverCargoDescription[] => {
  const { isUpdate = false, oldDescriptions, loadWeight } = options

  if (!cargoDescription) {
    return []
  }

  const [oldDescription] = oldDescriptions || []

  return [
    {
      cargoDescription,
      descriptionRowNumber:
        isUpdate && oldDescription ? oldDescription.descriptionRowNumber : undefined,
      grossWeight: loadWeight
    }
  ]
}

const handleNewInternalNote = (previousNote?: string, newNote?: string) => {
  if (!newNote) {
    return previousNote
  }
  if (!previousNote) {
    return newNote
  }
  return previousNote + ` || /n${newNote}`
}

const generateBookingUpdateBody = ({
  booking,
  cargoForm
}: {
  booking: CargoBooking | DriverCargoBooking
  cargoForm: CargoForm
}): UpdateDriverBookingRequest => {
  const {
    externalNote,
    internalNote,
    cargoJourneys,
    bookingReference,
    bookingVersionCode
  } = booking

  const {
    loadWeight,
    cargoType,
    trailerRN,
    vehicleRN,
    drivers,
    vehicleRegistrationNumberCountryCode,
    trailerRegistrationNumberCountryCode,
    consignee
  } = cargoForm

  const journeyBookingRow = cargoJourneys?.[0]?.journeyBookingRows?.[0]
  const vehicleProps = journeyBookingRow?.vehicleProperties

  const cargoVehicleRegNumberInfo: CargoVehicleRegistrationNumberInfo[] =
    handleBookingRegistrationNumbers(vehicleProps?.registrationNumbers || [], {
      vehicleRN,
      vehicleCountryCode: vehicleRegistrationNumberCountryCode,
      trailerRN,
      trailerCountryCode: trailerRegistrationNumberCountryCode
    })

  const currentDate = format(new Date(), `DD.MM.YYYY HH:mm.ss`)
  const newInternalNote = `Driver approved dangerous good and that he answered truthfully at ${currentDate}`

  return {
    namelist: driversToNameList(drivers).map((driver) => {
      Object.keys(driver).forEach((key) => {
        driver[key] =
          typeof driver[key] === 'string' && !driver[key].trim() ? null : driver[key]
      })
      return driver
    }),
    journey: {
      rowNumber: cargoJourneys?.[0]?.journeyRowNumber || 1,
      bookingRows: [
        {
          cargoDescriptions: handleCargoDescriptions(cargoType, {
            isUpdate: !!vehicleProps?.cargoDescriptions?.length,
            oldDescriptions: vehicleProps?.cargoDescriptions,
            loadWeight
          }),
          rowNumber: journeyBookingRow?.bookingRowNumber,
          driverCount: drivers.length,
          loadWeight,
          externalNote,
          internalNote: journeyBookingRow?.internalNote,
          length: vehicleProps?.length,
          referenceNumber: bookingReference,
          registrationNumbers: cargoVehicleRegNumberInfo,
          height: vehicleProps?.height,
          tareWeight: vehicleProps?.tareWeight,
          width: vehicleProps?.width,
          consignee: {
            value: consignee
          }
        }
      ]
    },
    lastModifiedTime: booking.bookingLastChangeTime!,
    latestBookingVersionNumber: bookingVersionCode,
    referenceNumber: bookingReference,
    externalNote,
    internalNote: handleNewInternalNote(internalNote, newInternalNote)
  }
}

const handlePaperService = (
  misc?: CargoAvailabilityMisc[],
  cargoDescription?: string
): UpdateCargoJourneyConnectedBookingRowRequest[] => {
  if (!misc || !misc.length || cargoDescription !== 'Paper') {
    return []
  }

  const tav2 = misc.find((misc) => misc.extCategoryCode === 'TAV2')
  if (
    tav2?.availableStatusCode !== CargoAvailabilityMiscAvailableStatusCode.AVAILABLE
  ) {
    return []
  }
  return [
    {
      extCategoryCode: 'TAV2',
      miscProperties: { categoryQuantity: 1 },
      vehicleProperties: { categoryQuantity: 1, tareWeight: 1 }
    }
  ]
}

type UpdateBookingProps = { booking?: CargoBooking | DriverCargoBooking }

const isBookingUpdatable = ({ booking }: UpdateBookingProps) => {
  const bookingRows = booking?.cargoJourneys?.[0]?.journeyBookingRows
  const bookingStatusIsNotNOCHG = booking?.bookingStatusCode !== 'NO_CHG'

  const isOutOfRange =
    bookingRows?.some(({ extCategoryCode }) =>
      extCategoryCode?.toUpperCase().includes('OOG')
    ) ?? false

  const containsDangerousCargo = Boolean(
    booking?.hasDangerousGoods ||
      bookingRows?.some((row) => row.vehicleProperties?.imdgs?.length)
  )

  const isUpdatable = Boolean(
    booking?.bookingControlFlags?.isUpdateAllowed &&
      !containsDangerousCargo &&
      !isOutOfRange &&
      bookingStatusIsNotNOCHG
  )

  const isCancelAllowed = Boolean(
    booking?.bookingControlFlags?.isCancelAllowed &&
      !containsDangerousCargo &&
      bookingStatusIsNotNOCHG
  )

  return { containsDangerousCargo, isUpdatable, isOutOfRange, isCancelAllowed }
}

export type CalculateVehicleMaxLengthProps = {
  isOccasional: boolean
  vehicleTemplate?: CargoExtCategory
  selectedDeparture?: CargoDeparture
  booking?: CargoBooking
}
const getAvailableAllotment = ({
  selectedDeparture
}: Pick<CalculateVehicleMaxLengthProps, 'selectedDeparture'>) => {
  return (
    selectedDeparture?.customerAllotments?.allotments?.highSpaceAllotment
      ?.availableLength || 0
  )
}
const calculateVehicleMaxLength = ({
  vehicleTemplate,
  selectedDeparture,
  booking,
  isOccasional
}: CalculateVehicleMaxLengthProps) => {
  const bookingLength =
    booking?.cargoJourneys?.[0]?.journeyBookingRows?.[0]?.vehicleProperties?.length
  const vehicleDefaultMaxLength = vehicleTemplate?.defaults?.lengthDefaults?.maxValue
  const availableLengthOnDeparture = getAvailableAllotment({ selectedDeparture })
  const allotmentAvailable = (bookingLength || 0) + availableLengthOnDeparture

  if (isOccasional) {
    if (!vehicleTemplate || !vehicleTemplate.defaults) {
      return 0
    }
    return vehicleDefaultMaxLength || 0
  }
  if (vehicleDefaultMaxLength === undefined) {
    return allotmentAvailable
  }

  if (
    booking?.cargoJourneys?.[0]?.departureCode !==
      selectedDeparture?.departureCode &&
    bookingLength !== undefined &&
    availableLengthOnDeparture < allotmentAvailable &&
    availableLengthOnDeparture < vehicleDefaultMaxLength
  ) {
    return availableLengthOnDeparture
  }

  if (allotmentAvailable < vehicleDefaultMaxLength) {
    return allotmentAvailable
  }
  return vehicleDefaultMaxLength
}

export {
  getBookingPrice,
  getPriceFromAvailability,
  handleBookingRegistrationNumbers,
  isValidPrice,
  handleCargoDescriptions,
  generateBookingUpdateBody,
  handlePaperService,
  isBookingUpdatable,
  calculateVehicleMaxLength,
  getAvailableAllotment
}
