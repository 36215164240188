import { observer } from 'mobx-react'
import React from 'react'

import { CargoJourneyBookingRowAvailabilityGroupType } from '../../../../api/cargoSelfServiceAPI'
import ReservationsStore from '../../../../stores/ReservationsStore'
import SearchStore from '../../../../stores/SearchStore'
import Booking from '../booking/Booking'
import Container from '../../../../components/Container/Container'
import Row from '../../../../components/Row/Row'
import ErrorStore from '../../../../stores/ErrorStore'

type State = {
  activeIndex: string
}

@observer
class SearchResultsContainer extends React.Component<unknown, State> {
  state: State = {
    activeIndex: ''
  }

  toggleActive = (bookingCode: string) => {
    if (this.state.activeIndex === bookingCode) {
      this.setState({ activeIndex: '' })
    } else {
      this.setState({ activeIndex: bookingCode })
      SearchStore.fetchBooking(bookingCode)
    }
  }

  resolveStatus = () => {
    if (ErrorStore.isLoading) {
      return 'Searching...'
    } else if (SearchStore.searchResults.length === 0) {
      return `No search results for "${SearchStore.criteriaAsString}"`
    } else {
      return ''
    }
  }

  render() {
    return (
      <Container
        title={this.resolveStatus()}
        disabled={SearchStore.searchResults.length === 0}
        full
      >
        <Row top left wrap>
          {SearchStore.searchResults.map((booking) => {
            const {
              bookingCode,
              departureTime,
              arrivalPortExtName: arrivalPortName,
              departurePortExtName: departurePortName,
              externalNote,
              internalNote,
              vehicleCategory
            } = booking

            return (
              <Booking
                onClick={() => this.toggleActive(bookingCode!)}
                deleteBooking={() => ReservationsStore.deleteBooking(bookingCode!)}
                key={bookingCode}
                isActive={this.state.activeIndex === bookingCode}
                /* Search results have ISO string as departureTime, so we drop the timezone */
                departureDatetime={`${departureTime}`.substring(0, 16)}
                booking={{
                  ...booking,
                  cargoJourneys: [
                    {
                      journeyBookingRows: [
                        {
                          availabilityGroupType:
                            CargoJourneyBookingRowAvailabilityGroupType.VEHICLE,
                          externalNote,
                          internalNote,
                          vehicleProperties: {
                            categoryQuantity: vehicleCategory.quantity,
                            registrationNumbers: (
                              vehicleCategory.primaryRegNums || []
                            ).map(({ regNum = '' }) => ({
                              registrationNumberCode: regNum
                            }))
                          }
                        }
                      ],
                      arrivalPortName,
                      departurePortName
                    }
                  ]
                }}
              />
            )
          })}
        </Row>
      </Container>
    )
  }
}

export default SearchResultsContainer
