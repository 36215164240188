import React, { FC } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { observer } from 'mobx-react'
import occasionalCustomerStore from '../../../../stores/OccasionalCustomerStore'
import { useTranslation } from 'react-i18next'
import reservationStore from '../../../../stores/ReservationStore'
import { isValidPrice } from '../../../../utils/bookingHelpers'

const Price: FC<{ children: number; label?: string }> = (props) => {
  const { t } = useTranslation()

  const { children, label = t('total_price') } = props

  return (
    <>
      <Text mr={3} fontSize="xl">
        {label}:
      </Text>
      <Text fontWeight="bold" fontSize="2xl" color="baseBlue">
        {children} €
      </Text>
    </>
  )
}

const FinalPrice = observer(() => {
  const { currentPrice } = occasionalCustomerStore
  const { booking } = reservationStore
  const { t } = useTranslation()

  const isModifying = Object.hasOwn(booking, 'bookingBalance')

  return (
    <Flex minW="max-content" justifyContent="flex-end" alignItems="center">
      {isModifying && isValidPrice(booking.cargoJourneys?.[0]?.journeyPrice) ? (
        <Price>{booking.cargoJourneys?.[0]?.journeyPrice || 0}</Price>
      ) : isValidPrice(currentPrice) ? (
        <Price>{currentPrice}</Price>
      ) : (
        <Text color="red.400">{t('fill_info_to_see_price')}</Text>
      )}
    </Flex>
  )
})

export default FinalPrice
