import React from 'react'
import { useTranslation } from 'react-i18next'
import reservationStore from '../../../../stores/ReservationStore'
import FormSelect from '../../../../components/FormControlled/FormSelect'
import { observer } from 'mobx-react'
import { useFormContext } from 'react-hook-form'
import { ReservationFormValues } from '../../ReservationContainer'

const VehicleCategoryField = observer(() => {
  const { vehicleTemplates: templates } = reservationStore
  const { control, setValue } = useFormContext<ReservationFormValues>()
  const { t } = useTranslation()

  return (
    <FormSelect
      name="vehicleTemplateCategoryExtCode"
      label={t('vehicle_category')}
      control={control}
      onChanged={(value) => {
        const template = templates.find(
          ({ categoryExtCode: code }) => code === value
        )
        if (template) {
          setValue('height', template.defaults?.heightDefaults?.defaultValue!)
          setValue('width', template.defaults?.widthDefaults?.defaultValue!)
          setValue('vehicleLength', template.defaults?.lengthDefaults?.defaultValue!)
        } else {
          setValue('height', 0)
          setValue('width', 0)
          setValue('vehicleLength', 0)
        }
        reservationStore.setVehicleTemplate(value)
        return value
      }}
      rules={{ required: true }}
    >
      <option value="">{t('select_from_dropdown')}</option>
      {reservationStore.vehicleTemplates.map(
        ({ categoryNames, categoryExtCode }) => (
          <option key={categoryExtCode} value={categoryExtCode}>
            {categoryNames!.displayName}
          </option>
        )
      )}
    </FormSelect>
  )
})

export default VehicleCategoryField
