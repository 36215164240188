import { t } from '../locales'
import { FieldValues, Path, UseControllerProps } from 'react-hook-form'
import { removeSpecialSymbols } from './helpers'

type ValidationRuleType<T extends FieldValues> = Pick<
  UseControllerProps<T, Path<T>>,
  'rules'
>['rules']

type VehicleRegNumberValidationOptions = {
  shouldFilterNotAllowedRegNumbers?: boolean
}

const notAllowedVehicleRegNumbers = new Set(['ABC-123'])

export const setRegNumberAs = (value: string) =>
  removeSpecialSymbols(value?.toUpperCase())

export const vehicleRegNumberRule = <T extends FieldValues>(
  rules: ValidationRuleType<T> & VehicleRegNumberValidationOptions = {}
): ValidationRuleType<T> => ({
  ...rules,
  validate: rules?.shouldFilterNotAllowedRegNumbers
    ? (value) => {
        if (
          notAllowedVehicleRegNumbers.has(
            String(value || '')
              .toUpperCase()
              .trim()
          )
        ) {
          return t('error.field_invalid')
        }
        return true
      }
    : undefined,
  maxLength: {
    message: t('value_too_long_max_is', { value: 10 }),
    value: 10
  }
})
