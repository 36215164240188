import classNames from 'classnames'
import React, { FC, useRef } from 'react'

import { CargoNameListRow } from '../../../../api/cargoSelfServiceAPI'
import css from './Autofill.module.scss'
import { compareObjects } from '../../../../utils/helpers'
import { CloseButton, useOutsideClick } from '@chakra-ui/react'

export interface SuggestProps extends React.HTMLProps<HTMLDivElement> {
  drivers: CargoNameListRow[]
  handleSelect: (driver: CargoNameListRow) => void
  handleDelete: (index: number) => void
  open: boolean
  driver: any
  close: () => void
}

const Suggest: FC<SuggestProps> = ({
  drivers,
  handleSelect,
  handleDelete,
  open,
  driver,
  close
}) => {
  const isOpen = drivers.length && open

  const ref = useRef<HTMLDivElement>(null)

  useOutsideClick({ enabled: open, ref, handler: close })

  if (!isOpen) {
    return null
  }

  // very expensive operation
  const focusedIndex = isOpen
    ? drivers.findIndex((currentDriver) => {
        delete driver.id
        return compareObjects(currentDriver, driver)
      })
    : -1

  return (
    <div ref={ref} className={classNames(css.suggest, 'scroll-bar')}>
      {drivers.map((driver: CargoNameListRow, index: number) => (
        <div key={index} className={css.itemRowWrapper}>
          <div
            className={classNames(css.itemRow, {
              [css.hover]: focusedIndex === index
            })}
            onClick={() => handleSelect(driver)}
          >
            <div className={css.item}>
              <div>{`${driver.firstName} ${driver.lastName}`}</div>
              <div>({driver.dateOfBirth!.replace(/\s/g, '')})</div>
            </div>
          </div>
          <CloseButton
            className={css.deleteRow}
            onMouseUp={() => handleDelete(index)}
            size="sm"
          />
        </div>
      ))}
    </div>
  )
}

export default Suggest
