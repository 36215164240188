import React from 'react'
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup
} from '@chakra-ui/react'
import { LanguageIcon } from '../../icons'
import { observer } from 'mobx-react'
import { LanguageTypes } from '../../locales'
import { useTranslation } from 'react-i18next'

const langShortCuts: Record<LanguageTypes, string> = {
  en: 'EN',
  ee: 'EST',
  ru: 'RUS',
  pl: 'POL',
  fi: 'FI'
}

const Languages = observer(() => {
  const { i18n } = useTranslation()

  return (
    <Menu>
      <MenuButton
        borderRadius="sm"
        as={Button}
        leftIcon={
          <Icon
            as={LanguageIcon}
            style={{ marginLeft: '-0.5rem' }}
            fill="white"
            width="30px"
            height="30px"
          />
        }
      >
        {langShortCuts[i18n.language]}
      </MenuButton>
      <MenuList color="baseBlue" zIndex={2}>
        <MenuOptionGroup
          onChange={(value) => {
            i18n.changeLanguage(value as LanguageTypes)
          }}
          value={i18n.language}
        >
          <MenuItemOption value="en">English</MenuItemOption>
          <MenuItemOption value="ee">Eesti keel</MenuItemOption>
          <MenuItemOption value="fi">Suomi</MenuItemOption>
          <MenuItemOption value="ru">Pусский</MenuItemOption>
          <MenuItemOption value="pl">Polski</MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
})

export default Languages
