import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx'

import errorStore from './ErrorStore'
import initI18n, { t } from '../locales'
import userStore from './UserStore'

class AuthStore {
  @observable authenticating: boolean = false
  @observable error: string = ''
  @observable token: string = ''
  tokenName: string = 'carus-api-token'

  constructor() {
    const currentToken = window.localStorage.getItem(this.tokenName)
    if (currentToken) {
      this.token = currentToken
    }
    makeAutoObservable(this)
  }

  @action
  authenticate(username: string, password: string) {
    this.authenticating = true
    fetch(
      `/api/cargo-self-service/${process.env.REACT_APP_API_CARRES_ENV}/v1/cargo-self-service/v1/auth/tokens/customer-sub-logins`,
      {
        method: 'post',
        headers: new Headers({
          Accept: 'application/json',
          'Accept-Language': initI18n.language,
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
          code: username,
          password
        })
      }
    )
      .then((response) => {
        if (!response.ok) {
          runInAction(async () => {
            this.token = ''
            this.authenticating = false
            if (response.status === 401) {
              errorStore.add(t('login_error'))
            } else {
              const json = await response.json()
              errorStore.add(json.debugMessage)
            }
          })
          return { access_token: null }
        }
        return response.json()
      })
      .then(async (json) => {
        if (!json || !json.carusAuthToken) {
          return
        }
        await userStore.fetchUserData(json.carusAuthToken)
        runInAction(() => {
          this.token = json.carusAuthToken
          window.localStorage.setItem(this.tokenName, json.carusAuthToken)
          this.authenticating = false
        })
      })
      .catch((error) => {
        runInAction(() => {
          this.token = ''
          this.authenticating = false
          errorStore.add(error.message)
        })
      })
  }

  @action
  logout() {
    this.token = ''
    window.localStorage.removeItem(this.tokenName)
    userStore.removeCustomerData()
  }

  @computed
  get isAuthenticated(): boolean {
    return !!this.token
  }

  @action
  resetError() {
    this.error = ''
  }

  get authorizationToken() {
    return this.token
  }
}

export default new AuthStore()
