import { action, configure, makeAutoObservable, runInAction } from 'mobx'
import {
  DriverCargoBooking,
  ResourceLinks,
  UpdateDriverBookingRequest
} from '../api/driverUpdateAPI'
import ErrorStore from './ErrorStore'
import { reqErrorHandler } from '../utils/helpers'
import api from '../services/api'

configure({
  enforceActions: 'always'
})

class DriverUpdateStore {
  booking?: DriverCargoBooking
  isBookingLoading: boolean = true

  constructor() {
    makeAutoObservable(this)
  }

  @action
  async updateBookingInformationByToken({
    token,
    body
  }: {
    token: string
    body: UpdateDriverBookingRequest
  }): Promise<[ResourceLinks, null] | [null, string]> {
    try {
      runInAction(() => (this.isBookingLoading = true))
      ErrorStore.addLoader()
      const { data } = await api.driverUpdate.updateDriverBooking(body, {
        headers: { 'x-carus-driver': token }
      })
      return [data, null]
    } catch (e) {
      return [null, reqErrorHandler(e)]
    } finally {
      ErrorStore.removeLoader()
      runInAction(() => (this.isBookingLoading = false))
    }
  }

  @action
  async getDriverUpdateInformation(
    token: string
  ): Promise<[DriverCargoBooking, null] | [null, string]> {
    try {
      runInAction(() => (this.isBookingLoading = true))
      ErrorStore.addLoader()
      const { data } = await api.driverUpdate.getDriverBooking({
        headers: { 'x-carus-driver': token }
      })
      runInAction(() => (this.booking = data))

      return [data, null]
    } catch (error) {
      return [null, reqErrorHandler(error)]
    } finally {
      ErrorStore.removeLoader()
      runInAction(() => (this.isBookingLoading = false))
    }
  }
}

export default new DriverUpdateStore()
