import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import View from '../../components/View/View'
import { useTranslation } from 'react-i18next'
import reservationStore from '../../stores/ReservationStore'
import { EDIT, ReservationSteps } from '../../utils/constants'
import CargoInformation from './components/CargoInformation/CargoInformation'
import Confirmation from './components/Confirmation/Confirmation'
import DepartureComponent from './components/DepartureComponent/DepartureComponent'
import { DriverListFormValues } from './components/DriverAndServices/DriverAndServices'
import { calculateVehicleMaxLength } from '../../utils/bookingHelpers'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  useToast,
  ListItem,
  OrderedList,
  useUpdateEffect
} from '@chakra-ui/react'
import { DoneIcon, EventIcon, AssignmentIcon, ErrorIcon } from '../../icons'
import { FormProvider, useForm } from 'react-hook-form'
import { DriversFormValues } from './components/Confirmation/SendLinkToDriver'
import { CargoNameListRow } from '../../api/cargoSelfServiceAPI'
import BaseFormLabel from '../../components/Form/BaseFormLabel'
import { getCargoInfoErrors } from '../../utils/reservationsHelpers'
import DriverServiceAccordion from '../../components/DriverServiceAccordion'
import userStore from '../../stores/UserStore'
import ErrorStore from '../../stores/ErrorStore'
import { Action } from '../../services/types'
import useQuery from '../../hooks/useQuery'

export type ReservationNativeFields = {
  vehicleRegistrationNumber: string
  vehicleRegistrationNumberCountryCode: string
  cargoType: string
  powerConnection: boolean
  vehicleTemplateCategoryExtCode: string
  width: number
  height: number
  loadWeight: number | string
  trailerRegistrationNumber: string
  trailerRegistrationNumberCountryCode: string
  externalNote: string
  internalNote: string
  vehicleLength: number
  consignee: string
}

export type ReservationFormValues = ReservationNativeFields &
  DriversFormValues &
  DriverListFormValues

export const driverDefaultValues: CargoNameListRow = {
  firstName: '',
  lastName: '',
  nationalityExtCode: '',
  dateOfBirth: '',
  mobile: '',
  genderCode: 'M',
  email: ''
}

export const cargoInfoFields: (keyof ReservationFormValues)[] = [
  'vehicleLength',
  'loadWeight',
  'vehicleRegistrationNumber',
  'cargoType',
  'vehicleTemplateCategoryExtCode',
  'externalNote',
  'internalNote',
  'consignee'
]

const defaultValues = (): Partial<ReservationFormValues> => ({
  width: 0,
  height: 0,
  email: '',
  phone: '',
  cargoType: '',
  externalNote: '',
  internalNote: '',
  loadWeight: '',
  trailerRegistrationNumber: '',
  vehicleRegistrationNumber: '',
  vehicleTemplateCategoryExtCode: '',
  powerConnection: false,
  vehicleLength: 0,
  drivers: [driverDefaultValues]
})

const ReservationContainer: FunctionComponent = observer(() => {
  const { step = ReservationSteps['0'] } = useParams<{
    step: string
    bookingCode: string
    action: Action
  }>()
  const query = useQuery()

  const location = useLocation()

  useUpdateEffect(() => {
    // Reset the state when user navigates to New Reservation
    if (location.pathname === '/') {
      setIsDepartureValid(undefined)
      reservationStore.reset()
    }
  }, [location])

  const action = (query.get('action') as Action) || Action.New
  const bookingCode = query.get('bookingCode') || ''

  const navigate = useNavigate()
  const { t } = useTranslation()

  const [active, setActive] = useState<number>(0)
  const [isDepartureValid, setIsDepartureValid] = useState<boolean | undefined>(
    undefined
  )
  const toast = useToast()

  const methods = useForm<ReservationFormValues>({
    defaultValues: defaultValues(),
    mode: 'onChange'
  })

  const fetchBooking = (bookingCode: string) =>
    reservationStore.fetchBooking(bookingCode).then(methods.reset)

  const updateStep = useCallback(() => {
    // Force the user to select the departure
    if (reservationStore.departureCode) {
      setActive(ReservationSteps[step])
    } else {
      setActive(0)
    }
  }, [reservationStore.departureCode, step])

  useEffect(() => {
    reservationStore.registerAutorun()

    return () => {
      reservationStore.disposeAutorun()
      methods.reset(defaultValues())
    }
  }, [])

  useEffect(() => {
    reservationStore.loadSavedDrivers()
    methods.reset(defaultValues())

    // Set the active step by default
    // Later on it is updated by the updateStep function
    setActive(ReservationSteps[step])

    reservationStore
      .fetchDeparturePorts()
      .then(reservationStore.fetchNationalities)
      .then(() => {
        if (bookingCode) {
          fetchBooking(bookingCode).then(updateStep)
        } else {
          reservationStore.reset()
        }
      })

    methods.trigger([
      'vehicleTemplateCategoryExtCode',
      'vehicleRegistrationNumber',
      'loadWeight',
      'cargoType'
    ])
  }, [bookingCode])

  useEffect(() => {
    // Update departure validity
    setIsDepartureValid((state) =>
      state !== undefined ? !!reservationStore.departureCode : undefined
    )
  }, [reservationStore.departureCode])

  const toggleActive = (id: number) => {
    if (reservationStore.departureCode) {
      setIsDepartureValid(true)
      setActive((prevValue) => {
        return prevValue === id ? -1 : id
      })
    } else {
      setIsDepartureValid(false)
      setActive(0)
    }
  }

  const onMakeReservation = methods.handleSubmit(
    async (values) => {
      ErrorStore.addLoader()

      const isValid = await methods.trigger()

      await reservationStore.updateDepartures()
      const maxAllotment = calculateVehicleMaxLength({
        vehicleTemplate: reservationStore.findVehicleTemplateByCode(
          values.vehicleTemplateCategoryExtCode
        ),
        booking: reservationStore.booking,
        selectedDeparture: reservationStore.selectedDeparture,
        isOccasional: userStore.isOccasional
      })

      if (values.vehicleLength > maxAllotment) {
        toast({
          title: 'Error',
          status: 'error',
          description:
            'The total length of the vehicles exceeds the available allotment'
        })
        ErrorStore.removeLoader()
        return
      }

      if (!isValid) {
        ErrorStore.removeLoader()
        return
      }

      const { email, phone } = values

      reservationStore.setValues(values)

      const [bookingCode] = await reservationStore.makeReservation(action, values)
      const params = new URLSearchParams()

      const path = `/done/${bookingCode}`

      if (!bookingCode) {
        ErrorStore.removeLoader()
        return
      }

      if (action === EDIT) {
        if (!userStore.isFeatureEnabled) {
          fetchBooking(bookingCode)

          toast({
            title: `Booking: ${bookingCode} updated successfully`,
            status: 'success'
          })

          ErrorStore.removeLoader()

          return
        }

        const [driver] = await reservationStore.generateDriverToken({
          email,
          bookingCode,
          mobile: phone,
          expiryTime: reservationStore.selectedDeparture.departureDatetime
        })

        const sendingStatus: boolean[] = []

        if (driver && Object.values(driver).length) {
          sendingStatus.push(driver.smsSent, driver.emailSent)
        }

        fetchBooking(bookingCode)

        toast({
          title: `Booking: ${bookingCode} updated successfully`,
          description: sendingStatus.some((status) => status)
            ? 'Login sent to the driver successfully'
            : undefined,
          status: 'success'
        })
      }

      if (!email && !phone) {
        ErrorStore.removeLoader()
        navigate(path)
        return
      }

      const [driver] = await reservationStore.generateDriverToken({
        email,
        bookingCode,
        mobile: phone,
        expiryTime: reservationStore.selectedDeparture.departureDatetime
      })

      if (driver && Object.values(driver).length) {
        params.append('smsSent', JSON.stringify(driver.smsSent))
        params.append('emailSent', JSON.stringify(driver.emailSent))
      }

      ErrorStore.removeLoader()

      navigate({ pathname: `/done/${bookingCode}`, search: params.toString() })
    },
    () => {
      toast({
        status: 'error',
        description:
          'Please proof the information above to make or create the booking'
      })
      ErrorStore.removeLoader()
    }
  )

  const driverListInvalid = !!methods.formState.errors.drivers?.length
  const cargoInformationInvalid = cargoInfoFields.some(
    (field) => !!methods.formState.errors[field]
  )

  const cargoInfoErrors = getCargoInfoErrors(methods.formState.errors)

  return (
    <View title={t(action === 'edit' ? 'edit_reservation' : 'new_reservation')}>
      <FormProvider {...methods}>
        <Accordion
          allowToggle
          defaultIndex={active}
          index={active}
          border="1px solid #E2E8F0"
        >
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  onClick={() => toggleActive(0)}
                  bgColor={(isExpanded && 'baseCyan') || undefined}
                >
                  <EventIcon fill={isDepartureValid === false ? 'red' : undefined} />
                  <Box
                    color={isDepartureValid === false ? 'red' : undefined}
                    flex="1"
                    textAlign="left"
                    ml={3}
                    fontSize="md"
                    py={2}
                  >
                    {t('departure_time_and_date')}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <DepartureComponent
                    id={0}
                    action={action}
                    active={active === 0}
                    onMakeReservation={onMakeReservation}
                    toggleActive={toggleActive}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  onClick={() => toggleActive(1)}
                  bgColor={(isExpanded && 'baseCyan') || undefined}
                >
                  <AssignmentIcon
                    fill={cargoInformationInvalid ? 'red' : undefined}
                  />
                  <Box
                    color={cargoInformationInvalid ? 'red' : undefined}
                    flex="1"
                    textAlign="left"
                    ml={3}
                    fontSize="md"
                    py={2}
                  >
                    {!!cargoInfoErrors.length ? (
                      <BaseFormLabel
                        tooltipProps={{ isDisabled: !cargoInfoErrors.length }}
                        tooltipText={
                          <OrderedList spacing={3}>
                            {cargoInfoErrors.map((error, index) => (
                              <ListItem key={index}>{error}</ListItem>
                            ))}
                          </OrderedList>
                        }
                        icon={ErrorIcon}
                        label={t('cargo_information')}
                      />
                    ) : (
                      t('cargo_information')
                    )}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <CargoInformation
                    id={1}
                    action={action}
                    active={active === 1}
                    onMakeReservation={onMakeReservation}
                    toggleActive={toggleActive}
                    isInvalid={cargoInformationInvalid}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem>
            <AccordionItem>
              {({ isExpanded }) => (
                <DriverServiceAccordion
                  action={action}
                  active={active}
                  driverListInvalid={driverListInvalid}
                  onMakeReservation={onMakeReservation}
                  toggleActive={toggleActive}
                  isExpanded={isExpanded}
                />
              )}
            </AccordionItem>
          </AccordionItem>
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  onClick={() => toggleActive(3)}
                  bgColor={(isExpanded && 'baseCyan') || undefined}
                >
                  <DoneIcon />
                  <Box flex="1" textAlign="left" ml={3} fontSize="md" py={2}>
                    {t('confirmation')}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Confirmation
                    id={3}
                    active={active === 3}
                    action={action}
                    onMakeReservation={onMakeReservation}
                    toggleActive={toggleActive}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </FormProvider>
    </View>
  )
})

export default ReservationContainer
