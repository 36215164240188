import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx'

import { CargoCustomer } from '../api/cargoSelfServiceAPI'
import authStore from './AuthStore'
import api from '../services/api'
import { betaTestUsers } from '../utils/constants'

class UserStore {
  @observable customer: CargoCustomer = {}
  @observable email: string = ''

  constructor() {
    const customer = localStorage.getItem('cargo-customer')
    const email = localStorage.getItem('cargo-user-email')
    if (customer) {
      try {
        this.customer = JSON.parse(customer)
      } catch (e) {
        authStore.logout()
      }
    }
    if (email) {
      this.email = email
    }
    makeAutoObservable(this)
  }

  get isOccasional() {
    return authStore.isAuthenticated && this.customer?.methodOfPaymentCode === null
  }

  @computed
  get isFeatureEnabled() {
    return betaTestUsers.includes(this.customer.customerCode || '')
  }

  @computed
  get computedEmail() {
    return userStore.email || userStore.customer?.emailContact?.contactInformation
  }

  @action
  async fetchUserData(authorizationToken: string) {
    try {
      const { data: response } = await api.cargoSelfService.getCargoCustomer(
        undefined,
        {
          headers: {
            'Cache-Control': 'no-cache',
            pragma: 'no-cache',
            'X-Carus-Auth': authorizationToken
          }
        }
      )
      localStorage.setItem('cargo-customer', JSON.stringify(response))
      runInAction(() => {
        this.customer = response
      })
    } catch (error) {
      console.error(error)
    }
  }

  @action
  removeCustomerData() {
    this.customer = {}
    this.email = ''
    localStorage.removeItem('cargo-customer')
    localStorage.removeItem('cargo-user-email')
  }

  @action
  setProperty(name: string, value: string) {
    this.customer = { ...this.customer, [name]: value }
  }

  @action
  setProperties(user: {}) {
    for (const key in user) {
      if (user.hasOwnProperty(key)) {
        this[key] = user[key]
      }
    }
  }

  setEmail(email: string) {
    this.email = email
    localStorage.setItem('cargo-user-email', email)
  }
}

const userStore = new UserStore()
export default userStore
