import React from 'react'

import Container from '../../../../components/Container/Container'
import { CargoDeparture } from '../../../../api/cargoSelfServiceAPI'
import { countBookings } from '../../../../utils/helpers'
import DeparturesContainer from '../departures/DeparturesContainer'
import css from './DepartureGroup.module.css'

type Props = {
  departures: { [date: string]: { [time: string]: CargoDeparture } }
  activeDate: string
  onClick: (date: string) => void
  pastReservations?: boolean
}

const DepartureGroup: React.FC<Props> = ({
  onClick,
  activeDate,
  departures,
  pastReservations
}) => {
  const dates = Object.keys(departures)

  if (dates.length === 0) {
    return (
      <Container
        disabled
        headerClass={css.noReservations}
        icon={null}
        title={'No reservations'}
      />
    )
  }

  return (
    <>
      {dates.map((date) => {
        const bookingCount = countBookings(departures[date])
        if (bookingCount.total === 0) {
          return null
        }
        return (
          <DeparturesContainer
            date={date}
            departures={departures[date]}
            isActive={activeDate === date}
            onClick={() => onClick(date)}
            key={date}
            bookingCount={bookingCount}
            pastReservations={pastReservations}
          />
        )
      })}
    </>
  )
}

export default DepartureGroup
