import React, { useEffect } from 'react'
import { Center, VStack } from '@chakra-ui/react'
import FormInput from '../../../../components/FormControlled/FormInput'
import authStore from '../../../../stores/AuthStore'
import BaseSubmitButton from '../../../../components/Form/BaseSubmitButton'
import { observer } from 'mobx-react'
import { useForm } from 'react-hook-form'
import { validateEmail } from '../../../../utils/helpers'
import userStore from '../../../../stores/UserStore'
import { useTranslation } from 'react-i18next'

type SignInForm = {
  username: string
  password: string
  emailSignIn: string
}

const SignIn = observer(() => {
  const { t } = useTranslation()

  const { control, handleSubmit } = useForm<SignInForm>({
    defaultValues: {
      username: '',
      password: '',
      emailSignIn: ''
    },
    mode: 'onChange'
  })

  const onSubmit = handleSubmit(({ username, password, emailSignIn: email }) => {
    authStore.authenticate(username, password)
    userStore.setEmail(email)
  })

  useEffect(() => {
    authStore.resetError()
  }, [])

  return (
    <form onSubmit={onSubmit}>
      <VStack spacing={5}>
        <FormInput
          disabled={authStore.authenticating}
          placeholder={t('username')}
          name="username"
          autoComplete="username"
          control={control}
          rules={{ required: true }}
        />
        <FormInput
          disabled={authStore.authenticating}
          placeholder={t('password')}
          type="password"
          autoComplete="current-password"
          name="password"
          control={control}
          rules={{ required: true }}
        />
        <FormInput
          disabled={authStore.authenticating}
          placeholder={t('email')}
          name="emailSignIn"
          control={control}
          rules={{ required: true, validate: validateEmail }}
        />
        <Center w="100%">
          <BaseSubmitButton
            type="submit"
            w="100%"
            disabled={authStore.authenticating}
            isLoading={authStore.authenticating}
            loadingText={t('login_in')}
            onClick={onSubmit}
          >
            {t('login')}
          </BaseSubmitButton>
        </Center>
      </VStack>
    </form>
  )
})

export default SignIn
