import React from 'react'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'
import { FormLabelProps, SelectProps } from '@chakra-ui/react'
import BaseSelect from '../Form/BaseSelect'
import { observer } from 'mobx-react'
import { extractErrorStatus } from '../../helpers/forms'
import { FieldPathValue } from 'react-hook-form/dist/types/path'

export type FormSelectProps<T extends FieldValues> = {
  name: Path<T>
  labelProps?: FormLabelProps
  label?: React.ReactNode
  placeholder?: string
  helperText?: string
  control: Control<T>
  rules?: Omit<
    RegisterOptions<any, any>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >
  setValueAs?: (value: string) => FieldPathValue<T, Path<T>>
  children: React.ReactNode
  onChanged?: (value: string) => FieldPathValue<T, Path<T>>
} & Omit<SelectProps, 'value' | 'onChange'>

const FormSelect = observer(<T extends FieldValues>(props: FormSelectProps<T>) => {
  const {
    name,
    placeholder,
    helperText,
    label,
    control,
    rules,
    setValueAs,
    children,
    labelProps,
    onChanged,
    ...rest
  } = props

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field: { onChange, ...field }, fieldState }) => {
        const [errorMessage, invalid] = extractErrorStatus(fieldState)

        const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
          if (setValueAs) {
            const value = setValueAs(event.currentTarget.value)
            onChange(value)
            onChanged?.(value)
            return
          }
          onChange(event.currentTarget.value)
          onChanged?.(event.currentTarget.value)
        }

        return (
          <BaseSelect
            labelProps={labelProps}
            isInvalid={invalid}
            isRequired={!!rules?.required}
            placeholder={placeholder}
            helperText={errorMessage || helperText}
            label={label}
            onChange={onSelectChange}
            {...field}
            {...rest}
            value={(field.value as string) ?? ''}
          >
            {children}
          </BaseSelect>
        )
      }}
    />
  )
})

export default FormSelect
