import en from './en.json'
import ru from './ru.json'
import fi from './fi.json'
import ee from './ee.json'
import pl from './pl.json'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

type TypeOfLanguage = typeof en & typeof ru & typeof fi & typeof ee & typeof pl

type LangValue = {
  [key in keyof TypeOfLanguage]: string
}

export type LanguageTypes = 'fi' | 'en' | 'ru' | 'ee' | 'pl'

export type LangKeyword = keyof LangValue

export type ResourcesType = Record<LanguageTypes, TypeOfLanguage>

const resources: ResourcesType = {
  en,
  ru,
  fi,
  ee,
  pl
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: Object.entries(resources).reduce((newResource, [key, value]) => {
      newResource[key] = {
        translation: value
      }
      return newResource
    }, {}),
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })

export const t = i18n.t

export default i18n
