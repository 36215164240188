import { action, computed, configure, makeAutoObservable, runInAction } from 'mobx'
import {
  CreateCargoAvailabilityRequest,
  SendLoginOtpViaEmail,
  TokenResponse,
  CargoAvailabilitySearchResult
} from '../api/cargoSelfServiceAPI'
import reservationStore from './ReservationStore'
import { reqErrorHandler } from '../utils/helpers'
import userStore from './UserStore'
import AuthStore from './AuthStore'
import {
  PortDepartureInformationResponse,
  PortDepartureInformationResponseCollection
} from '../api/portDepartureInfoAPI'
import { addDays, compareAsc, format } from 'date-fns'
import api from '../services/api'

configure({
  enforceActions: 'always'
})

class OccasionalCustomerStore {
  portDepartures: PortDepartureInformationResponse[] = []

  constructor() {
    makeAutoObservable(this)
  }

  @computed
  get currentPrice(): number | undefined {
    return this.prices.find(
      ({ extCode }) => reservationStore.vehicleTemplate?.categoryExtCode === extCode
    )?.price
  }

  @computed
  get prices() {
    return reservationStore.vehicleTemplates.map(
      ({ categoryNames, categoryExtCode }) => {
        const price =
          reservationStore.availableServices?.vehicleAvailability?.vehicle?.find(
            (vehicle) => vehicle.extCategoryCode === categoryExtCode
          )?.displayRowPrice || 0

        return {
          price,
          categoryName: categoryNames?.displayName,
          extCode: categoryExtCode
        }
      }
    )
  }

  @action
  async fetchAvailableServices({
    departureCode,
    journeyCode,
    body
  }: {
    departureCode: string
    journeyCode: string
    body: CreateCargoAvailabilityRequest
  }): Promise<[CargoAvailabilitySearchResult, null] | [null, string]> {
    try {
      const { data: response } =
        await api.cargoSelfService.postCargoAvailabilityTimeAndPriceAvailability(
          departureCode,
          journeyCode,
          body
        )
      return [response, null]
    } catch (e) {
      return [null, reqErrorHandler(e)]
    }
  }

  isValidToken(token: any): token is string {
    return typeof token === 'string'
  }

  async authenticate(body: TokenResponse) {
    try {
      if (!this.isValidToken(body.carusAuthToken)) {
        return false
      }

      await userStore.fetchUserData(body.carusAuthToken)
      runInAction(() => {
        if (!this.isValidToken(body.carusAuthToken)) {
          return false
        }

        AuthStore.token = body.carusAuthToken
        window.localStorage.setItem(AuthStore.tokenName, body.carusAuthToken)
        AuthStore.authenticating = false
      })
      return true
    } catch (e) {
      console.error('error', e)
      return false
    }
  }

  async sendOtpViaEmail(body: SendLoginOtpViaEmail) {
    try {
      await api.cargoSelfService.sendLoginOtpViaEmail(body)
      return [true, null]
    } catch (e) {
      return [null, reqErrorHandler(e)]
    }
  }

  async verifyOtp(otp: string): Promise<[TokenResponse, null] | [null, string]> {
    try {
      const { data: response } =
        await api.cargoSelfService.generateCustomerSubLoginTokenByOneTimePassword({
          oneTimePassword: otp
        })
      return [response, null]
    } catch (e) {
      return [null, reqErrorHandler(e)]
    }
  }

  async fetchDeparturesByPortCodes({
    portCodes,
    date
  }: {
    portCodes: string[]
    date: Date
  }) {
    const rawDepartures = await Promise.all(
      portCodes.map(async (portCode) =>
        occasionalCustomerStore.fetchAllotmentForDepartures({
          portCode,
          fromDate: date,
          toDate: addDays(date, 1)
        })
      )
    )

    const portDepartures = rawDepartures
      .reduce<PortDepartureInformationResponse[]>(
        (departures, [response]) => [
          ...departures,
          ...(response?.departures ? response.departures : [])
        ],
        []
      )
      .sort((a, b) => compareAsc(a.departureDate, b.departureDate))

    runInAction(() => {
      this.portDepartures = portDepartures
    })
  }

  async fetchAllotmentForDepartures({
    portCode,
    fromDate,
    toDate
  }: {
    portCode: string
    fromDate: Date
    toDate: Date
  }): Promise<[PortDepartureInformationResponseCollection, null] | [null, string]> {
    try {
      const { data: res } = await api.departureInfo.getCargoDepartures(portCode, {
        fromDate: format(fromDate, 'YYYY-MM-DD'),
        toDate: format(toDate, 'YYYY-MM-DD'),
        pageSize: 20
      })
      return [res, null]
    } catch (e) {
      return [null, reqErrorHandler(e)]
    }
  }
}

const occasionalCustomerStore = new OccasionalCustomerStore()
export default occasionalCustomerStore
