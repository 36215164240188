import { FormHelperText } from '@chakra-ui/react'
import React from 'react'

type Props = {
  children: string
  invalid?: boolean
}

const BaseFormHelperText: React.FC<Props> = ({ children, invalid }) => {
  return <FormHelperText color={invalid ? 'red.400' : ''}>{children}</FormHelperText>
}

export default BaseFormHelperText
