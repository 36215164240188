import { action, makeAutoObservable, observable } from 'mobx'

class ModalStore {
  @observable isVisible: boolean = false
  @observable header: string = ''
  @observable description: string = ''
  @observable onConfirm?: () => void

  constructor() {
    makeAutoObservable(this)
  }

  @action
  confirm = (label: string, description: string = '', onConfirm?: () => void) => {
    this.isVisible = true
    this.header = label
    this.description = description
    this.onConfirm = action(() => {
      onConfirm && onConfirm()
      this.isVisible = false
    })
  }

  @action
  onCancel = () => {
    this.isVisible = false
  }
}

export default new ModalStore()
