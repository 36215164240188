import React, { useEffect } from 'react'
import BaseRadio from '../Form/BaseRadio'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { RegisterOptions } from 'react-hook-form/dist/types/validator'
import { RadioGroupProps } from '@chakra-ui/react'
import { SelectItem } from '../Form/BaseSelect'
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller'

type Props<T extends FieldValues> = {
  name: Path<T>
  label?: string
  placeholder?: string
  helperText?: string
  control: Control<T>
  rules?: Omit<
    RegisterOptions<any, any>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >
  setValueAs?: (value: string) => string | number
  options: SelectItem[]
} & Omit<RadioGroupProps, 'children'>

const FormRadio = <T extends FieldValues>({
  name,
  placeholder,
  helperText,
  label,
  control,
  rules,
  setValueAs,
  options,
  ...props
}: Props<T>) => {
  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field }) => {
        return (
          <RadioContent
            {...props}
            field={field}
            label={label}
            name={name}
            placeholder={placeholder}
            options={options}
          />
        )
      }}
    />
  )
}

type RadioContentProps<T extends FieldValues> = {
  field: ControllerRenderProps<T>
} & Pick<Props<T>, 'name' | 'placeholder' | 'options' | 'label'> &
  Omit<RadioGroupProps, 'children'>

const RadioContent = <T extends FieldValues>(props: RadioContentProps<T>) => {
  const { field, options, placeholder, name, label, ...rest } = props

  useEffect(() => {
    if (!field.value && options.length) {
      // set default value for radio button
      field.onChange(options[0].value)
    }
  }, [field, field.value, options])

  return (
    <BaseRadio
      {...rest}
      placeholder={placeholder}
      label={label}
      options={options}
      {...field}
      value={(field.value ?? '') + ''}
    />
  )
}

export default FormRadio
