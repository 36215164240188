import { Flex, Box, Text, Link } from '@chakra-ui/react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

const Footer = observer(() => {
  const { t } = useTranslation()

  return (
    <Flex
      bg="baseDark"
      w="100%"
      justifyContent="center"
      alignContent="center"
      mt="auto"
      px={[4, 4, 4]}
    >
      <Box w="100%" minH="20vh" maxW="container.lg" mt={8}>
        <Flex
          w="100%"
          alignContent="start"
          flexDirection={['column', 'row']}
          textAlign={['center', 'inherit']}
        >
          <Box>
            <Text
              fontSize="md"
              color="white"
              fontWeight="400"
              mr={[0, 8]}
              mb={[8, 0]}
            >
              <strong>{t('important_links')}</strong>
              <br />
              <Link
                target="_blank"
                href="https://eckeroline.com/cargo"
                rel="noreferrer"
              >
                eckeroline.com/cargo
              </Link>
            </Text>
          </Box>
          <Box>
            <Text fontSize="md" color="white" fontWeight="400">
              <strong>{t('customer_service')}</strong>
              <br />
              <Link href="mailto:cargo@eckeroline.fi">cargo@eckeroline.fi</Link>
              <br />
              <Link href="tel:+35896853957">+358 9 685 3957</Link>
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  )
})

export default Footer
