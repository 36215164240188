import { createPortal } from 'react-dom'
import { FC, PropsWithChildren } from 'react'

type Props = PropsWithChildren<{
  elementId: string
}>

const Portal: FC<Props> = ({ children, elementId }) => {
  const $container = document.getElementById(elementId)

  if (!$container) {
    return null
  }

  return createPortal(children, $container)
}

export default Portal
