import { format, isFuture, isPast } from 'date-fns'
import {
  action,
  computed,
  configure,
  makeAutoObservable,
  observable,
  reaction,
  runInAction
} from 'mobx'

import { CargoDeparture, CargoExtPort } from '../api/cargoSelfServiceAPI'
import { departureToKey, getISODate } from '../utils/helpers'
import ErrorStore from './ErrorStore'
import api from '../services/api'

configure({
  enforceActions: 'always'
})

class ReservationsStore {
  @observable ports: CargoExtPort[] = []
  @observable departureCountryCodes: string[] = []
  @observable departures: CargoDeparture[] = []
  @observable from: Date = new Date()
  @observable to: Date = new Date()

  private autorunDisposer: (() => void) | null = null

  constructor() {
    makeAutoObservable(this)
  }

  @computed
  get departureCountries(): string[] {
    return [
      ...new Set(
        this.ports.reduce<string[]>(
          (a, x) => (x.countryExtCode ? [...a, x.countryExtCode] : a),
          []
        )
      )
    ]
  }

  isDepartureCountrySelected = (countryCode: string) => {
    return this.departureCountryCodes.includes(countryCode)
  }

  @action
  fetchDeparturePorts = () =>
    api.cargoSelfService.getAllCargoPorts().then(({ data: response }) => {
      runInAction(() => {
        this.ports = response.ports || []
        // Select all ports by default
        this.departureCountryCodes = this.departureCountries
      })
    })

  @action
  deleteBooking = (bookingCode: string) =>
    api.cargoSelfService
      .cancelCargoBooking(bookingCode, ':cancel', { body: null } as any) // Setting the body to null, due to strange behaviour of axios. See this issue: https://github.com/axios/axios/issues/86
      .then(() => {
        this.removeBookingFromDepartures(bookingCode)
        return true
      })
      .catch(() => {
        return false
      })

  @action
  removeBookingFromDepartures = (bookingCode: string) => {
    const dL = this.departures.length
    for (let i = 0; i < dL; i++) {
      const { bookings } = this.departures[i]
      if (bookings) {
        const bL = bookings.length
        for (let n = 0; n < bL; n++) {
          if (bookings[n].bookingCode === bookingCode) {
            bookings.splice(n, 1)
            return
          }
        }
      }
    }
  }

  groupByDateAndTime = (
    departures: CargoDeparture[]
  ): { [date: string]: { [time: string]: CargoDeparture } } => {
    const groupedDepartures = {}
    departures.forEach((departure) => {
      const date = getISODate(departure.departureDatetime)
      const key = departureToKey(departure)
      groupedDepartures[date] = {
        ...(groupedDepartures[date] || { [key]: [] }),
        [key]: departure
      }
    })
    return groupedDepartures
  }

  fetchDepartures = (
    from: string,
    to: string,
    showAllDepartures?: boolean,
    departurePortExtCode?: string,
    arrivalPortExtCode?: string,
    sort?: string[],
    fields?: string
  ) =>
    api.cargoSelfService.getCargoApiAllDeparturesToCustomer(
      {
        departureDateFromDate: from,
        departureDateFromHours: 0,
        departureDateFromMins: 0,
        departureDateToDate: to,
        departureDateToHours: 23,
        departureDateToMins: 59,
        arrivalPortExtCode,
        sort,
        departurePortExtCode,
        showAllDepartures,
        fields
      },
      { headers: { 'Cache-Control': 'no-cache', pragma: 'no-cache' } }
    )

  fetchDeparturesFromCountries = async (from: Date, to: Date) => {
    const ports = this.ports.filter(({ countryExtCode }) =>
      this.departureCountryCodes.includes(countryExtCode!)
    )
    const departuresPromises = ports.map((port) =>
      this.fetchDepartures(
        format(from, 'YYYY-MM-DD'),
        format(to, 'YYYY-MM-DD'),
        true,
        port.portExtCode
      ).then(({ data: { cargoDepartures = [] } }) => cargoDepartures)
    )
    const departures = await Promise.all(departuresPromises)
    return departures
      .reduce((cum, cur) => cum.concat(cur), [])
      .sort((a, b) => (a.departureDatetime! < b.departureDatetime! ? -1 : 1))
  }

  @action
  addDepartureCountry = (countryCode: string = '') => {
    this.departureCountryCodes.push(countryCode)
  }

  @action
  removeDepartureCountry = (countryCode: string = '') => {
    this.departureCountryCodes = this.departureCountryCodes.filter(
      (a) => a !== countryCode
    )
    if (this.departureCountryCodes.length === 0) {
      this.departureCountryCodes = this.departureCountries.filter(
        (x) => x !== countryCode
      )
    }
  }

  @action
  reset = () => {
    this.departures = []
    this.fetchDeparturePorts()
  }

  @computed
  get futureBookings() {
    return null
  }

  @computed
  get groupedFutureBookings() {
    const futureBookings = this.departures.filter(
      (departure: CargoDeparture) =>
        departure.bookings &&
        departure.bookings.length > 0 &&
        departure.departureDatetime &&
        isFuture(departure.departureDatetime)
    )
    return this.groupByDateAndTime(futureBookings)
  }

  @computed
  get groupedPastBookings() {
    const pastBookings = this.departures
      .filter(
        (departure: CargoDeparture) =>
          departure.bookings &&
          departure.bookings.length > 0 &&
          departure.departureDatetime &&
          isPast(departure.departureDatetime)
      )
      .reverse()
    return this.groupByDateAndTime(pastBookings)
  }

  @action
  setFrom(from: Date) {
    this.from = from
  }

  @action
  setTo(to: Date) {
    this.to = to
  }

  @action
  resetDepartures() {
    this.departures = []
  }

  @action
  registerAutorun() {
    this.autorunDisposer = this.startAutorun()
  }

  @action
  disposeAutorun() {
    this.autorunDisposer && this.autorunDisposer()
  }

  /**
   * Start autorun for departures.
   * Returns disposer function
   */
  private startAutorun() {
    // Update departures
    return reaction(
      () => this.departureCountryCodes.join() + this.to + this.from,
      async () => {
        this.resetDepartures()
        ErrorStore.addLoader()
        const departures = await this.fetchDeparturesFromCountries(
          this.from,
          this.to
        )
        ErrorStore.removeLoader()
        runInAction(() => {
          this.departures = departures
        })
      },
      { fireImmediately: true }
    )
  }
}

export default new ReservationsStore()
