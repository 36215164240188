import React, { useCallback, useEffect } from 'react'
import View from '../../components/View/View'
import {
  Text,
  useBoolean,
  Button,
  useToast,
  VStack,
  Stack,
  Link
} from '@chakra-ui/react'
import { useParams, Link as ReactLink, useNavigate } from 'react-router-dom'
import occasionalCustomerStore from '../../stores/OccasionalCustomerStore'

const OccasionalCustomerContainer = () => {
  const { otp } = useParams<{ otp: string }>()
  const navigate = useNavigate()

  const [pending, setPending] = useBoolean(false)

  const toast = useToast()

  const onSubmit = useCallback(async () => {
    setPending.on()
    const [response, errorMessage] = await occasionalCustomerStore.verifyOtp(otp!)
    setPending.off()

    if (errorMessage !== null) {
      toast({
        description: errorMessage,
        title: 'Possible solutions: request new one-time password or contact us',
        status: 'error'
      })
      return
    }

    const value = await occasionalCustomerStore.authenticate(response)
    if (value) {
      navigate('/')
    }
    // eslint-disable-next-line
  }, [otp, toast])

  useEffect(() => {
    onSubmit()
  }, [onSubmit])

  return (
    <View
      title="Authorization"
      alignItems="center"
      justifyContent="center"
      display="flex"
    >
      <VStack p={[2, 4]}>
        <Stack w="500px" maxW="100%" alignItems="center" spacing={5}>
          <Text>One-time password: {otp}</Text>
          <Button
            isLoading={pending}
            onClick={onSubmit}
            loadingText="Requesting access"
          >
            Request access again
          </Button>
          <Link color="baseBlue" to="/" as={ReactLink}>
            Request a new one-time password
          </Link>
        </Stack>
      </VStack>
    </View>
  )
}

export default OccasionalCustomerContainer
