import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { ReservationOccasionalCustomerFormValues } from '../index'
import { useTranslation } from 'react-i18next'
import { paymentMethods } from '../../../../utils/constants'
import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react'

type KeyOfPaymentMethodsType = keyof typeof paymentMethods

type PaymentMethodsProps = {
  control: Control<ReservationOccasionalCustomerFormValues>
}

const PaymentMethods = ({ control }: PaymentMethodsProps) => {
  const paymentMethodKeys = Object.keys(paymentMethods) as KeyOfPaymentMethodsType[]
  const { t } = useTranslation()

  return (
    <Controller
      control={control}
      name="preSelectedPaymentMethod"
      rules={{ required: true }}
      render={({ field: { onChange, value }, fieldState: { invalid } }) => (
        <Box>
          {paymentMethodKeys.map((type, idx) => {
            return (
              <Box key={idx}>
                <Text mb={[2, 3]} fontWeight="normal" fontSize="xl">
                  {t(`paymentMethod.${type}`)}
                </Text>
                <Grid
                  templateColumns={[
                    '1fr',
                    'repeat(2, 1fr)',
                    'repeat(4, 1fr)',
                    'repeat(5, 1fr)'
                  ]}
                  gap={3}
                  mb={[5, 6]}
                >
                  {paymentMethods[type].map((method) => (
                    <Flex
                      key={method.id}
                      bg={value === method.id ? 'baseSail' : 'baseBlueUltraLight'}
                      onClick={() => onChange(method.id)}
                      p={4}
                      justifyContent="center"
                      alignItems="center"
                      cursor="pointer"
                      borderRadius={10}
                    >
                      <Image
                        maxWidth="70%"
                        objectFit="contain"
                        w={['80px', '130px']}
                        h={['50px', '65px']}
                        {...method}
                      />
                    </Flex>
                  ))}
                </Grid>
              </Box>
            )
          })}
          {invalid && (
            <Text color="red.400">{t('error.payment_method_required')}</Text>
          )}
        </Box>
      )}
    />
  )
}

export default PaymentMethods
