import React, { FC, useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import reservationStore from '../../../stores/ReservationStore'
import View from '../../../components/View/View'
import { runInAction, toJS } from 'mobx'
import { DeepPartial, FormProvider, useForm } from 'react-hook-form'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  ListItem,
  OrderedList
} from '@chakra-ui/react'
import {
  AssignmentIcon,
  CreditCardIcon,
  DoneIcon,
  ErrorIcon,
  EventIcon,
  FaceIcon
} from '../../../icons'
import { useTranslation } from 'react-i18next'
import Confirmation from '../../reservation/components/Confirmation/Confirmation'
import DriverAndServices, {
  DriverListFormValues
} from '../../reservation/components/DriverAndServices/DriverAndServices'
import CargoInformation from '../../reservation/components/CargoInformation/CargoInformation'
import DepartureComponent from '../../reservation/components/DepartureComponent/DepartureComponent'
import { DriversFormValues } from '../../reservation/components/Confirmation/SendLinkToDriver'
import { getQueryAction, getQueryParams } from '../../../utils/helpers'
import { EDIT, ReservationSteps } from '../../../utils/constants'
import Payment, { PaymentFormValues } from './Payment'
import {
  cargoInfoFields,
  driverDefaultValues,
  ReservationNativeFields
} from '../../reservation/ReservationContainer'
import userStore from '../../../stores/UserStore'
import {
  decipherBookingRegNumbers,
  isValidPrice
} from '../../../utils/bookingHelpers'
import { getCargoInfoErrors } from '../../../utils/reservationsHelpers'
import BaseFormLabel from '../../../components/Form/BaseFormLabel'
import toast from '../../../helpers/toast'
import { Action } from '../../../services/types'
import DriverServiceAccordion from '../../../components/DriverServiceAccordion'

export type ReservationOccasionalCustomerFormValues = ReservationNativeFields &
  DriversFormValues &
  DriverListFormValues &
  PaymentFormValues & {
    isTermsAndConditionsAccepted: boolean
  }

const defaultValues: DeepPartial<ReservationOccasionalCustomerFormValues> = {
  width: 0,
  height: 0,
  powerConnection: false,
  email: '',
  phone: '',
  drivers: [driverDefaultValues],
  isTermsAndConditionsAccepted: false,
  vehicleLength: 0,
  payee: {
    firstName: '',
    lastName: '',
    emailAddress: userStore.customer.emailContact?.contactInformation || '',
    postalCode: userStore.customer.postalCode,
    countryExtCode: userStore.customer.countryExtCode,
    postalDistrict: userStore.customer.postalDistrict,
    streetAddress: userStore.customer.streetAddress
  },
  preSelectedPaymentMethod: ''
}

const payeeFields: (keyof ReservationOccasionalCustomerFormValues)[] = [
  'payee',
  'isTermsAndConditionsAccepted',
  'preSelectedPaymentMethod'
]

const Reservation: FC = observer(() => {
  const { step: rawStep } = useParams<{ step: string }>()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const step = rawStep!

  const [active, setActive] = useState<number>(0)

  const { booking } = reservationStore

  const methods = useForm<ReservationOccasionalCustomerFormValues>({
    defaultValues: { ...defaultValues },
    mode: 'onChange'
  })

  const action = getQueryAction(location.search)

  const handleQueryParams = useCallback(
    (search: string) => {
      const bookingCode = getQueryParams(search, 'bookingCode')
      if (bookingCode) {
        reservationStore.fetchBooking(bookingCode).then(methods.reset)
      } else {
        reservationStore.reset()
        reservationStore.fetchDepartureCategories()
      }
    },
    [methods]
  )

  useEffect(() => {
    const search = location.search

    reservationStore.fetchDeparturePorts()
    reservationStore.fetchNationalities()
    reservationStore.loadSavedDrivers()
    setActive(ReservationSteps[step] || 0)
    handleQueryParams(search)
    reservationStore.registerAutorun()

    return () => {
      reservationStore.disposeAutorun()
      methods.reset(defaultValues)
    }
  }, [location.search, step, handleQueryParams])

  const toggleActive = (id: number) => {
    setActive((prevValue) => {
      return prevValue === id ? -1 : id
    })
  }

  const onMakeReservation = methods.handleSubmit(
    async (values) => {
      reservationStore.setValues(values)

      const [bookingCode] = await reservationStore.makeReservation(action, values)

      if (!bookingCode) {
        if (action === EDIT) {
          const [bookingCode] = await reservationStore.makeReservation(
            Action.New,
            values
          )
          navigate(`/payment?bookingCode=${bookingCode}`)
        }
        return
      }

      navigate(`/payment?bookingCode=${bookingCode}`)

      // should we generate these only after payment has been made?
      // await generateDriverToken({
      //   phone,
      //   bookingCode,
      //   email,
      //   editing: action === EDIT
      // })
    },
    () => {
      toast({
        status: 'error',
        description:
          'Please check the information above to make or create a booking.'
      })
    }
  )

  const driverListInvalid = !!methods.formState.errors.drivers?.length
  const payeeInvalid = payeeFields.some((field) => !!methods.formState.errors[field])
  const cargoInformationInvalid = cargoInfoFields.some(
    (field) => !!methods.formState.errors[field]
  )

  const notEditing =
    action !== EDIT ||
    (isValidPrice(booking.bookingBalance) && booking.bookingBalance > 0)

  const cargoInfoErrors = getCargoInfoErrors(methods.formState.errors)

  return (
    <View title={t(action === EDIT ? 'edit_reservation' : 'new_reservation')}>
      <FormProvider {...methods}>
        <Accordion
          allowToggle
          defaultIndex={active}
          index={active}
          border="1px solid #E2E8F0"
        >
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  onClick={() => toggleActive(0)}
                  bgColor={(isExpanded && 'baseCyan') || undefined}
                >
                  <EventIcon />
                  <Box flex="1" textAlign="left" ml={3} fontSize="md" py={2}>
                    {t('departure_time_and_date')}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <DepartureComponent
                    id={0}
                    action={action}
                    active={active === 0}
                    onMakeReservation={onMakeReservation}
                    toggleActive={toggleActive}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  onClick={() => toggleActive(1)}
                  bgColor={(isExpanded && 'baseCyan') || undefined}
                >
                  <AssignmentIcon />
                  <Box flex="1" textAlign="left" ml={3} fontSize="md" py={2}>
                    {!!cargoInfoErrors.length ? (
                      <BaseFormLabel
                        tooltipProps={{ isDisabled: !cargoInfoErrors.length }}
                        tooltipText={
                          <OrderedList spacing={3}>
                            {cargoInfoErrors.map((error, index) => (
                              <ListItem key={index}>{error}</ListItem>
                            ))}
                          </OrderedList>
                        }
                        icon={ErrorIcon}
                        label={t('cargo_information')}
                      />
                    ) : (
                      t('cargo_information')
                    )}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <CargoInformation
                    id={1}
                    action={action}
                    active={active === 1}
                    onMakeReservation={onMakeReservation}
                    toggleActive={toggleActive}
                    isInvalid={cargoInformationInvalid}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
          <AccordionItem>
            {({ isExpanded }) => (
              <DriverServiceAccordion
                action={action}
                active={active}
                driverListInvalid={driverListInvalid}
                onMakeReservation={onMakeReservation}
                toggleActive={toggleActive}
                isExpanded={isExpanded}
              />
            )}
          </AccordionItem>
          {notEditing && (
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    onClick={() => toggleActive(3)}
                    bgColor={(isExpanded && 'baseCyan') || undefined}
                  >
                    <CreditCardIcon fill={(payeeInvalid && 'red') || undefined} />
                    <Box
                      flex="1"
                      textAlign="left"
                      ml={3}
                      fontSize="md"
                      py={2}
                      color={(payeeInvalid && 'red') || undefined}
                    >
                      {t('payment')}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Payment
                      id={3}
                      active={active === 3}
                      action={action}
                      onMakeReservation={onMakeReservation}
                      toggleActive={toggleActive}
                    />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          )}
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  onClick={() => toggleActive(notEditing ? 4 : 3)}
                  bgColor={(isExpanded && 'baseCyan') || undefined}
                >
                  <DoneIcon />
                  <Box flex="1" textAlign="left" ml={3} fontSize="md" py={2}>
                    {t('confirmation')}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  <Confirmation
                    id={4}
                    active={active === 4}
                    action={action}
                    onMakeReservation={onMakeReservation}
                    toggleActive={toggleActive}
                  />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </FormProvider>
    </View>
  )
})

export default Reservation
