import classNames from 'classnames'
import React from 'react'
import { IconBaseProps } from 'react-icons'
import { FaCircle, FaTruck } from 'react-icons/fa'

import { CargoDeparture } from '../../api/cargoSelfServiceAPI'
import { resolveStatus } from '../../utils/helpers'
import css from './Status.module.css'

export enum EnumStatusIconType {
  truck = 'TRUCK',
  circle = 'CIRCLE'
}

type Props = {
  departure: CargoDeparture
  iconType?: EnumStatusIconType
} & IconBaseProps

const Status: React.FC<Props> = ({
  departure,
  className,
  iconType = EnumStatusIconType.truck,
  ...props
}) => {
  switch (iconType) {
    case EnumStatusIconType.truck: {
      return (
        <FaTruck
          {...props}
          className={classNames(className, css[resolveStatus(departure)])}
        />
      )
    }
    case EnumStatusIconType.circle: {
      return (
        <FaCircle
          {...props}
          className={classNames(className, css[resolveStatus(departure)])}
        />
      )
    }
  }
}

export default Status
