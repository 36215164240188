import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

import css from './Row.module.css'

type Props = PropsWithChildren<{
  top?: boolean
  bottom?: boolean
  right?: boolean
  left?: boolean
  border?: boolean
  className?: string
  wrap?: boolean
}>

const Row: React.FC<Props> = ({
  children,
  top,
  bottom,
  right,
  left,
  border,
  className,
  wrap
}) => (
  <div
    className={classNames(
      css.component,
      {
        [css.top]: top,
        [css.bottom]: bottom,
        [css.left]: left,
        [css.right]: right,
        [css.border]: border,
        [css.wrap]: wrap
      },
      className
    )}
  >
    {children}
  </div>
)

export default Row
