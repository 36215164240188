import classNames from 'classnames'
import { format } from 'date-fns'
import { forEach, isEmpty } from 'lodash'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import { MdWatchLater } from 'react-icons/md'

import Container from '../../../../components/Container/Container'
import Row from '../../../../components/Row/Row'
import { CargoBooking, CargoDeparture } from '../../../../api/cargoSelfServiceAPI'
import ErrorStore from '../../../../stores/ErrorStore'
import reservationsStore from '../../../../stores/ReservationsStore'
import { keyToTime, makeCancelable } from '../../../../utils/helpers'
import Booking from '../booking/Booking'
import css from './DeparturesContainer.module.css'
import api from '../../../../services/api'
import { useTranslation } from 'react-i18next'

type Props = {
  isActive: boolean
  onClick: () => void
  departures: Record<string, CargoDeparture>
  date: string
  bookingCount: any
  pastReservations?: boolean
}

let cancellableUpdate: Partial<ReturnType<typeof makeCancelable>> = {}

const DeparturesContainer: FC<Props> = observer((props) => {
  const { departures, onClick, pastReservations, date, bookingCount, isActive } =
    props

  const [activeIndex, setActiveIndex] = useState('')
  const [bookings, setBookings] = useState<Record<string, CargoBooking[]>>({})
  const { t } = useTranslation()

  const onClickIndex = (id: string) => {
    setActiveIndex((activeIndex) => (activeIndex === id ? '' : id))
  }

  const fetchBookingsForDeparture = (
    departureCode: string,
    journeyCode: string,
    key: string
  ) => {
    ErrorStore.addLoader()
    api.cargoSelfService
      .getCargoBookingsForDeparture(
        departureCode,
        journeyCode,
        {
          includeOk: true,
          includeCancelled: false,
          pageSize: 1000
        },
        { headers: { 'Cache-Control': 'no-cache', pragma: 'no-cache' } }
      )
      .then(({ data }) => {
        ErrorStore.removeLoader()
        setBookings((state) => {
          if (!data.bookings) {
            return state
          }
          return {
            ...state,
            [key]: data.bookings
          }
        })
      })
      .catch((e) => {
        console.error(e)
        ErrorStore.removeLoader()
      })
  }

  useEffect(() => {
    if (isActive && isEmpty(bookings)) {
      forEach(departures, (departure: CargoDeparture, key) => {
        fetchBookingsForDeparture(
          departure.departureCode!,
          departure.journeyCode!,
          key
        )
      })
    }
    return () => {
      cancellableUpdate?.cancel?.()
    }
  }, [departures, bookings, isActive])

  const deleteBooking = (key: string, bookingCode: string | undefined) => {
    cancellableUpdate = makeCancelable(reservationsStore.deleteBooking(bookingCode!))

    cancellableUpdate.promise?.then((success) => {
      if (!success) {
        return
      }

      setBookings((bookings) => ({
        ...bookings,
        [key]: bookings[key].filter(
          (x: CargoBooking) => x.bookingCode !== bookingCode
        )
      }))
    })
  }

  const title = () => {
    return (
      <div className={css.title}>
        <div className={css.titleHeader}>
          {`${format(date, 'D.M.YYYY')} -
  ${bookingCount.total} ${t('reservations')}`}
        </div>
        <div className={css.titleLegend}>
          {bookingCount.PS_RQ > 0 && (
            <div
              className={classNames(css.titleLegendElement, css.waiting)}
              title="Request waiting for approval"
            >
              {bookingCount.PS_RQ || ''}
            </div>
          )}
          {bookingCount.NSC > 0 && (
            <div
              className={classNames(css.titleLegendElement, css.noshow)}
              title="No show"
            >
              {bookingCount.NSC || ''}
            </div>
          )}
        </div>
      </div>
    )
  }

  const portNameByCode = (departurePortExtCode: string) => {
    const { portName = '' } =
      reservationsStore.ports.find(
        ({ portExtCode }) => departurePortExtCode === portExtCode
      ) || {}

    return portName
  }

  return (
    <Container
      title={title()}
      icon={<MdWatchLater />}
      full
      onClick={onClick}
      isActive={isActive}
    >
      <div className={css.container}>
        {Object.keys(departures).map((key, i) => (
          <div key={i}>
            <h3 className={css.departure}>
              {keyToTime(key)}{' '}
              {portNameByCode(departures[key].departurePortExtCode!)}
            </h3>
            <Row top left className={css.wrapRow}>
              {(bookings[key] || []).map((booking: CargoBooking) => {
                const { bookingCode } = booking
                return (
                  <Booking
                    pastReservations={pastReservations}
                    isActive={activeIndex === bookingCode}
                    onClick={() => onClickIndex(bookingCode!)}
                    departureDatetime={date + ' ' + keyToTime(key)}
                    key={bookingCode}
                    deleteBooking={() => deleteBooking(key, bookingCode)}
                    booking={booking}
                  />
                )
              })}
            </Row>
          </div>
        ))}
      </div>
    </Container>
  )
})

export default DeparturesContainer
