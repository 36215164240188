import React, { FC, PropsWithChildren } from 'react'
import {
  Box,
  Button,
  CheckboxProps,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import BaseCheckbox from '../Form/BaseCheckbox'
import {
  FieldValues,
  Path,
  useController,
  UseControllerProps
} from 'react-hook-form'
import { observer } from 'mobx-react'
import { extractErrorStatus } from '../../helpers/forms'
import { useTranslation } from 'react-i18next'

type NativeProps = {
  modalDescription?: React.ReactNode
  modalTitle?: React.ReactNode
  description?: React.ReactNode
}

type Props<T extends FieldValues> = PropsWithChildren<
  UseControllerProps<T, Path<T>>
> &
  CheckboxProps &
  NativeProps

type ModalContentProps = PropsWithChildren<
  Pick<ModalProps, 'isOpen' | 'onClose'> & {
    onAccept: () => void
    title?: React.ReactNode
    isDisabled?: boolean
  }
>

const CheckboxModalContent: FC<ModalContentProps> = (props) => {
  const { isOpen, onClose, onAccept, children, title, isDisabled } = props
  const { t } = useTranslation()

  return (
    <Modal
      preserveScrollBarGap
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset="slideInBottom"
      scrollBehavior="inside"
      size="4xl"
    >
      <ModalOverlay />
      <ModalContent>
        {title ? <ModalHeader>{title}</ModalHeader> : null}
        <ModalCloseButton />
        <ModalBody as={Stack} spacing={3}>
          {children}
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={isDisabled}
            onClick={() => {
              onClose()
              onAccept()
            }}
          >
            {t('accept')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const FormCheckbox = <T extends FieldValues>(props: Props<T>) => {
  const {
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    children,
    description,
    modalTitle,
    modalDescription,
    ...rest
  } = props

  const { isOpen, onClose, onToggle } = useDisclosure()

  const insertModal = modalTitle || modalDescription

  const {
    field: { value, onBlur, ref, onChange },
    fieldState
  } = useController({
    control,
    name,
    rules,
    shouldUnregister,
    defaultValue
  })

  const isRequired = !!rules?.required

  const [errorMessage, isInvalid] = extractErrorStatus(fieldState)

  return (
    <FormControl
      isInvalid={isInvalid}
      isRequired={isRequired}
      as={Flex}
      alignItems="flex-start"
    >
      <BaseCheckbox
        {...rest}
        mr={3}
        mt={1}
        isInvalid={isInvalid}
        isRequired={isRequired}
        onBlur={onBlur}
        ref={ref}
        name={name}
        onChange={onChange}
        isChecked={value as boolean}
      />
      <Box>
        {children && (
          <FormLabel cursor="pointer" color="baseBlue" mb={2}>
            {children}
          </FormLabel>
        )}
        {description && (
          <Text
            as="span"
            cursor={insertModal ? 'pointer' : undefined}
            _hover={insertModal ? { textDecoration: 'underline' } : undefined}
            onClick={onToggle}
            fontWeight="normal"
            color={insertModal ? 'baseBlue' : 'baseBlack'}
          >
            {description}
          </Text>
        )}
        {isInvalid && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      </Box>
      {insertModal && (
        <CheckboxModalContent
          isDisabled={rest.isDisabled}
          title={modalTitle}
          isOpen={isOpen}
          onClose={onClose}
          onAccept={() => {
            onChange(true)
          }}
        >
          {modalDescription}
        </CheckboxModalContent>
      )}
    </FormControl>
  )
}

export default observer(FormCheckbox)
