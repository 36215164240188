/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DriverAuthToken {
  /**
   * Token (to be submitted as X-Carus-Driver header) for this entity.
   * @minLength 0
   * @maxLength 50
   */
  token: string
  /** @format date-time */
  expiryTime: string
}

export interface DriverTokenResponse {
  authToken: DriverAuthToken
  /** Indicates that token was sent via email */
  emailSent: boolean
  /** Indicates that token was sent via sms */
  smsSent: boolean
}

export interface Error400 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error400RequiredAction
}

export interface Error401 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error401RequiredAction
}

export interface Error404 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error404RequiredAction
  fields?: string[]
  resourceReference?: ResourceReference
}

export interface ResourceIdentifier {
  identifierType: ResourceIdentifierIdentifierType
  /**
   * @minLength 1
   * @maxLength 1000
   */
  value: string
}

export interface ResourceReference {
  resourceType: ResourceReferenceResourceType
  /**
   * @maxItems 2147483647
   * @minItems 1
   */
  identifiers: ResourceIdentifier[]
}

export interface Error500 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error500RequiredAction
}

export interface GenerateDriverToken {
  sendEmail?: SendEmail
  sendSms?: SendSms
  /** @format date-time */
  expiryTime: string
}

export interface SendEmail {
  /**
   * @minLength 1
   * @maxLength 50
   */
  emailAddress: string
  /**
   * @minLength 1
   * @maxLength 10
   */
  emailTemplateCode: string
}

export interface SendSms {
  /**
   * The phone number (should start with '+' and have at least 3 digits, whitespaces not allowed)
   * @minLength 4
   * @maxLength 50
   */
  phoneNumber: string
  /**
   * @minLength 1
   * @maxLength 10
   */
  smsTemplateCode: string
}

export interface Link {
  /** Full url. */
  href?: string
}

export interface ResourceLinks {
  self?: Link
}

export interface Error403 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error403RequiredAction
  fields?: string[]
  resourceReference?: ResourceReference
}

export interface Error406 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error406RequiredAction
}

export interface Error409 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error409RequiredAction
  fields?: string[]
  resourceReference?: ResourceReference
}

export interface Error429 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error429RequiredAction
}

export interface CargoNameListRow {
  /**
   * reference number
   * @minLength 0
   * @maxLength 10
   */
  referenceNumber?: string
  /**
   * qr code in text format
   * @minLength 0
   * @maxLength 2000
   */
  qrCodeText?: string
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  rowNumber?: number
  /**
   * guest code
   * @minLength 0
   * @maxLength 10
   */
  guestCode?: string
  /**
   * first name
   * @minLength 0
   * @maxLength 50
   */
  firstName?: string
  /**
   * middle name
   * @minLength 0
   * @maxLength 50
   */
  middleName?: string
  /**
   * last name
   * @minLength 0
   * @maxLength 50
   */
  lastName?: string
  /**
   * The title code
   * @minLength 0
   * @maxLength 5
   */
  titleCode?: string
  /**
   * passenger's day of birth
   * @format date
   */
  dateOfBirth?: string
  /**
   * passenger's birth place
   * @minLength 0
   * @maxLength 50
   */
  birthPlace?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  nationalityExtCode?: string
  /**
   * gender code
   * @minLength 0
   * @maxLength 1
   */
  genderCode?: string
  /** disability flag */
  disability?: boolean
  /**
   * The email address
   * @minLength 0
   * @maxLength 100
   */
  email?: string
  /**
   * The mobile number
   * @minLength 0
   * @maxLength 50
   */
  mobile?: string
  /**
   * The passport number
   * @minLength 0
   * @maxLength 30
   */
  passportNumber?: string
  /**
   * passenger's passport expiry date
   * @format date
   */
  passportExpiryDate?: string
  /**
   * passenger's passport issue date
   * @format date
   */
  passportIssueDate?: string
  /**
   * travel document type code
   * @minLength 0
   * @maxLength 10
   */
  travelDocumentTypeCode?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  travelDocumentCountryExtCode?: string
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  travelDocumentStateCode?: string
  /**
   * passenger's visa number
   * @minLength 0
   * @maxLength 20
   */
  visaNumber?: string
  /**
   * disability note
   * @minLength 0
   * @maxLength 1000
   */
  disabilityNote?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** group leader flag */
  groupLeader?: boolean
  /**
   * The guest type external code
   * @minLength 0
   * @maxLength 50
   */
  guestTypeCode?: string
  /** assistance flag */
  assistance?: boolean
  /** assistance approved flag */
  assistanceApproved?: boolean
  /**
   * contact number in destination
   * @minLength 0
   * @maxLength 50
   */
  destinationContactNumber?: string
  /** email confirmation flag */
  mailConfirmation?: boolean
  /** sms confirmation flag */
  smsConfirmation?: boolean
}

export interface CargoVehicleRegistrationNumberInfo {
  primary?: boolean
  /**
   * @minLength 0
   * @maxLength 50
   */
  value: string
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  stateCode?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  countryCode?: string
  /** This contains information about the driver. Only one of name and name list row number is populated.  */
  driverInfo?: DriverInfo
  /**
   * \*DEPRECATED*\ This field will be removed in a future release - use corresponding field under driverInfo instead.
   * @format int32
   * @max 99999
   */
  driversNameListRowNumber?: number
  /**
   * \*DEPRECATED*\ This field will be removed in a future release - use corresponding field under driverInfo instead.
   * @minLength 0
   * @maxLength 50
   */
  driverNote?: string
}

/** Object representing Cargo description object */
export interface DriverCargoDescription {
  /**
   * row number in the backend
   * @format int32
   */
  descriptionRowNumber?: number
  /**
   * the quantity of the items
   * @max 999999999999.99
   * @exclusiveMax false
   */
  quantity?: number
  /**
   * the type code
   * @minLength 0
   * @maxLength 10
   */
  cargoUnitTypeCode?: string
  /**
   * description
   * @minLength 0
   * @maxLength 1000
   */
  cargoDescription: string
  /**
   * gross weight
   * @max 9999999999.99
   * @exclusiveMax false
   */
  grossWeight?: number
  /**
   * net weight
   * @max 9999999999.99
   * @exclusiveMax false
   */
  netWeight?: number
  /**
   * customs code
   * @minLength 0
   * @maxLength 10
   */
  customsCode?: string
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  originCountryExtCode?: string
  /** free circulation flag */
  inFreeCirculation?: boolean
  /** in transit flag */
  inTransit?: boolean
  /** from bonded warehouse flag */
  fromBondedWarehouse?: boolean
}

/** This contains information about the driver. Only one of name and name list row number is populated.  */
export interface DriverInfo {
  /**
   * @minLength 0
   * @maxLength 50
   */
  name?: string
  /**
   * @format int32
   * @min 0
   * @max 99999
   */
  nameListRowNumber?: number
}

export interface UpdateDriverBookingJourney {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  rowNumber: number
  bookingRows?: UpdateDriverBookingJourneyBookingRow[]
}

export interface UpdateDriverBookingJourneyBookingRow {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  rowNumber?: number
  /**
   * This is the external reference. E.g. an agent might add their booking or order ID as a reference here.
   * @minLength 0
   * @maxLength 60
   */
  referenceNumber?: string
  /**
   * the weight of the load
   * @max 99999999.99
   * @exclusiveMax false
   */
  loadWeight?: number
  /**
   * the weight of the vehicle
   * @max 99999999.99
   * @exclusiveMax false
   */
  tareWeight?: number
  /**
   * the length of the vehicle
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  length?: number
  width?: number
  height?: number
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** @format int32 */
  driverCount?: number
  customerCodeConsignee?: UpdateDriverBookingJourneyBookingRowCustomerCodeConsignee
  consignee?: UpdateDriverBookingJourneyBookingRowConsignee
  consigneeCountryCode?: UpdateDriverBookingJourneyBookingRowConsigneeCountryCode
  registrationNumbers?: CargoVehicleRegistrationNumberInfo[]
  cargoDescriptions?: DriverCargoDescription[]
}

export interface UpdateDriverBookingJourneyBookingRowConsignee {
  /**
   * Consignee information.
   * @minLength 0
   * @maxLength 1000
   */
  value?: string
}

export interface UpdateDriverBookingJourneyBookingRowConsigneeCountryCode {
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 0
   * @maxLength 3
   */
  value?: string
}

export interface UpdateDriverBookingJourneyBookingRowCustomerCodeConsignee {
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  value?: string
}

/** Update booking object. */
export interface UpdateDriverBookingRequest {
  /**
   * Booking version number
   * @format int32
   * @min 1
   * @max 999999999
   */
  latestBookingVersionNumber?: number
  /** @format date-time */
  lastModifiedTime: string
  /**
   * This is the external reference. E.g. an agent might add their booking or order ID as a reference here.
   * @minLength 0
   * @maxLength 60
   */
  referenceNumber?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  namelist?: CargoNameListRow[]
  journey?: UpdateDriverBookingJourney
}

export interface CargoBookingAshoreReferencedNameListRow {
  /**
   * references to a passenger row in the booking name list
   * @format int32
   * @max 99999
   */
  nameListRowNumber?: number
}

export interface CargoBookingAssignedCabinRow {
  cabinNumberCode?: string
  cabinNameList?: CargoBookingCabinNameListRow[]
}

export interface CargoBookingCabinNameListRow {
  /**
   * Field references to a passenger in booking name list
   * @format int32
   * @max 99999
   */
  bookingNameListRowNumber?: number
}

export interface CargoBookingContactDetail {
  /**
   * contact's row number controlled by the backend
   * @format int32
   * @min 0
   * @max 99999
   */
  contactRowNumber?: number
  /**
   * contact type
   * @minLength 0
   * @maxLength 5
   */
  contactType?: string
  /**
   * contact info
   * @minLength 0
   * @maxLength 50
   */
  contactInfo?: string
  /** send info flag */
  sendInfo?: boolean
  /** auto sms flag */
  autoSms?: boolean
}

/** flags are used by the backend to advice the frontend what options are available to the booking at the time of fetching it */
export interface CargoBookingControlFlags {
  /** flags describes i the booking fits the cargo api definition of a valid booking */
  isValidCargoBooking?: boolean
  /** flag describes if the booking is currently being locked by another user (cannot be read or written) */
  isLocked?: boolean
  /** flag describes if canceling of the booking is possible through cargo api */
  isCancelAllowed?: boolean
  /** code describing the reason why cancel is not allowed */
  cancelNotAllowedReasonCode?: CargoBookingControlFlagsCancelNotAllowedReasonCode
  /** flag describes if updating of the booking is possible through cargo api */
  isUpdateAllowed?: boolean
  updateNotAllowedReasonCode?: CargoBookingControlFlagsUpdateNotAllowedReasonCode
}

export interface CargoBookingRowAssignedCabins {
  /** flag specifying of the cabins has been auto-assigned */
  autoAssigned?: boolean
  cabins?: CargoBookingAssignedCabinRow[]
}

export interface CargoBookingRowCabinProps {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  assignedCabins?: CargoBookingRowAssignedCabins
  rowItems?: CargoBookingRowItem[]
}

export interface CargoBookingRowItem {
  /**
   * reference number
   * @minLength 0
   * @maxLength 30
   */
  bookingRowItemReference: string
}

export interface CargoBookingRowMealProps {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date-time */
  startDate?: string
  rowItems?: CargoBookingRowItem[]
}

export interface CargoBookingRowMiscProps {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date-time */
  startDate?: string
  rowItems?: CargoBookingRowItem[]
}

export interface CargoBookingRowPassengerProps {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  rowItems?: CargoBookingRowItem[]
}

export interface CargoBookingRowTimeSlotProps {
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /** @format date-time */
  startDate?: string
  /** @format date-time */
  endDate?: string
  rowItems?: CargoBookingRowItem[]
}

/** Object representing Vehicle Make Model */
export interface CargoBookingVehicleMakeModel {
  /**
   * @minLength 0
   * @maxLength 50
   */
  vehicleMakeName?: string
  /**
   * @minLength 0
   * @maxLength 50
   */
  vehicleModelName?: string
  /**
   * @minLength 0
   * @maxLength 20
   */
  vehicleMakeModelCode?: string
}

export interface CargoExtraRowNote {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  rowNumber?: number
  /**
   * @minLength 0
   * @maxLength 10
   */
  noteType?: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  noteData?: string
}

/** \*DEPRECATED*\ This field will be removed in a future release - use bookingRowItemReference field under rowItems instead. */
export interface CargoExtraRowReferenceNumber {
  /**
   * @minLength 0
   * @maxLength 10
   */
  referenceNumber?: string
}

/** Object representing Cargo IMDG object */
export interface CargoImdg {
  /**
   * IMDG class code
   * @minLength 0
   * @maxLength 10
   */
  imdgClassCode?: string
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  imdgRowNumber?: number
  /**
   * UN number code
   * @minLength 0
   * @maxLength 10
   */
  unNumberCode?: string
  /** Object representing UN name. */
  unName?: CargoUnName
  /** Object representing the packaging options for class */
  emsAndPackagingGroup?: CargoImdgEmsAndPackagingGroup
  /**
   * weight
   * @max 99999999.99
   * @exclusiveMax false
   */
  weight?: number
  /**
   * net weight
   * @max 99999999.99
   * @exclusiveMax false
   */
  netWeight?: number
  /**
   * flash point
   * @format int32
   * @max 99999
   */
  flashPoint?: number
  /**
   * the note
   * @minLength 0
   * @maxLength 1000
   */
  note?: string
  /** marine pollutant flag */
  marinePollutant?: boolean
  /** limited quantity flag */
  limitedQuantity?: boolean
}

/** Object representing the packaging options for class */
export interface CargoImdgEmsAndPackagingGroup {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  emsAndPackagingGroupRowNumber?: number
  /**
   * ems
   * @minLength 0
   * @maxLength 10
   */
  ems?: string
  /**
   * packaging group code
   * @format int32
   * @max 9
   */
  packagingGroupCode?: number
}

export interface CargoJourneyControlFlags {
  /** flag specifies the journey row is updatable */
  isUpdateAllowed?: boolean
  updateNotAllowedReasonCode?: CargoJourneyControlFlagsUpdateNotAllowedReasonCode
}

/** Object representing Cargo registration number */
export interface CargoRegistrationNumber {
  primary?: boolean
  /**
   * Registration plate number
   * @minLength 0
   * @maxLength 50
   */
  registrationNumberCode: string
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  registrationStateCode?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  registrationCountryCode?: string
  /** This contains information about the driver. Only one of name and name list row number is populated.  */
  driverInfo?: DriverInfo
  /**
   * \*DEPRECATED*\ This field will be removed in a future release - use corresponding field under driverInfo instead.
   * @format int32
   * @max 99999
   */
  driversNameListRowNumber?: number
  /**
   * \*DEPRECATED*\ This field will be removed in a future release - use corresponding field under driverInfo instead.
   * @minLength 0
   * @maxLength 50
   */
  driverNote?: string
}

/** Object representing UN name. */
export interface CargoUnName {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  unNameRowNumber?: number
  /**
   * Full UN name
   * @minLength 0
   * @maxLength 200
   */
  name?: string
}

export interface DriverCargoAshoreBookingRow {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  /**
   * premise code
   * @minLength 0
   * @maxLength 10
   */
  premiseCode?: string
  /**
   * start date
   * @format date-time
   */
  startDate?: string
  /**
   * end date
   * @format date-time
   */
  endDate?: string
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category name used externally
   * @minLength 0
   * @maxLength 101
   */
  extCategoryDisplayName?: string
  availabilityGroupCode?: DriverCargoAshoreBookingRowAvailabilityGroupCode
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category Specification Name
   * @minLength 0
   * @maxLength 80
   */
  categorySpecificationName?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** auto attached flag */
  isAutoAttached?: boolean
  names?: CargoBookingAshoreReferencedNameListRow[]
  /** \*DEPRECATED*\ This field will be removed in a future release - use bookingRowItemReference field under rowItems instead. */
  ashoreReferenceNumbers?: string[]
  rowItems?: CargoBookingRowItem[]
  connectedAshoreBookingRows?: DriverCargoAshoreConnectedBookingRow[]
}

export interface DriverCargoAshoreConnectedBookingRow {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  /**
   * premise code
   * @minLength 0
   * @maxLength 10
   */
  premiseCode?: string
  /**
   * start date
   * @format date-time
   */
  startDate?: string
  /**
   * end date
   * @format date-time
   */
  endDate?: string
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category name used externally
   * @minLength 0
   * @maxLength 101
   */
  extCategoryDisplayName?: string
  availabilityGroupCode?: DriverCargoAshoreConnectedBookingRowAvailabilityGroupCode
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category Specification Name
   * @minLength 0
   * @maxLength 80
   */
  categorySpecificationName?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** auto attached flag */
  isAutoAttached?: boolean
  names?: CargoBookingAshoreReferencedNameListRow[]
  /** \*DEPRECATED*\ This field will be removed in a future release - use bookingRowItemReference field under rowItems instead. */
  ashoreReferenceNumbers?: string[]
  rowItems?: CargoBookingRowItem[]
}

export interface DriverCargoBooking {
  /**
   * booking code in carres
   * @minLength 0
   * @maxLength 10
   */
  bookingCode?: string
  /**
   * booking version code to be used in modify requests
   * @min 1
   * @exclusiveMin false
   * @max 99999999999999
   * @exclusiveMax false
   */
  bookingVersionCode?: number
  /** is booking cancelled */
  isCancelled?: boolean
  /**
   * Status of the booking. Note that booking status comes from a user-defined list and meanings may vary. See cancelled field for a Boolean status if the booking is cancelled or not.
   * @minLength 0
   * @maxLength 10
   */
  bookingStatusCode?: string
  /** dangerous goods flag */
  hasDangerousGoods?: boolean
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCode?: string
  /**
   * agent code
   * @minLength 0
   * @maxLength 10
   */
  agentCode?: string
  /**
   * date and time of the booking
   * @format date-time
   */
  bookingTime?: string
  /**
   * last change time of the booking
   * @format date-time
   */
  bookingLastChangeTime?: string
  /**
   * bookings validity time
   * @format date-time
   */
  bookingValidTo?: string
  /**
   * api system name which originated this booking
   * @minLength 0
   * @maxLength 10
   */
  bookingApiSys?: string
  /**
   * Method of payment code of the booking
   * @minLength 0
   * @maxLength 10
   */
  methodOfPaymentCode?: string
  /**
   * due date
   * @format date
   */
  dueDate?: string
  /** flags are used by the backend to advice the frontend what options are available to the booking at the time of fetching it */
  bookingControlFlags?: CargoBookingControlFlags
  /** flag identifying a group booking */
  isGroupBooking?: boolean
  namelist?: CargoNameListRow[]
  bookingContacts?: CargoBookingContactDetail[]
  cargoJourneys?: DriverCargoJourneyRow[]
  extraBookingLines?: DriverCargoExtraBookingRow[]
  ashoreBookingLines?: DriverCargoAshoreBookingRow[]
  /**
   * title of the customer
   * @minLength 0
   * @maxLength 5
   */
  titleCode?: string
  /**
   * first name
   * @minLength 0
   * @maxLength 50
   */
  firstName?: string
  /**
   * middle name
   * @minLength 0
   * @maxLength 50
   */
  middleName?: string
  /**
   * last name
   * @minLength 0
   * @maxLength 50
   */
  lastName?: string
  /**
   * customer's street address
   * @minLength 0
   * @maxLength 200
   */
  streetAddress?: string
  /**
   * customer's postal code
   * @minLength 0
   * @maxLength 10
   */
  postalCode?: string
  /**
   * customer's postal district
   * @minLength 0
   * @maxLength 50
   */
  postalDistrict?: string
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  countryExtCode?: string
  /**
   * State ID in country of booking
   * @minLength 0
   * @maxLength 10
   * @example "CA"
   */
  stateCode?: string
  /**
   * customer's phone area
   * @minLength 0
   * @maxLength 10
   */
  phoneArea?: string
  /**
   * customer's phone number
   * @minLength 0
   * @maxLength 50
   */
  phoneNumber?: string
  /**
   * Language code used externally
   * @minLength 0
   * @maxLength 10
   */
  languageExtCode?: string
  /**
   * Product code used externally (combination of brochure code and product code in CarRes)
   * @minLength 0
   * @maxLength 50
   */
  extProductCode?: string
  /**
   * Currency code used externally
   * @minLength 0
   * @maxLength 50
   */
  currencyExtCode?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /**
   * booking reference
   * @minLength 0
   * @maxLength 60
   */
  bookingReference?: string
}

export interface DriverCargoBookingRowVehicleProps {
  /**
   * the weight of the load
   * @max 99999999.99
   * @exclusiveMax false
   */
  loadWeight?: number
  /**
   * the weight of the vehicle
   * @max 99999999.99
   * @exclusiveMax false
   */
  tareWeight?: number
  /**
   * the length of the vehicle
   * @min 0
   * @exclusiveMin false
   * @max 99999999.99
   * @exclusiveMax false
   */
  length?: number
  /**
   * the width of the vehicle
   * @max 999.99
   * @exclusiveMax false
   */
  width?: number
  /**
   * the height of the vehicle
   * @max 99999999.99
   * @exclusiveMax false
   */
  height?: number
  /**
   * the amount of drivers
   * @format int32
   * @max 99999
   */
  driverCount?: number
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCodeConsignee?: string
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCodeShipper?: string
  /**
   * consignee information
   * @minLength 0
   * @maxLength 1000
   */
  consignee?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  consigneeCountryCode?: string
  /**
   * shipper information
   * @minLength 0
   * @maxLength 1000
   */
  shipper?: string
  /**
   * This is the CarRes internal ID, although it's recommended to use ISO 3166-1 Alpha-3, but it's not enforced.
   * @minLength 1
   * @maxLength 3
   */
  shipperCountryCode?: string
  /**
   * consignment note
   * @minLength 0
   * @maxLength 10
   */
  consignmentNote?: string
  /**
   * temperature
   * @minLength 0
   * @maxLength 10
   */
  temperature?: string
  /**
   * notifier information
   * @minLength 0
   * @maxLength 1000
   */
  notifier?: string
  /**
   * CarRes' internal ID of a registered customer
   * @minLength 0
   * @maxLength 10
   * @example "JOHNDOE"
   */
  customerCodeNotifier?: string
  rowItems?: CargoBookingRowItem[]
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity: number
  registrationNumbers?: CargoRegistrationNumber[]
  cargoDescriptions?: DriverCargoDescription[]
  imdgs?: CargoImdg[]
  makeModels?: CargoBookingVehicleMakeModel[]
}

export interface DriverCargoExtraBookingRow {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  /**
   * category name used externally
   * @minLength 0
   * @maxLength 101
   */
  extCategoryDisplayName?: string
  /**
   * Category Specification Name
   * @minLength 0
   * @maxLength 80
   */
  categorySpecificationName?: string
  isAutoAttached?: boolean
  /** \*DEPRECATED*\ This field will be removed in a future release - use bookingRowItemReference field under rowItems instead. */
  extraReferenceNumbers?: CargoExtraRowReferenceNumber[]
  /** @format date-time */
  startDate?: string
  rowNotes?: CargoExtraRowNote[]
  rowItems?: CargoBookingRowItem[]
  connectedExtraBookingRows?: DriverCargoExtraConnectedBookingRow[]
}

export interface DriverCargoExtraConnectedBookingRow {
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category quantity
   * @format int32
   * @min 0
   * @max 999999999
   */
  categoryQuantity?: number
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  /**
   * category name used externally
   * @minLength 0
   * @maxLength 101
   */
  extCategoryDisplayName?: string
  /**
   * Category Specification Name
   * @minLength 0
   * @maxLength 80
   */
  categorySpecificationName?: string
  isAutoAttached?: boolean
  /** \*DEPRECATED*\ This field will be removed in a future release - use bookingRowItemReference field under rowItems instead. */
  extraReferenceNumbers?: CargoExtraRowReferenceNumber[]
  /** @format date-time */
  startDate?: string
  rowNotes?: CargoExtraRowNote[]
  rowItems?: CargoBookingRowItem[]
}

export interface DriverCargoJourneyBookingRow {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  /**
   * reference number
   * @minLength 0
   * @maxLength 30
   */
  referenceNumber?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** checked in flag */
  checkedIn?: boolean
  /**
   * status code
   * @minLength 0
   * @maxLength 5
   */
  status?: string
  /** flag describing if the row was automatically attached */
  isAutoAttached?: boolean
  recordType?: DriverCargoJourneyBookingRowRecordType
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category name used externally
   * @minLength 0
   * @maxLength 101
   */
  extCategoryDisplayName?: string
  availabilityGroupType?: DriverCargoJourneyBookingRowAvailabilityGroupType
  vehicleProperties?: DriverCargoBookingRowVehicleProps
  cabinProperties?: CargoBookingRowCabinProps
  passengerProperties?: CargoBookingRowPassengerProps
  miscProperties?: CargoBookingRowMiscProps
  timeslotProperties?: CargoBookingRowTimeSlotProps
  mealProperties?: CargoBookingRowMealProps
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category Specification Name
   * @minLength 0
   * @maxLength 80
   */
  categorySpecificationName?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
  connectedBookingRows?: DriverCargoJourneyConnectedBookingRow[]
}

export interface DriverCargoJourneyConnectedBookingRow {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  bookingRowNumber?: number
  /**
   * reference number
   * @minLength 0
   * @maxLength 30
   */
  referenceNumber?: string
  /**
   * internal note
   * @minLength 0
   * @maxLength 1000
   */
  internalNote?: string
  /**
   * external note
   * @minLength 0
   * @maxLength 1000
   */
  externalNote?: string
  /** checked in flag */
  checkedIn?: boolean
  /**
   * status code
   * @minLength 0
   * @maxLength 5
   */
  status?: string
  /** flag describing if the row was automatically attached */
  isAutoAttached?: boolean
  recordType?: DriverCargoJourneyConnectedBookingRowRecordType
  /**
   * Category code used externally
   * @minLength 0
   * @maxLength 50
   */
  extCategoryCode?: string
  /**
   * category name used externally
   * @minLength 0
   * @maxLength 101
   */
  extCategoryDisplayName?: string
  availabilityGroupType?: DriverCargoJourneyConnectedBookingRowAvailabilityGroupType
  vehicleProperties?: DriverCargoBookingRowVehicleProps
  cabinProperties?: CargoBookingRowCabinProps
  passengerProperties?: CargoBookingRowPassengerProps
  miscProperties?: CargoBookingRowMiscProps
  timeslotProperties?: CargoBookingRowTimeSlotProps
  mealProperties?: CargoBookingRowMealProps
  /**
   * Category specification code of the item sold in CarRes
   * @minLength 0
   * @maxLength 5
   * @example "ADL"
   */
  categorySpecificationCode?: string
  /**
   * Category Specification Name
   * @minLength 0
   * @maxLength 80
   */
  categorySpecificationName?: string
  /**
   * Category specification quantity of the item sold in CarRes
   * @format int32
   * @min 0
   * @max 999999999
   * @example 2
   */
  categorySpecificationQuantity?: number
}

export interface DriverCargoJourneyRow {
  /**
   * item's row number
   * @format int32
   * @min 0
   * @max 99999
   */
  journeyRowNumber?: number
  /**
   * journey code in carres
   * @minLength 0
   * @maxLength 10
   */
  journeyCode?: string
  /**
   * departure code in carres
   * @minLength 0
   * @maxLength 20
   */
  departureCode?: string
  /**
   * the time of the departure
   * @format date-time
   */
  departureTime?: string
  /**
   * the time of the arrival
   * @format date-time
   */
  arrivalTime?: string
  /**
   * open journey type
   * @minLength 0
   * @maxLength 50
   */
  openJourneyType?: string
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  departurePortExtCode?: string
  /**
   * translated port name used externally
   * @minLength 0
   * @maxLength 50
   */
  departurePortName?: string
  /**
   * Port code used externally
   * @minLength 0
   * @maxLength 50
   */
  arrivalPortExtCode?: string
  /**
   * translated port name used externally
   * @minLength 0
   * @maxLength 50
   */
  arrivalPortName?: string
  /**
   * vessel code used in carres
   * @minLength 0
   * @maxLength 10
   */
  vesselCode?: string
  /**
   * the full name of the vessel
   * @minLength 0
   * @maxLength 50
   */
  vesselName?: string
  /**
   * Product code used externally (combination of brochure code and product code in CarRes)
   * @minLength 0
   * @maxLength 50
   */
  extProductCode?: string
  /** @format date-time */
  earliestCheckinTime?: string
  /** @format date-time */
  latestCheckinTime?: string
  journeyControlFlags?: CargoJourneyControlFlags
  journeyBookingRows?: DriverCargoJourneyBookingRow[]
}

export enum Error400RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error401RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error404RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum ResourceIdentifierIdentifierType {
  ACCOUNTING_JOB_NUMBER = 'ACCOUNTING_JOB_NUMBER',
  ACCOUNTING_SYSTEM_CODE = 'ACCOUNTING_SYSTEM_CODE',
  ACTOR_CODE = 'ACTOR_CODE',
  AGENT_CODE = 'AGENT_CODE',
  APISYS_CODE = 'APISYS_CODE',
  APP_CODE = 'APP_CODE',
  APP_INSTANCE_CODE = 'APP_INSTANCE_CODE',
  APP_INSTANCE_LOCALE_CODE = 'APP_INSTANCE_LOCALE_CODE',
  AUTOMAT_CODE = 'AUTOMAT_CODE',
  BOOKING_CODE = 'BOOKING_CODE',
  BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP_CODE = 'BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP_CODE',
  BOOKING_ROW_ITEM_REFERENCE = 'BOOKING_ROW_ITEM_REFERENCE',
  BOOKING_VERSION_ID = 'BOOKING_VERSION_ID',
  BOOKING_VERSION_NUMBER = 'BOOKING_VERSION_NUMBER',
  BROCHURE_CODE = 'BROCHURE_CODE',
  CARGO_TYPE_CODE = 'CARGO_TYPE_CODE',
  CATEGORY_CODE = 'CATEGORY_CODE',
  CATEGORY_GROUP_CODE = 'CATEGORY_GROUP_CODE',
  CATEGORY_STRUCT_GROUP_CODE = 'CATEGORY_STRUCT_GROUP_CODE',
  CLUB_CODE = 'CLUB_CODE',
  CLUB_LEVEL = 'CLUB_LEVEL',
  CONSIGNEE_CODE = 'CONSIGNEE_CODE',
  COUNTRY_CODE = 'COUNTRY_CODE',
  COUNTRY_EXT_CODE = 'COUNTRY_EXT_CODE',
  CURRENCY_CODE = 'CURRENCY_CODE',
  CUSTOMER_CODE = 'CUSTOMER_CODE',
  CUSTOMER_PRIVILEGE_GROUP_CODE = 'CUSTOMER_PRIVILEGE_GROUP_CODE',
  CUSTOMER_SUB_LOGIN_CODE = 'CUSTOMER_SUB_LOGIN_CODE',
  DEFAULT_ARRIVAL_PORT_CODE = 'DEFAULT_ARRIVAL_PORT_CODE',
  DEPARTURE_CODE = 'DEPARTURE_CODE',
  EMAIL_TEMPLATE_CODE = 'EMAIL_TEMPLATE_CODE',
  EINVOICE_TYPE_CODE = 'EINVOICE_TYPE_CODE',
  ETICKET_CODE = 'ETICKET_CODE',
  ETICKET_TYPE_CODE = 'ETICKET_TYPE_CODE',
  EXTERNAL_ACCESS_REFERENCE_NUMBER = 'EXTERNAL_ACCESS_REFERENCE_NUMBER',
  EXTERNAL_ACCESS_REFERENCE_TYPE = 'EXTERNAL_ACCESS_REFERENCE_TYPE',
  EXTERNAL_ACCESS_RESOURCE_CODE = 'EXTERNAL_ACCESS_RESOURCE_CODE',
  EXTERNAL_ACCESS_RESOURCE_GRANT_ACCESS_ID = 'EXTERNAL_ACCESS_RESOURCE_GRANT_ACCESS_ID',
  EXTERNAL_ACCESS_RESOURCE_TYPE = 'EXTERNAL_ACCESS_RESOURCE_TYPE',
  GUEST_TYPE_CODE = 'GUEST_TYPE_CODE',
  INVOICING_INTERVAL_CODE = 'INVOICING_INTERVAL_CODE',
  INVOICING_JOB_NUMBER = 'INVOICING_JOB_NUMBER',
  JOURNEY_CODE = 'JOURNEY_CODE',
  JOURNEY_GROUP_CODE = 'JOURNEY_GROUP_CODE',
  JOURNEY_NAME_LIST_REFERENCE = 'JOURNEY_NAME_LIST_REFERENCE',
  LANGUAGE_CODE = 'LANGUAGE_CODE',
  METHOD_OF_PAYMENT_CODE = 'METHOD_OF_PAYMENT_CODE',
  MOBILE_DEVICE_CODE = 'MOBILE_DEVICE_CODE',
  MOBILE_KEY_CODE = 'MOBILE_KEY_CODE',
  MOBILE_KEY_LOCK_TYPE = 'MOBILE_KEY_LOCK_TYPE',
  NAME_LIST_ROW_NUMBER = 'NAME_LIST_ROW_NUMBER',
  NATIONALITY_CODE = 'NATIONALITY_CODE',
  PORT_CODE = 'PORT_CODE',
  PORT_EXT_CODE = 'PORT_EXT_CODE',
  PRODUCT_CODE = 'PRODUCT_CODE',
  PAYMENT_REFERENCE = 'PAYMENT_REFERENCE',
  RECRUITMENT_METHOD_CODE = 'RECRUITMENT_METHOD_CODE',
  SHIPPER_CODE = 'SHIPPER_CODE',
  SMS_TEMPLATE_CODE = 'SMS_TEMPLATE_CODE',
  STATE_CODE = 'STATE_CODE',
  TAX_OFFICE_CODE = 'TAX_OFFICE_CODE',
  TERMS_AND_CONDITIONS_TEXT_GROUP_CODE = 'TERMS_AND_CONDITIONS_TEXT_GROUP_CODE',
  TERMS_OF_PAYMENT_CODE = 'TERMS_OF_PAYMENT_CODE',
  TERMINAL_CODE = 'TERMINAL_CODE',
  TITLE_CODE = 'TITLE_CODE',
  TRANSACTION_NUMBER = 'TRANSACTION_NUMBER',
  TRAVEL_DOCUMENT_TYPE_CODE = 'TRAVEL_DOCUMENT_TYPE_CODE',
  VEHICLE_MAKE_MODEL_CODE = 'VEHICLE_MAKE_MODEL_CODE',
  VOUCHER_CODE = 'VOUCHER_CODE',
  UNKNOWN = 'UNKNOWN'
}

export enum ResourceReferenceResourceType {
  ACCOUNTING_JOB = 'ACCOUNTING_JOB',
  ACCOUNTING_SYSTEM = 'ACCOUNTING_SYSTEM',
  ACTOR = 'ACTOR',
  AGENT = 'AGENT',
  APISYS = 'APISYS',
  APP = 'APP',
  APP_INSTANCE = 'APP_INSTANCE',
  APP_INSTANCE_LOCALE = 'APP_INSTANCE_LOCALE',
  AUTOMAT = 'AUTOMAT',
  BOOKING = 'BOOKING',
  BOOKING_ROW_ITEM = 'BOOKING_ROW_ITEM',
  BOOKING_NAME_LIST_ROW = 'BOOKING_NAME_LIST_ROW',
  BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP = 'BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP',
  BOOKING_VERSION = 'BOOKING_VERSION',
  BROCHURE_PRODUCT = 'BROCHURE_PRODUCT',
  CARGO_TYPE = 'CARGO_TYPE',
  CATEGORY_STRUCT = 'CATEGORY_STRUCT',
  CATEGORY_STRUCT_GROUP = 'CATEGORY_STRUCT_GROUP',
  CLUB = 'CLUB',
  COUNTRY = 'COUNTRY',
  CURRENCY = 'CURRENCY',
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_PRIVILEGE_GROUP = 'CUSTOMER_PRIVILEGE_GROUP',
  CUSTOMER_SUB_LOGIN = 'CUSTOMER_SUB_LOGIN',
  DEPARTURE = 'DEPARTURE',
  EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
  EINVOICE = 'EINVOICE',
  ETICKET = 'ETICKET',
  EXTERNAL_ACCESS_REFERENCE = 'EXTERNAL_ACCESS_REFERENCE',
  EXTERNAL_ACCESS_RESOURCE = 'EXTERNAL_ACCESS_RESOURCE',
  EXTERNAL_ACCESS_RESOURCE_GRANT = 'EXTERNAL_ACCESS_RESOURCE_GRANT',
  GUEST_TYPE = 'GUEST_TYPE',
  INVOICING_INTERVAL = 'INVOICING_INTERVAL',
  INVOICING_JOB = 'INVOICING_JOB',
  JOURNEY = 'JOURNEY',
  JOURNEY_GROUP = 'JOURNEY_GROUP',
  JOURNEY_NAME_LIST_REFERENCE = 'JOURNEY_NAME_LIST_REFERENCE',
  LANGUAGE = 'LANGUAGE',
  METHOD_OF_PAYMENT = 'METHOD_OF_PAYMENT',
  MOBILE_DEVICE = 'MOBILE_DEVICE',
  MOBILE_KEY = 'MOBILE_KEY',
  MOBILE_KEY_LOCK_TYPE = 'MOBILE_KEY_LOCK_TYPE',
  PAYMENT = 'PAYMENT',
  PORT = 'PORT',
  RECRUITMENT_METHOD = 'RECRUITMENT_METHOD',
  SMS_TEMPLATE = 'SMS_TEMPLATE',
  STATE = 'STATE',
  TAX_OFFICE = 'TAX_OFFICE',
  TERMS_AND_CONDITIONS_TEXT_GROUP = 'TERMS_AND_CONDITIONS_TEXT_GROUP',
  TERMS_OF_PAYMENT = 'TERMS_OF_PAYMENT',
  TERMINAL = 'TERMINAL',
  TITLE = 'TITLE',
  TRAVEL_DOCUMENT = 'TRAVEL_DOCUMENT',
  VEHICLE_MAKE_MODEL = 'VEHICLE_MAKE_MODEL',
  VOUCHER = 'VOUCHER',
  UNKNOWN = 'UNKNOWN'
}

export enum Error500RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error403RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error406RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error409RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error429RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

/** code describing the reason why cancel is not allowed */
export enum CargoBookingControlFlagsCancelNotAllowedReasonCode {
  GROUP_BOOKING = 'GROUP_BOOKING',
  OTHER_REASON = 'OTHER_REASON'
}

export enum CargoBookingControlFlagsUpdateNotAllowedReasonCode {
  MISSING_CATEGORY_TRANSLATION = 'MISSING_CATEGORY_TRANSLATION',
  MANUAL_PRICE_USED = 'MANUAL_PRICE_USED',
  ROW_ALREADY_CHECKED_IN = 'ROW_ALREADY_CHECKED_IN',
  JOURNEY_IS_IN_THE_PAST = 'JOURNEY_IS_IN_THE_PAST',
  BOOKING_IS_READONLY = 'BOOKING_IS_READONLY',
  CUSTOMER_NOT_ALLOWED_FOR_API = 'CUSTOMER_NOT_ALLOWED_FOR_API',
  PRODUCT_DOES_NOT_ALLOW_UPDATES = 'PRODUCT_DOES_NOT_ALLOW_UPDATES',
  GROUP_BOOKING = 'GROUP_BOOKING',
  INVALID_CARGO_BOOKING = 'INVALID_CARGO_BOOKING',
  LOCKED_BY_OTHER_USER = 'LOCKED_BY_OTHER_USER',
  OTHER_REASON = 'OTHER_REASON'
}

export enum CargoJourneyControlFlagsUpdateNotAllowedReasonCode {
  MISSING_CATEGORY_TRANSLATION = 'MISSING_CATEGORY_TRANSLATION',
  MANUAL_PRICE_USED = 'MANUAL_PRICE_USED',
  ROW_ALREADY_CHECKED_IN = 'ROW_ALREADY_CHECKED_IN',
  JOURNEY_IS_IN_THE_PAST = 'JOURNEY_IS_IN_THE_PAST',
  BOOKING_IS_READONLY = 'BOOKING_IS_READONLY',
  CUSTOMER_NOT_ALLOWED_FOR_API = 'CUSTOMER_NOT_ALLOWED_FOR_API',
  PRODUCT_DOES_NOT_ALLOW_UPDATES = 'PRODUCT_DOES_NOT_ALLOW_UPDATES',
  GROUP_BOOKING = 'GROUP_BOOKING',
  INVALID_CARGO_BOOKING = 'INVALID_CARGO_BOOKING',
  LOCKED_BY_OTHER_USER = 'LOCKED_BY_OTHER_USER',
  OTHER_REASON = 'OTHER_REASON'
}

export enum DriverCargoAshoreBookingRowAvailabilityGroupCode {
  PASSENGER = 'PASSENGER',
  LODGING = 'LODGING',
  MISC = 'MISC',
  MEAL = 'MEAL',
  VEHICLE = 'VEHICLE',
  TIMESLOT = 'TIMESLOT',
  UNKNOWN = 'UNKNOWN'
}

export enum DriverCargoAshoreConnectedBookingRowAvailabilityGroupCode {
  PASSENGER = 'PASSENGER',
  LODGING = 'LODGING',
  MISC = 'MISC',
  MEAL = 'MEAL',
  VEHICLE = 'VEHICLE',
  TIMESLOT = 'TIMESLOT',
  UNKNOWN = 'UNKNOWN'
}

export enum DriverCargoJourneyBookingRowRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum DriverCargoJourneyBookingRowAvailabilityGroupType {
  PASSENGER = 'PASSENGER',
  LODGING = 'LODGING',
  MISC = 'MISC',
  MEAL = 'MEAL',
  VEHICLE = 'VEHICLE',
  TIMESLOT = 'TIMESLOT',
  UNKNOWN = 'UNKNOWN'
}

export enum DriverCargoJourneyConnectedBookingRowRecordType {
  NORMAL_BOOKING_ROW = 'NORMAL_BOOKING_ROW',
  EXTRA_BOOKING_ROW = 'EXTRA_BOOKING_ROW',
  AMENDMENT_FEE = 'AMENDMENT_FEE',
  CANCELLATION_FEE = 'CANCELLATION_FEE',
  DISCOUNT = 'DISCOUNT',
  SUPPLEMENT_AMOUNT = 'SUPPLEMENT_AMOUNT',
  SUPPLEMENT_PERCENT = 'SUPPLEMENT_PERCENT',
  CC_FEE = 'CC_FEE',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  LOCKED_PRICE = 'LOCKED_PRICE',
  UNKNOWN = 'UNKNOWN'
}

export enum DriverCargoJourneyConnectedBookingRowAvailabilityGroupType {
  PASSENGER = 'PASSENGER',
  LODGING = 'LODGING',
  MISC = 'MISC',
  MEAL = 'MEAL',
  VEHICLE = 'VEHICLE',
  TIMESLOT = 'TIMESLOT',
  UNKNOWN = 'UNKNOWN'
}

export type GenerateDriverTokenData = DriverTokenResponse

export type GetDriverBookingData = DriverCargoBooking

export type UpdateDriverBookingData = ResourceLinks

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType
} from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL:
        axiosConfig.baseURL ||
        'https://api-test-rec.carus.com/cargo-self-service-driver-update/rectest/v1'
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] =
        property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        )
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    })
  }
}

/**
 * @title Cargo Self-Service API - Driver self-service update
 * @version 11.0.0
 * @license Carus License (https://www.carus.com)
 * @termsOfService https://www.carus.com
 * @baseUrl https://api-test-rec.carus.com/cargo-self-service-driver-update/rectest/v1
 * @contact Carus <support@carus.com> (https://www.carus.com)
 *
 * Cargo Self-Service API - Driver self-service update
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  cargoSelfService = {
    /**
     * No description
     *
     * @tags CargoSelfServiceDriver
     * @name GenerateDriverToken
     * @summary Generates a token that can be used for logging in as a cargo driver.
     * @request POST:/cargo-self-service/v1/bookings/{bookingCode}/driver-tokens
     * @secure
     * @response `201` `GenerateDriverTokenData` Generated driver token
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `404` `Error404` Booking not found, or booking customer code does not match logged in customer sub-login.
     * @response `409` `DriverTokenResponse` Booking last event time is more than a few days into the past.
     * @response `500` `Error500` Unexpected error
     */
    generateDriverToken: (
      bookingCode: string,
      data: GenerateDriverToken,
      params: RequestParams = {}
    ) =>
      this.request<
        GenerateDriverTokenData,
        Error400 | Error401 | Error404 | DriverTokenResponse | Error500
      >({
        path: `/cargo-self-service/v1/bookings/${bookingCode}/driver-tokens`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceDriver
     * @name GetDriverBooking
     * @summary Get booking for provided driver token.
     * @request GET:/cargo-self-service/v1/driver/booking
     * @secure
     * @response `200` `GetDriverBookingData` Returns driver booking.
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` The booking associated with the driver token is no longer available. Different customer code, cancellation, or something similar, may be the reason.
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` Update resulted in conflict.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getDriverBooking: (params: RequestParams = {}) =>
      this.request<
        GetDriverBookingData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/driver/booking`,
        method: 'GET',
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags CargoSelfServiceDriver
     * @name UpdateDriverBooking
     * @summary Update cargo booking using a driver token.
     * @request PATCH:/cargo-self-service/v1/driver/booking
     * @secure
     * @response `200` `UpdateDriverBookingData` Self reference to updated booking.
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Object not found
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `409` `Error409` The booking is in a state not permitting update, or booking version or last update time did not match.
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    updateDriverBooking: (
      data: UpdateDriverBookingRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        UpdateDriverBookingData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error409 | Error429
      >({
        path: `/cargo-self-service/v1/driver/booking`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      })
  }
}
