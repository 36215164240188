import classNames from 'classnames'
import { format, isToday } from 'date-fns'
import { groupBy } from 'lodash'
import React from 'react'
import { FaCar, FaPlus, FaTruck } from 'react-icons/fa'
import { MdFace } from 'react-icons/md'

import { ONE_WEEK_IN_MS } from '../../../utils/constants'
import css from './PortDepartures.module.css'
import { getDate, getTime, sumMetersByStatus } from '../../../utils/helpers'
import { Departure } from '../types'
import api from '../../../services/api'
import { PortDepartureInformationResponse } from '../../../api/portDepartureInfoAPI'
import DarkModeSwitch from './DarkModeSwitch';

type Props = { title: string; portCodes: string[], isDark: boolean }

type State = {
  departuresByDate: Record<string, PortDepartureInformationResponse[]>
  departures: PortDepartureInformationResponse[]
  loading: boolean
}

export class PortDepartures extends React.Component<Props, State> {
  state = {
    departures: [],
    departuresByDate: {},
    loading: false
  }
  interval?: number

  componentDidMount() {
    this.interval = window.setInterval(this.fetchDepartures, 10000)
    this.fetchDepartures()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  fetchDepartures = async () => {
    this.setState({ loading: true })
    const { portCodes } = this.props
    const today = format(Date.now(), 'YYYY-MM-DD')
    const inOneWeek = format(Date.now() + ONE_WEEK_IN_MS, 'YYYY-MM-DD')

    const responses = portCodes.map((portCode) =>
      api.departureInfo
        .getCargoDepartures(portCode, {
          fromDate: today,
          toDate: inOneWeek,
          pageSize: 20
        })
        .then((res) => res.data)
    )

    const data = await Promise.all(responses)
    const departures = data
      .reduce<PortDepartureInformationResponse[]>((a, b) => {
        return [...a, ...(b?.departures || [])]
      }, []) // unify
      .filter((x) => new Date(x.departureDate).valueOf() > Date.now())
      .sort((x, y) => {
        return x.departureDate < y.departureDate ? -1 : 1
      })
      .slice(0, 20)

    const departuresByDate = groupBy(departures, (x) => getDate(x.departureDate))

    this.setState({ departuresByDate, departures, loading: false })
  }

  mapDepartures = (date: string) =>
    this.state.departuresByDate[date].map((departure: Departure, i: number) => {
      const { isDark } = this.props;
      const {
        departureDate,
        passengerCountBooked,
        bookedCargoSpace,
        bookedPassengerSpace,
        availableProtectedSpace,
        bookedSpacePerBookingRowStatus
      } = departure

      const { CB: overBookedMeters = 0, PS_RQ = 0 } = sumMetersByStatus(
        bookedSpacePerBookingRowStatus
      )

      const privateCars = Math.round(
        (bookedPassengerSpace.low + bookedPassengerSpace.medium) / 5
      )

      const cargoBookings =
        bookedCargoSpace.high +
        bookedPassengerSpace.high +
        availableProtectedSpace.high +
        overBookedMeters

      return (
        <tr
          key={i}
          className={classNames(css.departureRow, css.row, css.body, {
            [css.today]: isToday(this.state.departuresByDate[date][0].departureDate),
            [css.dark]: isDark
          })}
        >
          <td className={classNames(css.departureDate)}>
            {i === 0 && date.substr(0, 5)}
          </td>
          <td>{getTime(departureDate)}</td>
          <td className={classNames(css.alignRight, css.available)}>{PS_RQ}</td>
          <td className={classNames(css.alignRight, css.available)}>
            {passengerCountBooked}
          </td>
          <td className={classNames(css.alignRight, css.available)}>
            {privateCars}
          </td>
          <td className={classNames(css.alignRight, css.available)}>
            {Math.round(cargoBookings / 17)}
          </td>
          <td className={classNames(css.alignRight, css.available)}>
            {cargoBookings}
          </td>
          <td className={classNames(css.alignRight, css.available)}>
            {Math.round(availableProtectedSpace.high / 17)}
          </td>
          <td className={classNames(css.alignRight, css.protected, {
            [css.dark]: isDark
          })}>
            {availableProtectedSpace.high}
          </td>
        </tr>
      )
    })

  mapByDate = () =>
    Object.keys(this.state.departuresByDate).map((date) => {
      return this.mapDepartures(date)
    })

  render() {
    const { title, isDark } = this.props
    const departureDates = this.mapByDate()

    return (
      <div className={css.component}>
        <table className={classNames(css.port, {
          [css.dark]: isDark
        })}>
          <thead className={classNames(css.row, css.portTitle, {
            [css.dark]: isDark
          })}>
            <tr>
              <th className={classNames(css.departureTime)}>{title}</th>
              <th className={classNames(css.alignRight, css.available)} />
              <th className={classNames(css.alignRight, css.available)}>
                <FaPlus />
              </th>
              <th className={classNames(css.alignRight, css.available)}>
                <MdFace />
              </th>
              <th className={classNames(css.alignRight, css.available)}>
                <FaCar />
              </th>
              <th className={classNames(css.alignRight, css.available)}>
                <span className={css.quantityHeader}>QTY</span>
              </th>
              <th className={classNames(css.alignRight, css.available)}>
                <FaTruck />
              </th>
              <th className={classNames(css.alignRight, css.available)}>
                <span className={css.quantityHeader}>QTY</span>
              </th>
              <th className={classNames(css.alignRight, css.available)}>
                <DarkModeSwitch />
              </th>
            </tr>
          </thead>
          <tbody className={classNames(css.portDepartures)}>{departureDates}</tbody>
        </table>
      </div>
    )
  }
}

export default PortDepartures
