import { Box, Checkbox, FormControl, Grid, GridItem } from '@chakra-ui/react'
import { observer } from 'mobx-react'
import React, { FC, useEffect } from 'react'

import Button from '../../../../components/Button/Button'
import BaseInput from '../../../../components/Form/BaseInput'
import Label from '../../../../components/Label/Label'
import FormSelect from '../../../../components/FormControlled/FormSelect'
import Status from '../../../../components/Status/Status'
import reservationStore from '../../../../stores/ReservationStore'
import { cargoTypes, EDIT } from '../../../../utils/constants'
import FormInput from '../../../../components/FormControlled/FormInput'
import ReservationComponentProps from '../../ReservationComponentProps'
import css from '../../ReservationContainer.module.scss'
import userStore from '../../../../stores/UserStore'
import DangerousCargoAlert from '../../../../components/DangerousCargoAlert'
import { ErrorIcon } from '../../../../icons'
import BaseFormLabel from '../../../../components/Form/BaseFormLabel'
import VehicleLengthField from './VehicleLengthField'
import { Controller, useFormContext } from 'react-hook-form'
import { cargoInfoFields, ReservationFormValues } from '../../ReservationContainer'
import VehicleCategoryField from './VehicleCategoryField'
import { toNumber } from '../../../../utils/helpers'
import ErrorStore from '../../../../stores/ErrorStore'
import {
  setRegNumberAs,
  vehicleRegNumberRule
} from '../../../../utils/validationRules'
import { useTranslation } from 'react-i18next'
import SaveButton from '../SaveButton/SaveButton'

const CargoTypeSelect = observer(() => {
  const { control, setValue, watch } = useFormContext<ReservationFormValues>()
  const [cargoType, categoryExtCode] = watch([
    'cargoType',
    'vehicleTemplateCategoryExtCode'
  ])
  const { t } = useTranslation()

  useEffect(() => {
    if (cargoType === cargoTypes[4]) {
      setValue('powerConnection', true)
    }
  }, [cargoType, categoryExtCode])

  return (
    <FormSelect
      label={
        <BaseFormLabel
          tooltipText="Power connection is required for temperature controlled cargo"
          icon={ErrorIcon}
          label={t('cargo_type')}
          iconProps={{ fill: 'yellow.400' }}
        />
      }
      name="cargoType"
      control={control}
      rules={{ required: true }}
    >
      <option value="">{t('select_from_dropdown')}</option>
      {cargoTypes.map((option, index) => (
        <option key={index}>{option}</option>
      ))}
      {reservationStore.customCargoType && (
        <option value={reservationStore.customCargoType}>
          {reservationStore.customCargoType}
        </option>
      )}
    </FormSelect>
  )
})

const NormalReservation = observer(() => {
  const { control, watch } = useFormContext<ReservationFormValues>()

  const isPowerControlRequired = watch('cargoType') === cargoTypes[4]
  const { t } = useTranslation()

  return (
    <>
      <Grid
        gap={4}
        mb={4}
        templateColumns={[
          '1fr',
          '1fr 1fr',
          'calc(300px + var(--chakra-space-4)) 150px 150px',
          'calc(300px + var(--chakra-space-4)) 150px 150px'
        ]}
      >
        <VehicleCategoryField />
        <FormInput
          label={t('height_m')}
          name="height"
          isReadOnly
          control={control}
        />
        <FormInput label={t('width_m')} name="width" isReadOnly control={control} />
      </Grid>
      <Grid gap={4} mb={4} templateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr']}>
        <FormInput
          label={t('vehicle_register_number')}
          name="vehicleRegistrationNumber"
          control={control}
          setValueAs={setRegNumberAs}
          rules={vehicleRegNumberRule({
            required: true,
            shouldFilterNotAllowedRegNumbers: true
          })}
        />
        <VehicleLengthField />
        <FormInput
          label={t('load_weight_kg')}
          name="loadWeight"
          control={control}
          rules={{ required: t('error.field_required') }}
          setValueAs={(value) => {
            if (!value) {
              return undefined
            }
            return toNumber(value, 0)
          }}
        />
      </Grid>
      <Grid gap={4} mb={4} templateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr']}>
        <FormInput
          label={t('trailer_register_number')}
          name="trailerRegistrationNumber"
          control={control}
          setValueAs={setRegNumberAs}
          rules={vehicleRegNumberRule()}
        />
        {!userStore.isOccasional && (
          <BaseInput
            label={t('allotment')}
            name="allotment"
            value={reservationStore.allotmentAvailable}
            isReadOnly
            textAlign="right"
            style={{ background: '#fafafa' }}
            iconRight={
              <Box
                zIndex={1}
                position="absolute"
                top="50%"
                left="4"
                transform="translateY(-50%)"
              >
                <Status
                  departure={reservationStore.selectedDeparture}
                  type="number"
                />
              </Box>
            }
            wrapperProps={{ position: 'relative', spacing: 0 }}
          />
        )}
      </Grid>
      <Grid
        gap={4}
        templateColumns={['1fr', '1fr', 'calc(300px + var(--chakra-space-4)) 1fr']}
      >
        <CargoTypeSelect />
        <FormControl>
          <Box display={['none', 'none', 'block', 'block']}>&nbsp;</Box>
          {reservationStore.powerConnectionAvailable ? (
            <Controller
              control={control}
              shouldUnregister
              name="powerConnection"
              render={({ field }) => {
                const { name, onBlur, ref, onChange, value } = field
                return (
                  <Checkbox
                    mt={4}
                    isDisabled={isPowerControlRequired}
                    isChecked={value}
                    onChange={() => onChange(!value)}
                    onBlur={onBlur}
                    ref={ref}
                    name={name}
                  >
                    {t('power_connection_required')}
                  </Checkbox>
                )
              }}
            />
          ) : (
            <Box mt={2}>
              <Label>Power connection not available</Label>
            </Box>
          )}
        </FormControl>
      </Grid>
      <DangerousCargoAlert className={css.cargoAlert} />
    </>
  )
})

type CargoInfoProps = ReservationComponentProps & { isInvalid: boolean }

const CargoInformation: FC<CargoInfoProps> = observer((props) => {
  const {
    trigger,
    control,
    formState: { isValid }
  } = useFormContext<ReservationFormValues>()
  const { id, action, onMakeReservation, toggleActive, isInvalid } = props
  const { t } = useTranslation()

  return (
    <div className={css.componentBodyWrapper}>
      <NormalReservation />
      <div className={css.footerRow}>
        <Grid
          templateColumns={['1fr', 'auto', '1fr 1fr 1fr auto', '1fr 1fr 1fr auto']}
          gap={4}
          className={css.commentWrapper}
        >
          <FormInput
            placeholder={t('consignee')}
            control={control}
            name="consignee"
            size="md"
          />
          <FormInput
            placeholder={t('reference_number_for_invoicing')}
            control={control}
            name="externalNote"
            size="md"
          />
          <FormInput
            placeholder={t('comment_on_the_reservation')}
            control={control}
            name="internalNote"
            size="md"
          />
          <div className={css.next}>
            {!userStore.isOccasional && action === EDIT && (
              <SaveButton onClick={onMakeReservation} disabled={!isValid} />
            )}
            <Button
              disabled={isInvalid}
              onClick={async () => {
                if (await trigger(cargoInfoFields)) {
                  return toggleActive(id + 1)
                }
              }}
            >
              {t('next')}
            </Button>
          </div>
        </Grid>
      </div>
    </div>
  )
})

export default CargoInformation
