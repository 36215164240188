import { ExtraService } from '../../../../services/types'
import { useTranslation } from 'react-i18next'
import Row from '../../../../components/Row/Row'
import { isTranslated } from '../../../../utils/helpers'
import Checkbox from '../../../../components/Checkbox/Checkbox'
import React, { FunctionComponent } from 'react'
import reservationStore from '../../../../stores/ReservationStore'
import Amount from '../../../../components/Amount/Amount'

type ExtraServicesProps = {
  items: ExtraService[]
}

const ExtraServices: FunctionComponent<ExtraServicesProps> = ({ items }) => {
  const { t } = useTranslation()

  return (
    <>
      {items.map((item, i) => (
        <Row border key={i}>
          {isTranslated(item.categoryExtCode) ? (
            <>{t(item.categoryExtCode)}</>
          ) : (
            item.categoryNames?.displayName
          )}
          &nbsp;
          {item.maxCategoryQuantity === 1 ? (
            <label>
              <Checkbox
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  reservationStore.setDepartureCategory({
                    ...item,
                    quantity: event.target.checked ? 1 : 0
                  })
                }
                checked={!!item.quantity}
              />
            </label>
          ) : (
            <Amount
              value={Number(item.quantity)}
              handleChange={(newValue) => {
                reservationStore.setDepartureCategory({
                  ...item,
                  quantity: newValue < 0 ? 0 : newValue
                })
              }}
            />
          )}
        </Row>
      ))}
    </>
  )
}

export default ExtraServices
