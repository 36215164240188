import {
  RadioGroup,
  Stack,
  Radio,
  FormControl,
  FormLabel,
  RadioGroupProps
} from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import BaseFormHelperText from '../BaseFormHelperText'
import { SelectItem } from '../BaseSelect'

type Props = {
  label?: string
  options: SelectItem[]
  helperText?: string
} & Omit<RadioGroupProps, 'children'>

const BaseRadio = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { label, options, helperText, ...radioGroupProps } = props
  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}
      <RadioGroup {...radioGroupProps} ref={ref}>
        <Stack direction="row">
          {options.map(({ value, label }) => {
            return (
              <Radio key={value} value={value}>
                {label}
              </Radio>
            )
          })}
        </Stack>
      </RadioGroup>
      {helperText && <BaseFormHelperText>{helperText}</BaseFormHelperText>}
    </FormControl>
  )
})

export default BaseRadio
