import classNames from 'classnames'
import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { PortDepartures } from './components/PortDepartures'
import css from './InfoScreenContainer.module.css'

const InfoScreenContainer = () => {
  const [searchParams, set] = useSearchParams();
  const isDark = (searchParams.get('dark') === "true") ?? false;

  return (
    <div className={css.view}>
      <div className={classNames(css.container, {
        [css['container-dark']]: isDark,
      })}>
        <PortDepartures title="Helsinki" portCodes={['H', 'HV']} isDark={isDark} />
        <PortDepartures title="Tallinn" portCodes={['T', 'TM']} isDark={isDark} />
      </div>
    </div>
  )
}

export default InfoScreenContainer
