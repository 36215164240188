import { LanguageTypes } from '../../../locales'

type CurrentPortExtNames = 'HV' | 'TM' | 'T' | 'H'

/*
"Helsinki Vuosaari" -> HV
"Tallinn Muuga"     -> TM
"Helsinki"          -> H
"Tallinn"           -> T
*/

type PortNameTranslationsType = Record<
  LanguageTypes,
  {
    from: Record<CurrentPortExtNames, string>
    to: Record<CurrentPortExtNames, string>
  }
>

const portNameTranslations: PortNameTranslationsType = {
  ee: {
    from: {
      HV: 'Helsingi Vuosaarest kell ',
      TM: 'Tallinnast Muugalt kell ',
      T: 'Tallinnast kell ',
      H: 'Helsingist kell '
    },
    to: {
      HV: 'Helsingi Vuosaarele kell ',
      TM: 'Tallinnale Muugale kell ',
      T: 'Tallinnasse kell ',
      H: 'Helsingisse kell '
    }
  },
  en: {
    from: {
      HV: 'From Helsinki Vuosaari at ',
      TM: 'From Tallinn Muuga at ',
      T: 'From Tallinn at ',
      H: 'From Helsinki at '
    },
    to: {
      HV: 'To Helsinki Vuosaari at ',
      TM: 'To Tallinn Muuga at ',
      T: 'To Tallinn at ',
      H: 'To Helsinki at '
    }
  },
  fi: {
    from: {
      HV: 'Helsingin Vuosaaresta klo ',
      TM: 'Tallinnan Muugasta klo ',
      T: 'Tallinnasta klo ',
      H: 'Helsingistä klo '
    },
    to: {
      HV: 'Helsinki Vuosaareen klo ',
      TM: 'Tallinnan Muugalle klo ',
      T: 'Tallinnaan klo ',
      H: 'Helsinkiin klo '
    }
  },
  pl: {
    from: {
      HV: 'Z Helsinek Vuosaari o ',
      TM: 'Z Tallina Muuga o ',
      T: 'Z Tallina o ',
      H: 'Z Helsinek o '
    },
    to: {
      HV: 'Do Helsinek Vuosaari o ',
      TM: 'Do Tallina Muuga o ',
      T: 'Do Tallina o ',
      H: 'Do Helsinek o '
    }
  },
  ru: {
    from: {
      HV: 'Из Хельсинки Вуосаари(Helsinki Vuosaari) в ',
      TM: 'Из Таллинна Мууга(Tallinn Muuga) в ',
      T: 'Из Таллинна(Tallinn) в ',
      H: 'Из Хельсинки(Helsinki) в '
    },
    to: {
      HV: 'В Хельсинки Вуосаари(Helsinki Vuosaari) в ',
      TM: 'В Таллинн Мууга(Tallinn Muuga) в ',
      T: 'В Таллинна(Tallinn) в ',
      H: 'В Хельсинки(Helsinki) в '
    }
  }
}

export { portNameTranslations }
