import React from 'react'
import View from '../../components/View/View'
import { Center, Flex, Progress } from '@chakra-ui/react'
import ErrorStore from '../../stores/ErrorStore'
import { Route, Routes } from 'react-router-dom'
import Cancel from './components/Cancel'
import Success from './components/Success'
import Payment from './components/Payment'

const PaymentContainer: React.FC = () => {
  return (
    <View title="Payment">
      <Progress isIndeterminate={ErrorStore.isLoading} size="xs" />
      <Center>
        <Flex
          h="66vh"
          maxW="600px"
          alignItems="center"
          justifyContent="center"
          flexFlow="column"
        >
          <Routes>
            <Route path="/payment/cancel" element={<Cancel />} />
            <Route path="/payment/success" element={<Success />} />
            <Route element={<Payment />} />
          </Routes>
        </Flex>
      </Center>
    </View>
  )
}

export default PaymentContainer
