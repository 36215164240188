import React, { FunctionComponent } from 'react'
import ErrorStore from '../../../../stores/ErrorStore'
import { Button } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

type SaveButtonProps = {
  onClick: () => void
  disabled: boolean
}

const SaveButton: FunctionComponent<SaveButtonProps> = observer(
  ({ onClick, disabled }) => {
    const { t } = useTranslation()
    const label = ErrorStore.isSaving ? t('saving_changes') : t('save_changes')

    return (
      <Button
        onClick={onClick}
        disabled={disabled || ErrorStore.isLoading || ErrorStore.isSaving}
      >
        {label}
      </Button>
    )
  }
)

export default SaveButton
