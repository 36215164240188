import { FieldErrors } from 'react-hook-form/dist/types/errors'
import {
  cargoInfoFields,
  ReservationFormValues
} from '../views/reservation/ReservationContainer'

export const getCargoInfoErrors = (errors: FieldErrors<ReservationFormValues>) => {
  const filteredErrors = cargoInfoFields.reduce<FieldErrors<ReservationFormValues>>(
    (cargoInfoErrors, currentField) => {
      if (errors[currentField]) {
        return { ...cargoInfoErrors, [currentField]: errors[currentField] }
      }
      return cargoInfoErrors
    },
    {}
  )

  return Object.values(
    Object.values(filteredErrors).reduce<Record<string, string>>((errors, error) => {
      if (error.type === 'required') {
        return { ...errors, required: 'Please fill in all required fields' }
      }
      return {
        ...errors,
        [error.type as unknown as string]: error.message as string
      }
    }, {})
  )
}
