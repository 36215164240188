import { observer } from 'mobx-react'
import React from 'react'

import modalStore from '../../stores/ModalStore'
import Button from '../Button/Button'
import css from './Modal.module.css'

@observer
class Modal extends React.Component {
  private backdropRef = React.createRef<HTMLDivElement>()

  handleClick = (event: any) => {
    if (this.backdropRef.current!.contains(event.target)) {
      return
    }
    modalStore.onCancel()
  }

  render() {
    const { isVisible, header, description, onConfirm, onCancel } = modalStore

    if (!isVisible) {
      document.removeEventListener('mousedown', this.handleClick, false)
      return null
    }

    document.addEventListener('mousedown', this.handleClick, false)

    return (
      <div className={css.backdrop}>
        <div className={css.container} ref={this.backdropRef}>
          <div className={css.component}>
            <div className={css.header}>{header}</div>
            {description && <div className={css.description}>{description}</div>}
            <div className={css.buttonWrapper}>
              <Button onClick={onConfirm}>OK</Button>
              <Button onClick={onCancel}>Cancel</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Modal
