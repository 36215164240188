import React from 'react'
import { Text } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { ReservationOccasionalCustomerFormValues } from '../index'
import FormCheckbox from '../../../../components/FormControlled/FormCheckbox'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

const TermsCheckbox = observer(() => {
  const { control } = useFormContext<ReservationOccasionalCustomerFormValues>()
  const { t } = useTranslation()

  return (
    <FormCheckbox
      rules={{ required: t('accepting_terms_error') }}
      control={control}
      modalDescription={
        <>
          {/*TODO: Modify the content of modal window, if needed*/}
          <Text>
            Age limit is 18 years. Passengers under the age of 18 may only travel
            accompanied by their parent/guardian or a person over the age of 22
            authorized in writing by the parent/guardian. As exception young persons
            aged 16-17 can be allowed travelling alone under certain conditions. See
            more information in the terms.
          </Text>
          <Text>
            All passengers must carry a valid travel document: a passport or an
            official ID card. Drivers licence is not a valid travel document. Travel
            documents can be inspected before the embarkation.
          </Text>
          <Text>
            Arrive early to the terminal: embarkation ends 30 minutes before the
            departure.
          </Text>
          <Text>Terms of Travel</Text>
          <Text>Pre-order terms</Text>
          <Text>
            Read the instructions for passengers by the Finnish institute for health
            and welfare
          </Text>
        </>
      }
      modalTitle="Terms and conditions"
      name="isTermsAndConditionsAccepted"
      description={t('read_more_about_it')}
    >
      {t('terms_and_condition_description')}
    </FormCheckbox>
  )
})

export default TermsCheckbox
