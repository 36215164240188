import React, { forwardRef } from 'react'
import { Checkbox, CheckboxProps } from '@chakra-ui/react'

type Props = CheckboxProps

// Our own version of Checkboxes can be implemented here, in case we decide to migrate from chakra-ui, or something else
const BaseCheckbox = forwardRef<HTMLInputElement, Props>((props, ref) => {
  return <Checkbox {...props} ref={ref} />
})

export default BaseCheckbox
