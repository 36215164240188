import React from 'react'
import { observer } from 'mobx-react'
import { Box, Flex, Grid, Stack, Text } from '@chakra-ui/react'
import FormInput from '../../../../components/FormControlled/FormInput'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Button/Button'
import FormSelect from '../../../../components/FormControlled/FormSelect'
import ReservationStore from '../../../../stores/ReservationStore'

import { ApisysPayeeInformation } from '../../../../api/externalPaymentsAPI'
import ReservationComponentProps from '../../../reservation/ReservationComponentProps'
import { ReservationOccasionalCustomerFormValues } from '../index'
import { validateEmail } from '../../../../utils/helpers'
import PaymentMethods from './PaymentMethods'
import TermsCheckbox from '../TermsCheckbox'
import FinalPrice from '../FinalPrice'

export type PaymentFormValues = {
  payee: ApisysPayeeInformation
  preSelectedPaymentMethod: string
}

type Props = ReservationComponentProps

const Payment: React.FC<Props> = observer((props: Props) => {
  const { toggleActive, id } = props
  const { control } = useFormContext<ReservationOccasionalCustomerFormValues>()
  const { nationalities } = ReservationStore
  const { t } = useTranslation()

  return (
    <Stack spacing={5} p={[2, 5]}>
      <Box>
        <Text mb={[2, 3]} fontWeight="normal" fontSize="xl">
          {t('payment_info')}
        </Text>
        <Grid templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)']} gap={3}>
          <FormInput
            label={t('firstname')}
            name="payee.firstName"
            control={control}
            rules={{ required: true }}
          />
          <FormInput
            label={t('lastname')}
            name="payee.lastName"
            control={control}
            rules={{ required: true }}
          />
          <FormInput
            label={t('email')}
            name="payee.emailAddress"
            control={control}
            rules={{
              validate: validateEmail,
              required: true
            }}
          />
          <FormInput
            label={t('address')}
            name="payee.streetAddress"
            control={control}
            rules={{ required: true }}
          />
          <FormInput
            label={t('postalCode')}
            name="payee.postalCode"
            control={control}
            rules={{ required: true }}
          />
          <FormInput
            label={t('postalDistrict')}
            name="payee.postalDistrict"
            control={control}
            rules={{ required: true }}
          />
          <FormSelect
            label={t('nationality')}
            name="payee.countryExtCode"
            control={control}
            rules={{ required: true }}
          >
            <option value="">{t('select_from_dropdown')}</option>
            {nationalities.map((nationality) => (
              <option
                key={nationality.countryExtCode}
                value={nationality.countryExtCode}
              >
                {nationality.countryName}
              </option>
            ))}
          </FormSelect>
        </Grid>
      </Box>

      <PaymentMethods control={control} />

      <TermsCheckbox />

      <FinalPrice />

      <Flex justifyContent="flex-end">
        <Button onClick={() => toggleActive(id + 1)}>{t('next')}</Button>
      </Flex>
    </Stack>
  )
})

export default Payment
