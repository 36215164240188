import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import css from '../../ReservationContainer.module.scss'
import DriversList from './DriversList'
import { HStack } from '@chakra-ui/react'
import classNames from 'classnames'
import { MdAddCircle, MdRemoveCircle } from 'react-icons/md'
import React from 'react'
import { DriverDetailsProps } from './DriverAndServices'

const DriverDetails = observer((props: DriverDetailsProps) => {
  const { id, addDriver, removeDriver, last, updateDriver, driver } = props
  const { t } = useTranslation()

  return (
    <div>
      <div className={css.subtitle}>
        <div>
          {t('driver')} &nbsp;
          {id > 0 && id + 1}
        </div>
      </div>
      <DriversList id={id} driver={driver} updateDriver={updateDriver} />
      <div className={css.addRemoveColumn}>
        <HStack>
          {typeof removeDriver === 'function' && (
            <div
              className={classNames(css.addOrRemove)}
              onClick={() => removeDriver(id)}
            >
              <MdRemoveCircle className={css.removeIcon} />
              <span>&nbsp; {t('remove_driver')}</span>
            </div>
          )}
          {id === last && (
            <div className={classNames(css.addOrRemove)} onClick={addDriver}>
              <MdAddCircle className={css.addIcon} />
              <span>&nbsp; {t('add_driver')}</span>
            </div>
          )}
        </HStack>
      </div>
      <div className={css.line} />
    </div>
  )
})

export default DriverDetails
