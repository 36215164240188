import React, { FC } from 'react'
import {
  Avatar,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  UseDisclosureReturn
} from '@chakra-ui/react'
import userStore from '../../stores/UserStore'
import { NavLink } from 'react-router-dom'
import {
  AssignmentIcon,
  EventIcon,
  FormatListBulletedIcon,
  LogoutIcon
} from '../../icons'
import authStore from '../../stores/AuthStore'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

type Props = Pick<UseDisclosureReturn, 'isOpen' | 'onClose'>

const AsideDrawer: FC<Props> = observer((props) => {
  const { isOpen, onClose } = props
  const { t } = useTranslation()

  const handleLogout = () => {
    authStore.logout()
  }

  return (
    <Drawer
      blockScrollOnMount={false}
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton color="#fff" />
        <Box
          as={Flex}
          bg="baseBlue"
          minH={172}
          mb={3}
          flexDir="column"
          justifyContent="flex-end"
          p={4}
        >
          <Avatar size="lg" name={userStore.customer.name} mb={2} />
          <Text fontSize="lg" color="white" fontWeight="bold">
            {userStore.customer.name}
          </Text>
        </Box>
        <DrawerBody>
          <Stack spacing={4}>
            <List onClick={onClose} spacing={3}>
              <ListItem display="flex" alignItems="center" as={NavLink} to="/">
                <ListIcon
                  as={EventIcon}
                  width="40px"
                  height="32px"
                  fill="baseBlue"
                />
                {t('new_reservation')}{' '}
              </ListItem>
              <ListItem
                display="flex"
                alignItems="center"
                as={NavLink}
                to="/preliminary"
              >
                <ListIcon
                  as={AssignmentIcon}
                  width="40px"
                  height="32px"
                  fill="baseBlue"
                />
                {t('preliminary_booking')}
              </ListItem>
              <ListItem
                display="flex"
                alignItems="center"
                as={NavLink}
                to="/reservations"
              >
                <ListIcon
                  as={FormatListBulletedIcon}
                  width="40px"
                  height="32px"
                  fill="baseBlue"
                />
                {t('reservations')}
              </ListItem>
              <ListItem
                display="flex"
                alignItems="center"
                as={NavLink}
                onClick={handleLogout}
                to="/"
              >
                <ListIcon
                  as={LogoutIcon}
                  width="40px"
                  height="32px"
                  fill="baseBlue"
                />
                {t('logout')}
              </ListItem>
            </List>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
})

export default AsideDrawer
