import { observer } from 'mobx-react'
import React from 'react'
import { MdRepeat } from 'react-icons/md'

import Button from '../../../../components/Button/Button'
import Label from '../../../../components/Label/Label'
import BaseSelect from '../../../../components/Form/BaseSelect'
import { isTranslated } from '../../../../utils/helpers'
import reservationStore from '../../../../stores/ReservationStore'
import { EDIT } from '../../../../utils/constants'
import ReservationComponentProps from '../../ReservationComponentProps'
import DepartureSelect from '../DepartureSelect/DepartureSelect'
import css from './DepartureComponent.module.css'
import userStore from '../../../../stores/UserStore'
import { useFormContext } from 'react-hook-form'
import { ReservationFormValues } from '../../ReservationContainer'
import ErrorStore from '../../../../stores/ErrorStore'
import { useTranslation } from 'react-i18next'
import SaveButton from '../SaveButton/SaveButton'

const DepartureComponent = observer((props: ReservationComponentProps) => {
  const {
    formState: { isValid }
  } = useFormContext<ReservationFormValues>()
  const { id, action, onMakeReservation, toggleActive } = props
  const {
    departureCountryCode,
    arrivalCountryCode,
    setDepartureCountry,
    setArrivalCountry,
    departureCode,
    departureCountries
  } = reservationStore
  const { t } = useTranslation()

  const isDepartureValid = !!departureCode

  // ToDo: Use portExtCode as key instead of index
  const portOptions = departureCountries.map((countryCode) => (
    <option key={countryCode} value={countryCode}>
      {isTranslated(countryCode) ? <>{t(countryCode)}</> : countryCode}
    </option>
  ))

  return (
    <div className={css.componentBodyWrapper}>
      <div className={css.row}>
        <Label vertical wide>
          {t('departure_country')}
          <BaseSelect
            value={departureCountryCode || ''}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setDepartureCountry(e.target.value)
            }
          >
            {portOptions}
          </BaseSelect>
        </Label>
        <Label vertical>
          <div className={css.dash}>&mdash;</div>
        </Label>
        <Label vertical wide>
          {t('arrival_country')}
          <BaseSelect
            value={arrivalCountryCode || ''}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setArrivalCountry(e.target.value)
            }
          >
            {portOptions}
          </BaseSelect>
        </Label>
        <Label className={css.inputWrapper}>
          <MdRepeat
            className={css.icon}
            onClick={() => reservationStore.reverseCountries()}
          />
        </Label>
      </div>
      <div className={css.line} />
      <DepartureSelect />
      <div className={css.line} />
      <div className={css.next}>
        {!userStore.isOccasional && action === EDIT && (
          <SaveButton onClick={onMakeReservation} disabled={!isValid} />
        )}
        <Button disabled={!isDepartureValid} onClick={() => toggleActive(id + 1)}>
          {t('next')}
        </Button>
      </div>
    </div>
  )
})

export default DepartureComponent
