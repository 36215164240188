import { useEffect } from 'react'
import { useParams } from 'react-router'

const RedirectToApp = () => {
  const { appLink } = useParams()
  const protocol =
    process.env.REACT_APP_API_CARRES_ENV !== 'rectest'
      ? 'eckeroapp'
      : 'eckeroapp-staging'

  const href = `${protocol}://app?${appLink}`

  useEffect(() => {
    window.location.href = href
  }, [appLink])

  return null
}

export default RedirectToApp
