import React, { FC } from 'react'
import { Container, Tab, Tabs, TabList, TabPanels, TabPanel } from '@chakra-ui/react'
// import SignUp from '../SignUp'
import SignIn from '../SignIn'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

const Login: FC = observer(() => {
  const { t } = useTranslation()

  return (
    <Container mt="5vh" maxW={['sm', 'md']} py={5}>
      <Tabs isFitted>
        <TabList>
          {/* TODO: uncomment the sign up section in feature releases */}
          {/*<Tab>{t('sign_up')}</Tab>*/}
          <Tab>{t('login')}</Tab>
        </TabList>
        <TabPanels>
          {/*<TabPanel>*/}
          {/*  <SignUp />*/}
          {/*</TabPanel>*/}
          <TabPanel>
            <SignIn />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  )
})

export default Login
