import React, { FC, useEffect } from 'react'
import { HStack, Stack } from '@chakra-ui/react'
import FormInput from '../../../../components/FormControlled/FormInput'
import { useFormContext } from 'react-hook-form'
import { CargoNameListRow } from '../../../../api/cargoSelfServiceAPI'
import { emailPattern } from '../../../../utils/constants'

type Props = {
  drivers: CargoNameListRow[]
}

export type DriversFormValues = {
  email: string
  phone: string
}

const SendLinkToDriver: FC<Props> = ({ drivers }) => {
  const { control, getFieldState, setValue } = useFormContext<DriversFormValues>()

  const { email, mobile } = drivers.length
    ? {
        email: drivers[0].email,
        mobile: drivers[0].mobile
      }
    : {
        email: '',
        mobile: ''
      }

  const emailTouched = getFieldState('email').isTouched
  const phoneTouched = getFieldState('phone').isTouched

  useEffect(() => {
    if (!emailTouched && !phoneTouched) {
      setValue('phone', mobile || '')
      setValue('email', email || '')
    }
  }, [email, emailTouched, mobile, phoneTouched, setValue])

  return (
    <HStack alignSelf="flex-start" w="100%" margin="auto">
      <HStack spacing={2} alignItems="flex-start">
        <Stack>
          <FormInput
            label="Phone"
            name="phone"
            control={control}
            size="sm"
            placeholder="+358 123456789"
          />
        </Stack>
        <Stack>
          <FormInput
            label="Email"
            name="email"
            rules={{
              validate: (email) => {
                if (email?.trim()) {
                  return emailPattern.test(email)
                }
                return true
              }
            }}
            control={control}
            size="sm"
            placeholder="email@address.com"
          />
        </Stack>
      </HStack>
    </HStack>
  )
}

export default SendLinkToDriver
