/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AsyncBank {
  /**
   * @minLength 0
   * @maxLength 50
   */
  referenceNumber: string
  /**
   * @minLength 0
   * @maxLength 50
   */
  accountNumber?: string
  /** @format date-time */
  dueTime: string
}

export interface CurrencyExtAmount {
  /** @example 123.45 */
  amount: number
  /**
   * Currency code used externally
   * @minLength 0
   * @maxLength 50
   */
  currencyExtCode: string
}

export interface GetRedirect {
  url: string
}

export interface HtmlSnippet {
  snippet: string
}

export interface InitiateExtEcommercePaymentResponse {
  responseType: InitiateExtEcommercePaymentResponseResponseType
  getRedirect?: GetRedirect
  postRedirect?: PostRedirect
  htmlSnippet?: HtmlSnippet
  asyncBank?: AsyncBank
  /**
   * Payment's transaction number
   * @format int32
   * @min 0
   * @max 999999999
   */
  transactionNumber: number
  amount: CurrencyExtAmount
}

export interface Parameter {
  /**
   * @minLength 0
   * @maxLength 200
   */
  name: string
  /**
   * @minLength 0
   * @maxLength 1000
   */
  value?: string
}

export interface PostRedirect {
  url: string
  /**
   * @maxItems 2147483647
   * @minItems 1
   */
  parameters: Parameter[]
}

export interface Error400 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error400RequiredAction
}

export interface Error403 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error403RequiredAction
  fields?: string[]
  resourceReference?: ResourceReference
}

export interface ResourceIdentifier {
  identifierType: ResourceIdentifierIdentifierType
  /**
   * @minLength 1
   * @maxLength 1000
   */
  value: string
}

export interface ResourceReference {
  resourceType: ResourceReferenceResourceType
  /**
   * @maxItems 2147483647
   * @minItems 1
   */
  identifiers: ResourceIdentifier[]
}

export interface Error404 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error404RequiredAction
  fields?: string[]
  resourceReference?: ResourceReference
}

export interface Error409 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error409RequiredAction
  fields?: string[]
  resourceReference?: ResourceReference
}

export interface Error422 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error422RequiredAction
  fields?: string[]
  resourceReference?: ResourceReference
  errorSubType?: Error422ErrorSubType
}

export interface Error500 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error500RequiredAction
}

export interface ApisysPayeeInformation {
  /**
   * first name
   * @minLength 0
   * @maxLength 50
   */
  firstName?: string
  /**
   * last name
   * @minLength 0
   * @maxLength 50
   */
  lastName: string
  /**
   * The postal district
   * @minLength 0
   * @maxLength 50
   */
  postalDistrict?: string
  /**
   * The street address
   * @minLength 0
   * @maxLength 200
   */
  streetAddress?: string
  /**
   * The postal code
   * @minLength 0
   * @maxLength 10
   */
  postalCode?: string
  /**
   * @minLength 1
   * @maxLength 50
   */
  emailAddress?: string
  /**
   * Country code used externally
   * @minLength 0
   * @maxLength 3
   */
  countryExtCode: string
}

export interface InitiateExtEcommercePaymentRequest {
  /**
   * Language code in format/standart expected by Payment Service Provider
   * @minLength 1
   * @maxLength 20
   */
  paymentLanguageCode?: string
  /**
   * CarRes' internal ID for the method of payment
   * @minLength 0
   * @maxLength 10
   */
  methodOfPaymentCode: string
  acceptCreditCardFee: boolean
  /** Preselected payment method. This is provider specific value. The Payment Service Provider should supply the possible values. Not supported by all Payment Service Providers.  */
  preSelectedPaymentMethod?: string
  /** External URLs which the end user should return to when comming back from Payment Service Provider. Not all Payment Service Providers support all four variants.  */
  returnUrls: ReturnUrls
  amount: CurrencyExtAmount
  payee?: ApisysPayeeInformation
}

/** External URLs which the end user should return to when comming back from Payment Service Provider. Not all Payment Service Providers support all four variants.  */
export interface ReturnUrls {
  /**
   * @minLength 1
   * @maxLength 200
   */
  success: string
  /**
   * @minLength 1
   * @maxLength 200
   */
  decline: string
  /**
   * @minLength 1
   * @maxLength 200
   */
  cancel: string
  /**
   * @minLength 1
   * @maxLength 200
   */
  error: string
}

export interface BaseCreditCardDetails {
  /**
   * credit card's masked number
   * @minLength 0
   * @maxLength 50
   */
  maskedCardNumber: string
}

export interface CompleteExtEcommercePaymentResponse {
  paymentSucceeded: boolean
  /**
   * booking code in carres
   * @minLength 0
   * @maxLength 10
   */
  bookingCode: string
  /**
   * CarRes' internal ID for the method of payment
   * @minLength 0
   * @maxLength 10
   */
  methodOfPaymentCode: string
  creditCardDetails?: BaseCreditCardDetails
  bookingBalance: CurrencyExtAmount
  amountInPaymentCurrency: CurrencyExtAmount
  amountInBookingCurrency: CurrencyExtAmount
}

export interface CompleteExtEcommercePaymentRequest {
  parameters?: Parameter[]
}

export enum InitiateExtEcommercePaymentResponseResponseType {
  GET_REDIRECT = 'GET_REDIRECT',
  POST_REDIRECT = 'POST_REDIRECT',
  HTML_SNIPPET = 'HTML_SNIPPET',
  ASYNC_BANK = 'ASYNC_BANK'
}

export enum Error400RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error403RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum ResourceIdentifierIdentifierType {
  ACCOUNTING_JOB_NUMBER = 'ACCOUNTING_JOB_NUMBER',
  ACCOUNTING_SYSTEM_CODE = 'ACCOUNTING_SYSTEM_CODE',
  ACTOR_CODE = 'ACTOR_CODE',
  AGENT_CODE = 'AGENT_CODE',
  APISYS_CODE = 'APISYS_CODE',
  APP_CODE = 'APP_CODE',
  APP_INSTANCE_CODE = 'APP_INSTANCE_CODE',
  APP_INSTANCE_LOCALE_CODE = 'APP_INSTANCE_LOCALE_CODE',
  AUTOMAT_CODE = 'AUTOMAT_CODE',
  BOOKING_CODE = 'BOOKING_CODE',
  BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP_CODE = 'BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP_CODE',
  BOOKING_ROW_ITEM_REFERENCE = 'BOOKING_ROW_ITEM_REFERENCE',
  BOOKING_VERSION_ID = 'BOOKING_VERSION_ID',
  BOOKING_VERSION_NUMBER = 'BOOKING_VERSION_NUMBER',
  BROCHURE_CODE = 'BROCHURE_CODE',
  CARGO_TYPE_CODE = 'CARGO_TYPE_CODE',
  CATEGORY_CODE = 'CATEGORY_CODE',
  CATEGORY_GROUP_CODE = 'CATEGORY_GROUP_CODE',
  CATEGORY_STRUCT_GROUP_CODE = 'CATEGORY_STRUCT_GROUP_CODE',
  CLUB_CODE = 'CLUB_CODE',
  CLUB_LEVEL = 'CLUB_LEVEL',
  CONSIGNEE_CODE = 'CONSIGNEE_CODE',
  COUNTRY_CODE = 'COUNTRY_CODE',
  COUNTRY_EXT_CODE = 'COUNTRY_EXT_CODE',
  CURRENCY_CODE = 'CURRENCY_CODE',
  CUSTOMER_CODE = 'CUSTOMER_CODE',
  CUSTOMER_PRIVILEGE_GROUP_CODE = 'CUSTOMER_PRIVILEGE_GROUP_CODE',
  CUSTOMER_SUB_LOGIN_CODE = 'CUSTOMER_SUB_LOGIN_CODE',
  DEFAULT_ARRIVAL_PORT_CODE = 'DEFAULT_ARRIVAL_PORT_CODE',
  DEPARTURE_CODE = 'DEPARTURE_CODE',
  EMAIL_TEMPLATE_CODE = 'EMAIL_TEMPLATE_CODE',
  EINVOICE_TYPE_CODE = 'EINVOICE_TYPE_CODE',
  ETICKET_CODE = 'ETICKET_CODE',
  ETICKET_TYPE_CODE = 'ETICKET_TYPE_CODE',
  EXTERNAL_ACCESS_REFERENCE_NUMBER = 'EXTERNAL_ACCESS_REFERENCE_NUMBER',
  EXTERNAL_ACCESS_REFERENCE_TYPE = 'EXTERNAL_ACCESS_REFERENCE_TYPE',
  EXTERNAL_ACCESS_RESOURCE_CODE = 'EXTERNAL_ACCESS_RESOURCE_CODE',
  EXTERNAL_ACCESS_RESOURCE_GRANT_ACCESS_ID = 'EXTERNAL_ACCESS_RESOURCE_GRANT_ACCESS_ID',
  EXTERNAL_ACCESS_RESOURCE_TYPE = 'EXTERNAL_ACCESS_RESOURCE_TYPE',
  GUEST_TYPE_CODE = 'GUEST_TYPE_CODE',
  INVOICING_INTERVAL_CODE = 'INVOICING_INTERVAL_CODE',
  INVOICING_JOB_NUMBER = 'INVOICING_JOB_NUMBER',
  JOURNEY_CODE = 'JOURNEY_CODE',
  JOURNEY_GROUP_CODE = 'JOURNEY_GROUP_CODE',
  JOURNEY_NAME_LIST_REFERENCE = 'JOURNEY_NAME_LIST_REFERENCE',
  LANGUAGE_CODE = 'LANGUAGE_CODE',
  METHOD_OF_PAYMENT_CODE = 'METHOD_OF_PAYMENT_CODE',
  MOBILE_DEVICE_CODE = 'MOBILE_DEVICE_CODE',
  MOBILE_KEY_CODE = 'MOBILE_KEY_CODE',
  MOBILE_KEY_LOCK_TYPE = 'MOBILE_KEY_LOCK_TYPE',
  NAME_LIST_ROW_NUMBER = 'NAME_LIST_ROW_NUMBER',
  NATIONALITY_CODE = 'NATIONALITY_CODE',
  PORT_CODE = 'PORT_CODE',
  PORT_EXT_CODE = 'PORT_EXT_CODE',
  PRODUCT_CODE = 'PRODUCT_CODE',
  PAYMENT_REFERENCE = 'PAYMENT_REFERENCE',
  RECRUITMENT_METHOD_CODE = 'RECRUITMENT_METHOD_CODE',
  SHIPPER_CODE = 'SHIPPER_CODE',
  SMS_TEMPLATE_CODE = 'SMS_TEMPLATE_CODE',
  STATE_CODE = 'STATE_CODE',
  TAX_OFFICE_CODE = 'TAX_OFFICE_CODE',
  TERMS_AND_CONDITIONS_TEXT_GROUP_CODE = 'TERMS_AND_CONDITIONS_TEXT_GROUP_CODE',
  TERMS_OF_PAYMENT_CODE = 'TERMS_OF_PAYMENT_CODE',
  TERMINAL_CODE = 'TERMINAL_CODE',
  TITLE_CODE = 'TITLE_CODE',
  TRANSACTION_NUMBER = 'TRANSACTION_NUMBER',
  TRAVEL_DOCUMENT_TYPE_CODE = 'TRAVEL_DOCUMENT_TYPE_CODE',
  VEHICLE_MAKE_MODEL_CODE = 'VEHICLE_MAKE_MODEL_CODE',
  VOUCHER_CODE = 'VOUCHER_CODE',
  UNKNOWN = 'UNKNOWN'
}

export enum ResourceReferenceResourceType {
  ACCOUNTING_JOB = 'ACCOUNTING_JOB',
  ACCOUNTING_SYSTEM = 'ACCOUNTING_SYSTEM',
  ACTOR = 'ACTOR',
  AGENT = 'AGENT',
  APISYS = 'APISYS',
  APP = 'APP',
  APP_INSTANCE = 'APP_INSTANCE',
  APP_INSTANCE_LOCALE = 'APP_INSTANCE_LOCALE',
  AUTOMAT = 'AUTOMAT',
  BOOKING = 'BOOKING',
  BOOKING_ROW_ITEM = 'BOOKING_ROW_ITEM',
  BOOKING_NAME_LIST_ROW = 'BOOKING_NAME_LIST_ROW',
  BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP = 'BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP',
  BOOKING_VERSION = 'BOOKING_VERSION',
  BROCHURE_PRODUCT = 'BROCHURE_PRODUCT',
  CARGO_TYPE = 'CARGO_TYPE',
  CATEGORY_STRUCT = 'CATEGORY_STRUCT',
  CATEGORY_STRUCT_GROUP = 'CATEGORY_STRUCT_GROUP',
  CLUB = 'CLUB',
  COUNTRY = 'COUNTRY',
  CURRENCY = 'CURRENCY',
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_PRIVILEGE_GROUP = 'CUSTOMER_PRIVILEGE_GROUP',
  CUSTOMER_SUB_LOGIN = 'CUSTOMER_SUB_LOGIN',
  DEPARTURE = 'DEPARTURE',
  EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
  EINVOICE = 'EINVOICE',
  ETICKET = 'ETICKET',
  EXTERNAL_ACCESS_REFERENCE = 'EXTERNAL_ACCESS_REFERENCE',
  EXTERNAL_ACCESS_RESOURCE = 'EXTERNAL_ACCESS_RESOURCE',
  EXTERNAL_ACCESS_RESOURCE_GRANT = 'EXTERNAL_ACCESS_RESOURCE_GRANT',
  GUEST_TYPE = 'GUEST_TYPE',
  INVOICING_INTERVAL = 'INVOICING_INTERVAL',
  INVOICING_JOB = 'INVOICING_JOB',
  JOURNEY = 'JOURNEY',
  JOURNEY_GROUP = 'JOURNEY_GROUP',
  JOURNEY_NAME_LIST_REFERENCE = 'JOURNEY_NAME_LIST_REFERENCE',
  LANGUAGE = 'LANGUAGE',
  METHOD_OF_PAYMENT = 'METHOD_OF_PAYMENT',
  MOBILE_DEVICE = 'MOBILE_DEVICE',
  MOBILE_KEY = 'MOBILE_KEY',
  MOBILE_KEY_LOCK_TYPE = 'MOBILE_KEY_LOCK_TYPE',
  PAYMENT = 'PAYMENT',
  PORT = 'PORT',
  RECRUITMENT_METHOD = 'RECRUITMENT_METHOD',
  SMS_TEMPLATE = 'SMS_TEMPLATE',
  STATE = 'STATE',
  TAX_OFFICE = 'TAX_OFFICE',
  TERMS_AND_CONDITIONS_TEXT_GROUP = 'TERMS_AND_CONDITIONS_TEXT_GROUP',
  TERMS_OF_PAYMENT = 'TERMS_OF_PAYMENT',
  TERMINAL = 'TERMINAL',
  TITLE = 'TITLE',
  TRAVEL_DOCUMENT = 'TRAVEL_DOCUMENT',
  VEHICLE_MAKE_MODEL = 'VEHICLE_MAKE_MODEL',
  VOUCHER = 'VOUCHER',
  UNKNOWN = 'UNKNOWN'
}

export enum Error404RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error409RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error422RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error422ErrorSubType {
  REFERENCED_RESOURCE_NOT_FOUND = 'REFERENCED_RESOURCE_NOT_FOUND',
  REFERENCED_RESOURCE_FORBIDDEN = 'REFERENCED_RESOURCE_FORBIDDEN',
  REFERENCED_RESOURCE_CONFLICT = 'REFERENCED_RESOURCE_CONFLICT',
  UNKNOWN = 'UNKNOWN'
}

export enum Error500RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export type InitiateExtEcommercePaymentData = InitiateExtEcommercePaymentResponse

export type CompleteExtEcommercePaymentData = CompleteExtEcommercePaymentResponse

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType
} from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL:
        axiosConfig.baseURL ||
        'https://api-test-rec.carus.com/ext-ecommerce-payments/rectest/v1'
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] =
        property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        )
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    })
  }
}

/**
 * @title External Payments API - Ecommerce Payments
 * @version 11.0.0
 * @license Carus License (https://www.carus.com)
 * @termsOfService https://www.carus.com
 * @baseUrl https://api-test-rec.carus.com/ext-ecommerce-payments/rectest/v1
 * @contact Carus <support@carus.com> (https://www.carus.com)
 *
 * API for managing external Ecommerce Payments
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  extPayments = {
    /**
     * No description
     *
     * @tags ExternalPaymentsAPIEcommercePayments, ExternalPaymentsApiEcommercePayments
     * @name InitiateExtEcommercePayment
     * @summary Initiate ecommerce payment for a booking
     * @request POST:/ext-payments/v1/booking-code/{bookingCode}/ecommerce-payment
     * @secure
     * @response `201` `InitiateExtEcommercePaymentData` Information needed to allow the external client to make the payment
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `403` `Error403` Provided method of payment not allowed for user.
     * @response `404` `Error404` Provided booking code not found.
     * @response `409` `Error409` Booking is open
     * @response `422` `Error422` Error given if method of payment is not found or not supported. You will also get this if currency is not found.
     * @response `500` `Error500` Unexpected error
     */
    initiateExtEcommercePayment: (
      bookingCode: string,
      data: InitiateExtEcommercePaymentRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        InitiateExtEcommercePaymentData,
        Error400 | Error403 | Error404 | Error409 | Error422 | Error500
      >({
        path: `/ext-payments/v1/booking-code/${bookingCode}/ecommerce-payment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags ExternalPaymentsAPIEcommercePayments, ExternalPaymentsApiEcommercePayments
     * @name CompleteExtEcommercePayment
     * @summary Update a payment as completed
     * @request PATCH:/ext-payments/v1/transaction-number/{transactionNumber}/ecommerce-payment:complete
     * @secure
     * @response `200` `CompleteExtEcommercePaymentData` Information about completed payment.
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `404` `Error404` Provided transaction number not found.
     * @response `500` `Error500` Unexpected error
     */
    completeExtEcommercePayment: (
      transactionNumber: number,
      complete: string,
      data: CompleteExtEcommercePaymentRequest,
      params: RequestParams = {}
    ) =>
      this.request<CompleteExtEcommercePaymentData, Error400 | Error404 | Error500>({
        path: `/ext-payments/v1/transaction-number/${transactionNumber}/ecommerce-payment${complete}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      })
  }
}
