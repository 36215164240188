import { extendTheme, withDefaultProps } from '@chakra-ui/react'

const colors = {
  baseYellow: '#FFE400',
  baseBlue: '#0051A5',
  baseBlueLight: '#D9E5F2',
  baseBlueUltraLight: '#F2F6FB',
  baseBlack: '#000',
  baseWhite: '#fff',
  baseSilver: '#f2f2f2',
  baseBrandBlue: '#0051a5',
  baseBrandYellow: '#ffda00',
  baseText: '#4a4a4a',
  baseLightGray: '#fafafa',
  baseLighterGray: '#f3f3f3',
  baseGray: '#e0e0e0',
  baseDark: '#333333',
  baseDarkGray: '#9b9b9b',
  baseDarkerGray: '#979797',
  baseGreen: '#7ed321',
  baseLightGreen: '#cbffcd',
  baseRed: '#bf2828',
  baseLightRed: '#ffe6e5',
  baseCyan: '#aaddfa',
  baseBabyBlue: '#edf9ff',
  baseLightYellow: '#fff7c5',
  baseSail: '#a9defa'
}

export default extendTheme(
  withDefaultProps({
    defaultProps: {
      variant: 'outline',
      size: 'md'
    },
    components: ['Input', 'NumberInput', 'PinInput', 'Select']
  }),
  withDefaultProps({
    defaultProps: {
      size: 'md'
    },
    components: ['Button', 'Radio']
  }),
  {
    fonts: {
      heading: 'neo-sans, sans-serif',
      body: 'neo-sans, sans-serif'
    },
    styles: {
      global: {
        body: {
          backgroundColor: colors.baseSilver
        },
        a: {
          color: colors.baseBlue,
          _hover: {
            textDecoration: 'underline'
          }
        }
      }
    },
    colors,
    components: {
      Button: {
        baseStyle: {
          fontWeight: 'light',
          bg: colors.baseBlue,
          color: colors.baseWhite,
          borderRadius: 'full',
          _hover: {
            _disabled: {
              bg: colors.baseDarkerGray
            }
          },
          _disabled: {
            bg: colors.baseDarkerGray,
            opacity: 1
          }
        },
        variants: {
          base: {}, // Make a variant, we'll call it `base` here and leave it empty
          second: {
            color: colors.baseBlue,
            backgroundColor: colors.baseYellow
          }
        },
        defaultProps: {
          variant: 'base' // Then here we set the base variant as the default
        }
      },
      FormLabel: {
        baseStyle: {
          fontWeight: 'normal'
        }
      },
      FormHelperText: {
        baseStyle: {
          fontWeight: 'normal'
        }
      },
      Radio: {
        parts: ['label'],
        baseStyle: {
          label: {
            fontWeight: 'normal'
          }
        }
      },
      Input: {
        variants: {
          custom: {
            field: {
              fontWeight: 'normal',
              background: colors.baseWhite,
              border: '1px solid',
              borderRadius: 'md',
              borderColor: '#e2e8f0',
              _focus: {
                borderColor: 'transparent',
                outlineColor: colors.baseBlue,
                outlineStyle: 'auto',
                outlineWidth: 'md'
              },
              color: colors.baseText,
              fontFamily: 'neo-sans, sans-serif',
              _placeholder: {
                color: 'rgba(0, 0, 0, 0.36)'
              }
            }
          },
          outline: {
            field: {
              boxShadow: 'inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)',
              fontWeight: 'light',
              background: colors.baseWhite,
              border: '1px solid',
              borderRadius: '3px',
              borderColor: colors.baseGray,
              _focus: {
                borderColor: colors.baseBlue
              },
              color: colors.baseText,
              fontFamily: 'neo-sans, sans-serif',
              _placeholder: {
                color: colors.baseDarkGray
              }
            }
          }
        }
      },
      Select: {
        variants: {
          custom: {
            field: {
              fontWeight: 'normal',
              background: colors.baseWhite,
              border: '1px solid',
              borderRadius: 'md',
              borderColor: '#e2e8f0',
              _focus: {
                borderColor: 'transparent',
                outlineColor: colors.baseBlue,
                outlineStyle: 'auto',
                outlineWidth: 'md'
              },
              color: colors.baseText,
              fontFamily: 'neo-sans, sans-serif',
              _placeholder: {
                color: 'rgba(0, 0, 0, 0.36)'
              }
            }
          },
          outline: {
            field: {
              boxShadow: 'inset 0 1px 3px 0 rgba(0, 0, 0, 0.5)',
              fontWeight: 'light',
              background: colors.baseWhite,
              border: '1px solid',
              borderRadius: '3px',
              borderColor: colors.baseGray,
              _focus: {
                borderColor: colors.baseBlue
              },
              color: colors.baseText,
              fontFamily: 'neo-sans, sans-serif',
              _placeholder: {
                color: colors.baseDarkGray
              }
            }
          }
        }
      }
    }
  }
)
