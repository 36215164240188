import { addDays, parse, subDays } from 'date-fns'
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  reaction,
  runInAction
} from 'mobx'

import {
  CargoBooking,
  CargoExtSearchResult,
  SearchBookingsCriteria
} from '../api/cargoSelfServiceAPI'
import api from '../services/api'
import { dateToString } from '../utils/helpers'
import ErrorStore from './ErrorStore'
import { SEARCH_DATE_RANGE_FROM, SEARCH_DATE_RANGE_TO } from '../utils/constants'

class SearchStore {
  @observable fromDate: Date = subDays(new Date(), SEARCH_DATE_RANGE_FROM)
  @observable toDate: Date = addDays(new Date(), SEARCH_DATE_RANGE_TO)
  @observable criteria: SearchBookingsCriteria = {
    bookingCode: '',
    externalNote: '',
    freeTextSearch: {
      text: ''
    },
    registrationNumber: ''
  }
  @observable searchResults: Array<
    CargoExtSearchResult | (CargoExtSearchResult & CargoBooking)
  > = []
  private timeout?: number
  private autorunDisposer: (() => void) | null = null

  constructor() {
    makeAutoObservable(this)
  }

  @computed
  get value() {
    return `${this.fromDateAsString}${this.toDateAsString}${
      this.criteria.bookingCode
    }${this.criteria.externalNote}${this.criteria.freeTextSearch!.text}${
      this.criteria.registrationNumber
    }`
  }

  @computed
  get criteriaAsString() {
    return `${this.criteria.bookingCode}${this.criteria.externalNote}${
      this.criteria.freeTextSearch!.text
    }${this.criteria.registrationNumber}`
  }

  @computed
  get fromDateAsString() {
    return dateToString(this.fromDate)
  }

  @computed
  get toDateAsString() {
    return dateToString(this.toDate)
  }

  @computed
  get searchCriteria() {
    return {
      ...(this.criteria.bookingCode
        ? { bookingCode: this.criteria.bookingCode }
        : {}),
      ...(this.criteria.externalNote
        ? { externalNote: this.criteria.externalNote }
        : {}),
      ...(this.criteria.registrationNumber
        ? { registrationNumber: this.criteria.registrationNumber }
        : {}),
      ...(this.criteria.freeTextSearch!.text
        ? { freeTextSearch: { text: `${this.criteria.freeTextSearch!.text}*` } }
        : {})
    }
  }

  @action
  setFromDate = (date: string) => {
    this.fromDate = parse(date)
    this.searchResults = []
  }

  @action
  setToDate = (date: string) => {
    this.toDate = parse(date)
    this.searchResults = []
  }

  @action
  setBookingCode = (bookingCode: string) => {
    this.criteria.bookingCode = bookingCode
    this.searchResults = []
  }

  @action
  setExternalNode = (externalNode: string) => {
    this.criteria.externalNote = externalNode
    this.searchResults = []
  }

  @action
  setFreeText = (text: string) => {
    this.searchResults = []
    this.criteria.freeTextSearch!.text = text
  }

  @action
  setRegistrationNumber = (registrationNumber: string) => {
    this.criteria.registrationNumber = registrationNumber
    this.searchResults = []
  }

  debounceSearch = () => {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = window.setTimeout(() => {
      this.searchBookings()
    }, 200)
  }

  @action
  searchBookings = async () => {
    try {
      ErrorStore.addLoader()
      const {
        data: { results = [] }
      } = await api.cargoSelfService.bookingSearch(
        this.fromDateAsString,
        0,
        0,
        this.toDateAsString,
        23,
        59,
        this.searchCriteria,
        undefined
      )
      runInAction(() => {
        this.searchResults = results
      })
      ErrorStore.removeLoader()
    } catch (e) {
      console.error(e)
      ErrorStore.removeLoader()
    }
  }

  @action
  fetchBooking = async (bookingCode: string) => {
    try {
      const { data: booking } = await api.cargoSelfService.getCargoBookingWithCode(
        bookingCode,
        { recallCancelled: false }
      )
      runInAction(() => {
        this.searchResults = this.searchResults.map((result) => {
          if (result.bookingCode === bookingCode) {
            return { ...result, ...booking }
          }
          return result
        })
      })
    } catch (e) {
      console.error(e)
    }
  }

  @action
  reset = () => {
    this.searchResults = []
    this.criteria = {
      bookingCode: '',
      externalNote: '',
      freeTextSearch: {
        text: ''
      },
      registrationNumber: ''
    }
  }

  @action
  registerAutorun() {
    this.autorunDisposer = this.startAutorun()
  }

  @action
  disposeAutorun() {
    this.autorunDisposer && this.autorunDisposer()
  }

  @action
  startAutorun = () => {
    return reaction(
      () => this.value,
      () => {
        this.debounceSearch()
      }
    )
  }
}

export default new SearchStore()
