import classNames from 'classnames'
import React from 'react'

import css from './Radio.module.css'

const Radio: React.FC<React.HTMLProps<HTMLInputElement>> = (props) => {
  return (
    <div
      className={classNames(css.component, {
        [css.checked]: props.checked,
        [css.disabled]: props.disabled
      })}
    >
      <input {...props} type="radio" />
    </div>
  )
}

export default Radio
