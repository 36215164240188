import React from 'react'
import { Center, Container, Flex, Skeleton, Stack, Text } from '@chakra-ui/react'
import { ConfirmationNumberIcon, OpenInNewWindowIcon } from '../../../icons'
import { format } from 'date-fns'
import et from 'date-fns/locale/et'
import ru from 'date-fns/locale/ru'
import en from 'date-fns/locale/en'
import fi from 'date-fns/locale/fi'
import pl from 'date-fns/locale/pl'
import { DriverCargoBooking } from '../../../api/driverUpdateAPI'
import { observer } from 'mobx-react'
import { portNameTranslations } from './helpers'
import { LanguageTypes } from '../../../locales'
import { capitalizeFirstLetter } from '../../../utils/helpers'
import { useTranslation } from 'react-i18next'

const localeMap: Record<LanguageTypes, Locale> = {
  ru,
  en,
  fi,
  pl,
  ee: et
}

type Props = {
  loading: boolean
  booking?: Partial<DriverCargoBooking>
}

const portLocations: Record<string, string> = {
  T: 'https://goo.gl/maps/VCeYUCmoiXxsW1tS9',
  TM: 'https://goo.gl/maps/rngU3ckTP6QXA26P9',
  H: 'https://goo.gl/maps/a9WSMEKUB3zJD6eXA',
  HV: 'https://goo.gl/maps/qiu7NZNAPmeAkHdx5'
}

const BookingShortInfo: React.FC<Props> = observer(({ loading, booking }) => {
  const {
    departurePortExtCode,
    departurePortName,
    departureTime,
    arrivalPortExtCode,
    arrivalTime,
    arrivalPortName
  } = booking?.cargoJourneys?.[0] || {}

  const { t, i18n } = useTranslation()

  const validDeparture = departurePortExtCode && departureTime
  const validArrival = arrivalPortExtCode && arrivalTime

  const translation = portNameTranslations[i18n.language]

  const fromPort =
    validDeparture &&
    (translation.from[departurePortExtCode] || `From ${departurePortName} at `) +
      format(new Date(departureTime), 'HH:mm')

  const toPort =
    validArrival &&
    (translation.to[arrivalPortExtCode] || `To ${arrivalPortName} at `) +
      format(new Date(arrivalTime), 'HH:mm')

  return (
    <Container maxW="100%" justifyContent="center" p={[3, 3, 3, 0]}>
      <Center bg="#F2F6FB" borderRadius={['md', 'md', 'md', 0]}>
        <Flex justify="space-between" w="100%" maxW="container.md" p={5}>
          <Stack>
            <Skeleton minW="220px" isLoaded={!loading}>
              <Text fontSize="2xl" color="baseBlue" fontWeight="400">
                {t('booking')} <strong>{booking?.bookingCode}</strong>
              </Text>
            </Skeleton>
            <Skeleton minW="220px" isLoaded={!loading}>
              <Text fontSize="md" color="baseBlue" fontWeight="400">
                <a href={portLocations[departurePortExtCode!]} target="_blank">
                  <Flex alignItems="center">
                    {fromPort}&nbsp;
                    <OpenInNewWindowIcon fill="#0051A5" height="16px" width="16px" />
                  </Flex>
                </a>
                <a href={portLocations[arrivalPortExtCode!]} target="_blank">
                  <Flex alignItems="center">
                    {toPort}&nbsp;
                    <OpenInNewWindowIcon fill="#0051A5" height="16px" width="16px" />
                  </Flex>
                </a>
                {arrivalTime && (
                  <Text fontWeight="bold" paddingTop={2}>
                    {capitalizeFirstLetter(
                      format(new Date(arrivalTime), 'MMMM Do YYYY', {
                        locale: localeMap[i18n.language]
                      })
                    )}
                  </Text>
                )}
              </Text>
            </Skeleton>
          </Stack>
          <Center display={['none', 'flex', 'flex', 'flex']}>
            <ConfirmationNumberIcon fill="#0051A5" height="45px" width="45px" />
          </Center>
        </Flex>
      </Center>
    </Container>
  )
})

export default BookingShortInfo
