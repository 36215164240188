import { FunctionComponent, PropsWithChildren } from 'react'
import theme from '../../theme'
import { ChakraProvider } from '@chakra-ui/react'

type LayoutProps = PropsWithChildren<{}>

const Layout: FunctionComponent<LayoutProps> = ({ children }) => (
  <ChakraProvider theme={theme}>{children}</ChakraProvider>
)

export default Layout
