import { format } from 'date-fns'
import React, { useState } from 'react'
import { FaBolt, FaTruck } from 'react-icons/fa'
import { MdClose, MdEdit, MdEvent, MdFace } from 'react-icons/md'
import { Link } from 'react-router-dom'

import Container from '../../../../components/Container/Container'
import Row from '../../../../components/Row/Row'
import {
  CargoBooking,
  CargoJourneyBookingRowAvailabilityGroupType
} from '../../../../api/cargoSelfServiceAPI'
import css from './Booking.module.css'
import classNames from 'classnames'

import Alert from '../../../../components/Alert/Alert'
import CopyToPasteboard from '../../../../components/CopyToPasteboard/CopyToPasteboard'
import { ErrorLevel } from '../../../../stores/ErrorStore'
import modalStore from '../../../../stores/ModalStore'
import { CARGOWEB } from '../../../../utils/constants'
import { FormProvider, useForm } from 'react-hook-form'
import SendLinkToDriver, {
  DriversFormValues
} from '../../../reservation/components/Confirmation/SendLinkToDriver'
import {
  Button,
  Stack,
  useToast,
  Text,
  Flex,
  Alert as ChakraAlert,
  AlertIcon
} from '@chakra-ui/react'
import reservationStore from '../../../../stores/ReservationStore'
import { DriverTokenResponse } from '../../../../api/driverUpdateAPI'
import userStore from '../../../../stores/UserStore'
import { isBookingUpdatable } from '../../../../utils/bookingHelpers'
import { useTranslation } from 'react-i18next'

type Props = {
  booking: CargoBooking
  onClick: () => void
  isActive: boolean
  departureDatetime: string
  deleteBooking: () => void
  pastReservations?: boolean
}

const Booking: React.FC<Props> = (props) => {
  const {
    isActive,
    onClick,
    departureDatetime,
    deleteBooking,
    booking,
    pastReservations: isPast
  } = props

  const {
    bookingApiSys,
    bookingCode,
    bookingContacts,
    bookingTime,
    bookingLastChangeTime,
    namelist = [],
    cargoJourneys = [],
    bookingStatusCode,
    bookingBalance,
    currencyExtCode
  } = booking

  const toast = useToast()

  const [isSent, setIsSent] = useState(false)
  const { t } = useTranslation()

  const methods = useForm<DriversFormValues>({
    defaultValues: {
      email: '',
      phone: ''
    },
    mode: 'onChange'
  })

  const [cargoJourney] = cargoJourneys
  const vehicle =
    cargoJourney &&
    cargoJourney.journeyBookingRows!.find(
      (x) =>
        x.availabilityGroupType ===
        CargoJourneyBookingRowAvailabilityGroupType.VEHICLE
    )

  const { arrivalPortName = '', departurePortName = '' } = cargoJourney || {}

  if (!vehicle) {
    return null
  }

  const {
    connectedBookingRows = [],
    internalNote = '',
    externalNote = '',
    vehicleProperties = undefined,
    extCategoryDisplayName = ''
  } = vehicle || {}

  const registrationNumbers =
    vehicleProperties &&
    vehicleProperties.registrationNumbers!.reduce(
      (a, { registrationNumberCode }) =>
        a ? a + ' + ' + registrationNumberCode : registrationNumberCode,
      ''
    )
  const {
    cargoDescriptions = false,
    loadWeight = '',
    length = 0
  } = vehicleProperties || {}

  const {
    isUpdatable,
    containsDangerousCargo: hasDangerousGoods,
    isCancelAllowed: isCancelingAllowed
  } = isBookingUpdatable({ booking })

  const portNamesAndDepartureTime =
    (departurePortName &&
      arrivalPortName &&
      `${departurePortName} - ${arrivalPortName}`) +
    (departureDatetime && ` / ${format(departureDatetime, 'D.M. / HH:mm')}`)

  const bookingTitle: JSX.Element = (
    <div className={css.bookingTitle}>
      {portNamesAndDepartureTime && (
        <div>
          <Text fontWeight="bolder" as="b">
            {portNamesAndDepartureTime}
          </Text>
        </div>
      )}
      {registrationNumbers && (
        <div>
          <Text fontWeight="bolder" as="b">
            Truck:&nbsp;
          </Text>
          {registrationNumbers}
        </div>
      )}
      <Flex alignItems="center" justifyContent="space-between">
        <Flex>
          <Text fontWeight="bolder" as="b">
            Booking:&nbsp;
          </Text>
          {bookingCode}
        </Flex>
        <CopyToPasteboard value={bookingCode || ''} />
      </Flex>
      {externalNote && (
        <div>
          <Text fontWeight="bolder" as="b">
            Reference:&nbsp;
          </Text>
          {externalNote}
        </div>
      )}
    </div>
  )

  const onSendSubmit = methods.handleSubmit(async ({ email, phone }) => {
    if ((!email && !phone) || !bookingCode) {
      methods.setError(
        'phone',
        {
          type: 'required',
          message: 'At least one field must be filled'
        },
        { shouldFocus: true }
      )

      return
    }

    const [driver] = await reservationStore.generateDriverToken({
      email,
      bookingCode,
      mobile: phone,
      expiryTime: cargoJourney.departureTime
    })

    const isValidDriverToken = (
      driverToken: null | DriverTokenResponse
    ): driverToken is DriverTokenResponse =>
      Boolean(driverToken !== null && driverToken)

    if (
      isValidDriverToken(driver) &&
      ['emailSent', 'smsSent'].some((key) => driver[key])
    ) {
      toast({
        title: 'Login sent to the driver successfully',
        status: 'success'
      })

      setIsSent(true)

      const timeoutId = setTimeout(() => {
        setIsSent(false)
        clearInterval(timeoutId)
      }, 3000)

      return
    }

    toast({
      title: 'Login was not sent',
      description: 'Please check the entered data',
      status: 'error'
    })
  })

  const inUnpaid = (bookingBalance || -1) > 0

  const unpaidAlert = ((userStore.isOccasional && !isPast && inUnpaid) || null) && (
    <ChakraAlert fontSize="sm" status="error">
      <AlertIcon />
      <Text>
        This booking has not been paid in full, amount to pay is:{' '}
        <Text fontWeight="normal" as="span">
          {bookingBalance} {currencyExtCode}
        </Text>
      </Text>
    </ChakraAlert>
  )

  return (
    <div className={css.container}>
      <Container
        icon={<MdEvent />}
        title={bookingTitle}
        isActive={isActive}
        onClick={onClick}
        headerClass={css[bookingStatusCode!]}
      >
        <Row border>
          <span>{extCategoryDisplayName}</span>
          <FaTruck className={css.icon} />
        </Row>
        {!!connectedBookingRows.find((row) => row.extCategoryCode === 'POW') && (
          <Row border>
            <span>{t('power_connection')}</span>
            <FaBolt className={css.icon} />
          </Row>
        )}
        {namelist.map((item, index) => (
          <Row border key={index}>
            <span>
              {item.firstName} {item.lastName} {item.mobile}
            </span>
            <MdFace className={css.icon} />
          </Row>
        ))}
        {bookingContacts && bookingContacts[0] && (
          <div className={css.reference}>
            Contact: {bookingContacts[0].contactInfo}
          </div>
        )}
        <div className={css.reference}>
          Created: {format(bookingTime || '', 'DD.MM.YYYY - HH:mm')}
          <div>{bookingApiSys === CARGOWEB ? 'ONLINE' : 'INHOUSE'}</div>
        </div>
        <div className={css.reference}>
          Last edited: {format(bookingLastChangeTime || '', 'DD.MM.YYYY - HH:mm')}
        </div>
        {cargoDescriptions && cargoDescriptions[0] && (
          <div className={css.reference}>
            Cargo:{' '}
            {cargoDescriptions[0].cargoDescription + ` ${loadWeight} kg ${length} m`}
          </div>
        )}
        {internalNote && <div className={css.reference}>Note: {internalNote}</div>}
        {hasDangerousGoods && (
          <Alert level={ErrorLevel.info}>{t('has_dangerous_goods_info')}</Alert>
        )}
        <Row className={css.utilsWrapper}>
          <div className={css.utils}>
            {isCancelingAllowed ? (
              <button
                type="button"
                onClick={() =>
                  modalStore.confirm('Are you sure?', '', () => {
                    deleteBooking()
                  })
                }
                title="Cancel booking"
                className={css.button}
              >
                {t('cancel')} <MdClose className={css.icon} />
              </button>
            ) : (
              <div
                className={classNames(css.button, css.disabled)}
                title="Cancel not allowed"
              >
                {t('cancel')} <MdClose className={css.icon} />
              </div>
            )}
            {isUpdatable ? (
              <Link
                to={`/confirmation?action=edit&bookingCode=${bookingCode}`}
                title="Edit booking"
                className={css.button}
              >
                {t('edit')} <MdEdit className={css.editIcon} />
              </Link>
            ) : (
              <div
                className={classNames(css.button, css.disabled)}
                title="Edit not allowed"
              >
                {t('edit')} <MdEdit className={css.editIcon} />
              </div>
            )}
          </div>
        </Row>
      </Container>

      {unpaidAlert}

      {!isPast && isUpdatable && (
        <Container>
          <Row>
            <Stack>
              <FormProvider {...methods}>
                <SendLinkToDriver drivers={namelist} />
              </FormProvider>
              <Button
                alignSelf="flex-end"
                onClick={onSendSubmit}
                w="auto"
                bg={isSent ? 'green.500' : undefined}
                colorScheme="teal"
                size="sm"
              >
                {isSent ? 'Login sent' : 'Send login'}
              </Button>
            </Stack>
          </Row>
        </Container>
      )}
    </div>
  )
}

export default Booking
