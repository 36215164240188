import React from 'react'
import {
  Radio,
  RadioGroup,
  Skeleton,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { observer } from 'mobx-react'
import occasionalCustomerStore from '../../../stores/OccasionalCustomerStore'
import reservationStore from '../../../stores/ReservationStore'
import errorStore from '../../../stores/ErrorStore'
import { useTranslation } from 'react-i18next'

const pricesPlaceholder = Array(5).fill({ price: 0, categoryName: '', extCode: '' })

const PriceList = observer(() => {
  const { prices } = occasionalCustomerStore
  const { isLoading } = errorStore
  const { t } = useTranslation()

  const computedPrices = isLoading ? pricesPlaceholder : prices

  return (
    <TableContainer mt={2}>
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            <Th>{t('cargo_categories')}</Th>
            <Th textAlign="right">{t('price')}</Th>
          </Tr>
        </Thead>
        <RadioGroup
          as={Tbody}
          onChange={(extCode) => reservationStore.setVehicleTemplate(extCode)}
          value={reservationStore.vehicleTemplate?.categoryExtCode || ''}
        >
          {computedPrices.length ? (
            computedPrices.map(({ price, categoryName: name, extCode }, index) => {
              return (
                <Tr key={extCode || index}>
                  <Td fontWeight="normal" fontSize="md">
                    <Skeleton isLoaded={!isLoading}>
                      <Radio value={extCode}>{name}</Radio>
                    </Skeleton>
                  </Td>
                  <Td
                    fontWeight="normal"
                    textAlign="right"
                    fontSize="md"
                    color="baseBlue"
                  >
                    <Skeleton isLoaded={!isLoading}>{price?.toFixed(2)} €</Skeleton>
                  </Td>
                </Tr>
              )
            })
          ) : (
            <Tr>
              <Td colSpan={2}>
                <Text fontWeight="normal" fontSize="sm" as="span">
                  {t('select_dep_to_see_prices')}
                </Text>
              </Td>
            </Tr>
          )}
        </RadioGroup>
      </Table>
    </TableContainer>
  )
})

export default PriceList
