import classNames from 'classnames'
import React from 'react'
import { MdClose } from 'react-icons/md'

import { ErrorLevel } from '../../stores/ErrorStore'
import css from './Alert.module.css'

interface Props extends React.HTMLProps<HTMLDivElement> {
  level: ErrorLevel
}

const Alert: React.FC<Props> = ({
  level,
  children,
  className,
  onClick,
  ...rest
}) => (
  <div
    onClick={onClick}
    {...rest}
    className={classNames(css.component, css[level], className, {
      [css.clickable]: !!onClick
    })}
  >
    <div className={css.container}>
      {children}
      {onClick && <MdClose className={css.icon} />}
    </div>
  </div>
)

export default Alert
