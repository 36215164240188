import classNames from 'classnames'
import { addSeconds, parse } from 'date-fns'
import React from 'react'

import { timeDiffCalc } from '../utils/helpers'

type State = {
  timeParsed: Date
  intervalId: ReturnType<typeof setTimeout | typeof setInterval>
  timeCurrent: Date
}

type Props = {
  timeFrom?: string
  timeTo: Date
  className?: string
}

export class DownTimer extends React.Component<Props, State> {
  state = {
    timeParsed: parse(this.props.timeTo),
    timeCurrent: new Date(),
    intervalId: setTimeout(() => null, 0)
  }

  componentDidMount() {
    if (this.state.timeParsed && !this.isOverdue) {
      this.startCountDown()
    }
  }

  componentWillUnmount() {
    this.clearTimer()
  }

  clearTimer() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId)
    }
  }

  componentDidUpdate() {
    if (this.isOverdue) {
      this.clearTimer()
    }
  }

  startCountDown() {
    const intervalId = setInterval(() => {
      this.setState((state) => {
        return {
          timeCurrent: addSeconds(state.timeCurrent, 1),
          intervalId
        }
      })
    }, 1000)
  }

  get isOverdue() {
    return this.state.timeCurrent > this.state.timeParsed
  }

  render() {
    if (this.isOverdue) {
      return (
        <span title="Overdue" className={classNames(this.props.className)}>
          --
        </span>
      )
    }

    const value = timeDiffCalc(
      this.state.timeParsed.getTime(),
      this.state.timeCurrent.getTime()
    )

    return (
      <span className={classNames(this.props.className)} title={'in ' + value}>
        {value || '--'}
      </span>
    )
  }
}
