import { ErrorLevel } from '../stores/ErrorStore'
import { MdCall, MdErrorOutline } from 'react-icons/md'
import { FaAt } from 'react-icons/fa'
import Alert from './Alert/Alert'
import React, { FC } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

const DangerousCargoAlert: FC<Exclude<BoxProps, 'as' | 'mt'>> = (props) => {
  return (
    <Box {...props} as={Alert} level={ErrorLevel.error} mt="20px">
      <div>
        <MdErrorOutline />
        <span>
          In case of an irregular size vehicle or dangerous cargo,
          <br /> please contact us for reservation!
        </span>
      </div>
      <div>
        <MdCall />
        <span>+358 9 6853957</span>
      </div>
      <div>
        <FaAt />
        <span>cargo@eckeroline.fi</span>
      </div>
    </Box>
  )
}

export default DangerousCargoAlert
