import classNames from 'classnames'
import { format, isPast, isToday } from 'date-fns'
import React, { PropsWithChildren } from 'react'

import css from '../Calendar.module.css'
import { isAfterDays } from '../../../utils/helpers'

type Props = PropsWithChildren<{
  date: Date
  isSelected?: boolean
  isVisible?: boolean
  onSelectDate: (date: Date) => void
}>

const Day: React.FC<Props> = ({
  children,
  date,
  isSelected = false,
  isVisible = false,
  onSelectDate
}) => {
  return (
    <div
      onClick={() => onSelectDate(date)}
      className={classNames(css.day, {
        [css.current]: isSelected,
        [css.currentMonth]: isVisible,
        [css.past]: !isToday(date) && isPast(date),
        [css.limited]: isVisible && isAfterDays(date)
      })}
    >
      {format(date, 'D')}
      {children}
    </div>
  )
}

export default Day
