import { observer } from 'mobx-react'
import React from 'react'

import Calendar from '../../../../components/Calendar/Calendar'
import Departures from '../../../../components/Departures/Departures'
import Status from '../../../../components/Status/Status'
import ErrorStore from '../../../../stores/ErrorStore'
import reservationStore from '../../../../stores/ReservationStore'
import css from './DepartureSelect.module.css'
import UserStore from '../../../../stores/UserStore'
import DeparturesOccasional from '../../../../components/Departures/DeparturesOccasional'
import { FaTruck } from 'react-icons/fa'
import { Alert, AlertIcon, Icon } from '@chakra-ui/react'

const DepartureSelect = observer(() => {
  const onSelectDate = (date: Date) => {
    reservationStore.setSelectedDate(date)
  }

  const onSelectMonth = (month: Date) => {
    // this will fetch the departures for the visible calendar days
    // We use onlyAllotments to limit the size of the response
    reservationStore.setMonth(month)
  }

  const renderDayContents = () =>
    reservationStore.calendarDepartures.map((departure) => {
      if (!departure) {
        return null
      }

      if (UserStore.isOccasional) {
        return <Icon as={FaTruck} color="baseGreen" />
      }

      return <Status departure={departure} />
    })

  return (
    <div className={css.component}>
      <div className={css.calendar}>
        <Calendar
          defaultMonth={reservationStore.month}
          selectedDate={reservationStore.selectedDate}
          onSelectDate={onSelectDate}
          onSelectMonth={onSelectMonth}
          isLoading={ErrorStore.isLoading}
          days={renderDayContents()}
        />
        <Alert status="info" variant="subtle" fontSize="sm">
          <AlertIcon />
          Bookings can be made only 14 days in advance
        </Alert>
      </div>
      {UserStore.isOccasional ? <DeparturesOccasional /> : <Departures />}
    </div>
  )
})

export default DepartureSelect
