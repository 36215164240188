import React from 'react'
import { createRoot } from 'react-dom/client'
import { createStandaloneToast } from '@chakra-ui/react'
import './index.scss'

import Root from './root/components/Root'
import './locales'

const { ToastContainer } = createStandaloneToast()

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Root />
    <ToastContainer />
  </React.StrictMode>
)
