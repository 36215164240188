import React from 'react'
import { Container, ContainerProps } from '@chakra-ui/react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

type Props = {
  title: string
} & ContainerProps

const View: React.FC<Props> = ({ title, children, ...containerProps }) => (
  <>
    <Header title={title} />
    <Container
      minH="64vh"
      w="100%"
      maxW="container.lg"
      px={0}
      bgColor="#fff"
      zIndex="1"
      transform={[null, null, null, 'translateY(10px)']}
      {...containerProps}
    >
      {children}
    </Container>
    <Footer />
  </>
)

export default View
