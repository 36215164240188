import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { Link as ReactLink, useLocation } from 'react-router-dom'
import {
  Box,
  Center,
  Container,
  Flex,
  Button,
  forwardRef,
  ButtonProps,
  Progress,
  useDisclosure,
  useMediaQuery,
  Link,
  Icon
} from '@chakra-ui/react'
import authStore from '../../stores/AuthStore'
import userStore from '../../stores/UserStore'
import { AccountCircleIcon, MenuIcon, LogoutIcon, CargoLogoIcon } from '../../icons'
import ErrorStore from '../../stores/ErrorStore'
import Alert from '../Alert/Alert'
import { MdErrorOutline } from 'react-icons/md'
import AsideDrawer from './AsideDrawer'
import { useTranslation } from 'react-i18next'

type Props = {
  title: string
}

const NavButton = forwardRef<ButtonProps, 'div'>((props, ref) => (
  <Button bg="transparent" p={0} minW="auto" ref={ref} {...props} />
))

const Header: React.FC<Props> = observer(({ title: documentTitle }) => {
  const [isLargerThan767] = useMediaQuery('(min-width: 767px)')
  const { pathname } = useLocation()
  const match = pathname.includes('/driver-update/')
  const { t } = useTranslation()

  const handleLogout = () => {
    authStore.logout()
  }
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef<SVGSVGElement>(null)

  useEffect(() => {
    document.title = `${documentTitle} | Eckerö Line Cargo`
  }, [documentTitle])

  const AsideMenu = (
    <>
      <MenuIcon
        fill="#fff"
        width="30px"
        height="30px"
        ref={btnRef}
        onClick={onOpen}
        style={{ cursor: 'pointer' }}
      />
      <AsideDrawer isOpen={isOpen} onClose={onClose} />
    </>
  )

  return (
    <Container position="sticky" top="0" zIndex={4} maxW="100%" bg="white" p="0">
      {isLargerThan767 && (
        <Center px={4}>
          <Flex
            w="100%"
            maxW="container.lg"
            minH="50px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link
              display="flex"
              my={1}
              _focus={{ outline: 'none' }}
              as={ReactLink}
              to="/"
            >
              <Icon minW="180px" as={CargoLogoIcon} h="auto" mr={2} />
            </Link>
            {authStore.isAuthenticated && isLargerThan767 && (
              <Flex alignItems="center" justifyContent="center">
                <Box>
                  <NavButton>
                    <AccountCircleIcon fill="#0051A5" width="30px" height="30px" />
                  </NavButton>
                </Box>
                <Box ml={2}>{userStore.customer.name || ''}</Box>
                <Box ml={5}>{userStore.computedEmail}</Box>
                <Box ml={5} onClick={handleLogout} cursor="pointer">
                  <NavButton mr={1}>
                    <Icon
                      as={LogoutIcon}
                      fill="baseBlue"
                      width="30px"
                      height="30px"
                      ref={btnRef}
                    />
                  </NavButton>
                  {t('logout')}
                </Box>
              </Flex>
            )}
          </Flex>
        </Center>
      )}
      <Flex
        w="100%"
        minH="50px"
        justifyContent="center"
        alignItems="center"
        bgColor="baseBlue"
        fontSize="xl"
        fontWeight={300}
        px={4}
        py={2}
      >
        <Flex alignItems="center" w="100%" maxW="container.lg">
          {authStore.isAuthenticated ? (
            <>
              {!match && AsideMenu}
              <Box fontSize="xl" color="white" ml={6} fontWeight="400">
                {documentTitle}
              </Box>
            </>
          ) : match ? (
            <Box fontSize="xl" color="white" ml={6} fontWeight="400">
              {documentTitle}
            </Box>
          ) : (
            <Link
              as={ReactLink}
              fontSize="xl"
              color="white"
              to="/"
              ml={6}
              fontWeight="400"
            >
              {t('login')}
            </Link>
          )}
          <Box ml="auto" id="header-bottom-id" />
        </Flex>
      </Flex>
      <Box bg="gray.100">
        <Progress
          mx="auto"
          maxW="container.lg"
          size="xs"
          isIndeterminate={ErrorStore.isLoading}
        />
      </Box>
      <div>
        {ErrorStore.map(
          ({ title, message, level, isClosed }, id) =>
            (isClosed === undefined || !isClosed) && (
              <Alert key={id} level={level} onClick={() => ErrorStore.close(id)}>
                <div>
                  <MdErrorOutline />
                  <div>
                    {title && <strong>{title}</strong>}
                    <div>{message}</div>
                  </div>
                </div>
              </Alert>
            )
        )}
      </div>
    </Container>
  )
})

export default Header
