import classNames from 'classnames'
import React from 'react'

import css from './Checkbox.module.css'

const Checkbox: React.FC<React.HTMLProps<HTMLInputElement>> = (props) => {
  return (
    <div className={classNames(css.component, { [css.checked]: props.checked })}>
      <input {...props} type="checkbox" />
    </div>
  )
}

export default Checkbox
