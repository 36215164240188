import { t } from '../locales'
import { ControllerFieldState } from 'react-hook-form'
import { isTranslated } from '../utils/helpers'
import { FieldError } from 'react-hook-form/dist/types'

const errorMessage = (error: FieldError) => {
  if (error.message) {
    if (isTranslated(error.message)) {
      return t(error.message) as string
    }
    return error.message
  }

  if (error.type === 'required') {
    return t('error.field_required')
  }

  return t('error.field_invalid')
}

const extractErrorStatus = ({ error }: ControllerFieldState): [string, boolean] => {
  const isInvalid = !!error
  return [error ? errorMessage(error) : '', isInvalid]
}

export { extractErrorStatus }
