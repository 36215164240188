import { addDays, subDays } from 'date-fns'
import { observer } from 'mobx-react'
import React from 'react'

import { withTranslation, WithTranslation } from 'react-i18next'
import SearchStore from '../../stores/SearchStore'
import { dateToString } from '../../utils/helpers'
import DeparturePortButtons from '../DeparturePortButtons/DeparturePortButtons'
import BaseInput from '../Form/BaseInput'
import Label from '../Label/Label'
import css from './Search.module.css'

type State = {
  advanced: boolean
}

@observer
class SearchForm extends React.Component<WithTranslation, State> {
  state = {
    advanced: false
  }

  onChange =
    (callback: (value: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      callback(event.target.value)
    }

  render() {
    const { t } = this.props

    return (
      <div className={css.component}>
        <div className={css.container}>
          <DeparturePortButtons />
        </div>
        <div className={css.advanced}>
          <Label full vertical>
            <BaseInput
              value={SearchStore.criteria.freeTextSearch?.text}
              onChange={this.onChange((value) => SearchStore.setFreeText(value))}
              placeholder={t('freeTextSearch')!}
            />
          </Label>
          <div className={css.dateRange}>
            <Label>
              <span className={css.labelText}>{t('from')}</span>
              <BaseInput
                value={SearchStore.fromDateAsString}
                type="date"
                min={dateToString(subDays(new Date(), 100))}
                onChange={this.onChange((value) => SearchStore.setFromDate(value))}
                onKeyPress={(e) => e.preventDefault()}
              />
            </Label>
            <Label>
              <span className={css.labelText}>{t('to')}</span>
              <BaseInput
                value={SearchStore.toDateAsString}
                type="date"
                max={dateToString(addDays(new Date(), 100))}
                onChange={this.onChange((value) => SearchStore.setToDate(value))}
                onKeyPress={(e) => e.preventDefault()}
              />
            </Label>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(SearchForm)
