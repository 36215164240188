import { observer } from 'mobx-react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import userStore from '../../stores/UserStore'
import authStore from '../../stores/AuthStore'
import InfoScreenContainer from '../../views/infoScreen/InfoScreenContainer'
import LoginContainer from '../../views/login/LoginContainer'
import PreliminaryBookingContainer from '../../views/preliminaryBooking/PreliminaryBookingContainer'
import ConfirmationContainer from '../../views/confirmation/ConfirmationContainer'
import ReservationContainer from '../../views/reservation/ReservationContainer'
import ReservationsContainer from '../../views/revervations/ReservationsContainer'
import DriverUpdateContainer from '../../views/driverUpdate/DriverUpdateContainer'
import Modal from '../../components/Modal/Modal'
import OccasionalCustomerContainer from '../../views/occasionalCustomer/OccasionalCustomerContainer'
import Reservation from '../../views/occasionalCustomer/Reservation'
import PaymentContainer from '../../views/payment/PaymentContainer'
import css from './Root.module.css'
import Layout from '../../components/Layout/Layout'
import { ReactElement } from 'react'
import RedirectToApp from '../../views/redirect/RedirectToApp'

const Root = observer(() => {
  const ReservationScreen = userStore.isOccasional
    ? Reservation
    : ReservationContainer

  const withLayout = (element: ReactElement) => <Layout>{element}</Layout>

  return (
    <div className={css.component}>
      <Modal />
      <div className={css.routes}>
        <BrowserRouter>
          <Routes>
            <Route path="/redirect/:appLink" element={<RedirectToApp />} />
            <Route path="/info" element={<InfoScreenContainer />} />
            <Route
              path="/driver-update/:token"
              element={withLayout(<DriverUpdateContainer />)}
            />
            <Route
              path="/occational-customer/:otp"
              element={withLayout(<OccasionalCustomerContainer />)}
            />
            <Route path="/payment" element={withLayout(<PaymentContainer />)} />
            {!authStore.isAuthenticated ? (
              <Route path="/" element={withLayout(<LoginContainer />)} />
            ) : (
              <>
                <Route path="/" element={withLayout(<ReservationScreen />)} />
                <Route
                  path="/reservations"
                  element={withLayout(<ReservationsContainer />)}
                />
                <Route
                  path="/done/:bookingCode"
                  element={withLayout(<ConfirmationContainer />)}
                />
                <Route
                  path="/preliminary"
                  element={withLayout(<PreliminaryBookingContainer />)}
                />
                <Route path="/:step" element={withLayout(<ReservationScreen />)} />
                <Route path="*" element={<Navigate to="/" replace />} />{' '}
              </>
            )}
            <Route path="*" element={<Navigate to="/" replace />} />{' '}
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  )
})

export default Root
