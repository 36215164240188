import {
  FormControl,
  FormLabel,
  Input,
  InputProps,
  Stack,
  Center,
  Tooltip,
  StackProps,
  FormLabelProps
} from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import BaseFormHelperText from '../BaseFormHelperText'
import { CheckCircleIcon, ErrorIcon } from '../../../icons'
import { random } from 'lodash'

type Props = {
  label?: React.ReactNode
  disabled?: boolean
  progressIcon?: boolean
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  name?: string
  placeholder?: string
  helperText?: string
  errorMessage?: string
  wrapperProps?: StackProps
  labelProps?: FormLabelProps
} & Omit<InputProps, 'isDisabled'>

const BaseInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    label,
    disabled = false,
    progressIcon,
    name,
    placeholder,
    helperText,
    errorMessage,
    onChange,
    value,
    onBlur,
    iconLeft,
    iconRight,
    wrapperProps,
    labelProps,
    ...rest
  } = props

  return (
    <FormControl isInvalid={props.isInvalid} isRequired={props.isRequired}>
      {label && (
        <FormLabel
          htmlFor={name}
          {...labelProps}
          requiredIndicator={React.isValidElement(label) ? <></> : undefined}
        >
          {label}
        </FormLabel>
      )}
      <Stack direction="row" {...wrapperProps}>
        {iconLeft}
        <Input
          {...rest}
          disabled={disabled}
          placeholder={placeholder}
          id={name}
          name={name}
          ref={ref}
          onChange={onChange}
          value={value ?? ''}
        />
        {iconRight}
        {progressIcon && (
          <Center>
            {1 === random(1, false) ? (
              <Tooltip
                label="This field is required"
                aria-label="This field is required"
              >
                <ErrorIcon width="30px" height="30px" fill="#FBD38D" />
              </Tooltip>
            ) : (
              <CheckCircleIcon width="30px" height="30px" fill="#1FA53C" />
            )}
          </Center>
        )}
      </Stack>
      {helperText && (
        <BaseFormHelperText invalid={props.isInvalid}>
          {helperText}
        </BaseFormHelperText>
      )}
    </FormControl>
  )
})

export default BaseInput
