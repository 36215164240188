import { CargoNameListRow } from '../../../../api/cargoSelfServiceAPI'
import React from 'react'
import css from './Confirmation.module.css'
import { Box, HStack, Stack } from '@chakra-ui/react'
import { MdFace } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

type DriverListItemProps = {
  driver: CargoNameListRow
  editable: boolean
  index: number
}

const DriverListItem: React.FC<DriverListItemProps> = ({ driver, index: i }) => {
  const {
    firstName,
    lastName,
    genderCode,
    nationalityExtCode,
    dateOfBirth,
    mobile,
    email
  } = driver

  const { t } = useTranslation()

  return (
    <div className={css.box}>
      <Stack width="100%">
        <HStack>
          <Box marginRight="auto">
            <>{t('driver_n', { value: i + 1 })}</>: {firstName} {lastName}
            {genderCode && ' / ' + genderCode}
            {nationalityExtCode && ' / ' + nationalityExtCode}
            {dateOfBirth && ' / ' + dateOfBirth}
            {mobile && ' / ' + mobile}
            {email && ' / ' + email}
          </Box>
          <MdFace />
        </HStack>
      </Stack>
    </div>
  )
}

export default DriverListItem
