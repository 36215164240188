import classNames from 'classnames'
import React from 'react'

import css from './Button.module.css'

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  inverted?: boolean
  grayScale?: boolean
}

const Button: React.FC<Props> = ({
  children,
  inverted,
  className,
  grayScale,
  disabled,
  ...props
}) => {
  return (
    <button
      disabled={disabled}
      className={classNames(css.button, className, {
        [css.inverted]: inverted,
        [css.grayScale]: grayScale,
        [css.withIcon]: children && Array.isArray(children) && children.length > 1,
        [css.disabled]: disabled
      })}
      {...props}
    >
      <span className={css.label}>{children}</span>
    </button>
  )
}

export default Button
