import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

import Arrow from '../Arrow/Arrow'
import css from './Container.module.css'

type Props = PropsWithChildren<{
  title?: string | JSX.Element
  icon?: any
  isActive?: boolean
  full?: boolean
  auto?: boolean
  fill?: boolean
  additionalHeader?: JSX.Element
  className?: string
  onClick?: any
  disabled?: boolean
  headerClass?: string
}>

type State = {
  height: string | undefined
  isActive: boolean
  onClick: any
}

class Container extends React.Component<Props, State> {
  probeRef = React.createRef<HTMLDivElement>()
  observer?: MutationObserver

  constructor(props: Props) {
    super(props)
    this.state = {
      height: undefined,
      isActive: this.props.isActive || false,
      onClick: undefined
    }
  }

  setContainerHeight = () => {
    const node = this.probeRef.current!
    if (!node || !node.clientHeight) {
      return
    }
    if (this.probeRef.current! && this.state.height !== node.clientHeight + 'px') {
      this.setState({ height: node.clientHeight + 'px' })
    }
  }

  setOnClick = () => {
    const onClick = this.resolveOnClick()
    this.setState({ onClick })
  }

  componentDidMount() {
    if (!this.probeRef.current) {
      return
    }
    this.observer = new MutationObserver(this.setContainerHeight)
    this.observer.observe(this.probeRef.current, {
      childList: true,
      subtree: true
    })
    this.setOnClick()
    window.addEventListener('resize', this.setContainerHeight)
    window.addEventListener('transitionend', this.setContainerHeight)
  }

  componentWillUnmount() {
    if (!this.observer) {
      return
    }
    this.observer.disconnect()
    window.removeEventListener('resize', this.setContainerHeight)
    window.removeEventListener('transitionend', this.setContainerHeight)
  }

  resolveOnClick = () => {
    const { onClick, auto } = this.props
    if (auto) {
      return () => {
        const { isActive } = this.state
        this.setState({ isActive: !isActive })
      }
    } else if (onClick) {
      return onClick
    }
    return undefined
  }

  isActive = (): boolean => {
    const { auto } = this.props
    if (auto) {
      // Container handles state independently
      return this.state.isActive
    } else if (this.props.isActive) {
      // Uses parent state
      return this.props.isActive
    } else if (!this.state.onClick) {
      // Static containers are always open
      return true
    }
    return false
  }

  componentDidUpdate() {
    // Re-update height if needed
    this.setContainerHeight()
  }

  render() {
    const {
      children,
      icon,
      title,
      full,
      fill,
      className,
      additionalHeader,
      disabled,
      headerClass
    } = this.props
    const { onClick } = this.state
    const isActive = this.isActive()
    const style = isActive ? { height: this.state.height } : { height: '0' }
    return (
      <div
        className={classNames(
          css.container,
          {
            [css.full]: full,
            [css.fill]: fill
          },
          className
        )}
      >
        <div className={css.component}>
          {title && (
            <div
              className={classNames(headerClass, css.header, {
                [css.open]: isActive,
                [css.canExpand]: !onClick,
                [css.disabled]: disabled
              })}
              onClick={!disabled ? onClick : undefined}
            >
              <div className={css.title}>
                {icon && <span className={css.icon}>{icon}</span>}
                {typeof title === 'string' ? (
                  <span className={css.route}>{title}</span>
                ) : (
                  title
                )}
              </div>
              {onClick && <Arrow active={isActive} className={css.arrow} />}
              {additionalHeader && (
                <div className={css.additionalHeader}>{additionalHeader}</div>
              )}
            </div>
          )}
          {children && (
            <div
              style={style}
              className={classNames(css.content, {
                [css.open]: isActive,
                [css.disabled]: disabled
              })}
            >
              <div ref={this.probeRef}>{children}</div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Container
