import { observer } from 'mobx-react'
import React from 'react'
import { FaTruck } from 'react-icons/fa'
import { useFormContext } from 'react-hook-form'
import { MdEvent, MdHotel, MdRestaurant, MdRoomService } from 'react-icons/md'

import Alert from '../../../../components/Alert/Alert'
import Button from '../../../../components/Button/Button'
import { Action, ExtraService } from '../../../../services/types'
import ErrorStore, { ErrorLevel } from '../../../../stores/ErrorStore'
import reservationStore from '../../../../stores/ReservationStore'
import { EDIT } from '../../../../utils/constants'
import { getDate, getTime } from '../../../../utils/helpers'
import css from './Confirmation.module.css'
import ReservationComponentProps from '../../ReservationComponentProps'
import DriverListItem from './DriverListItem'
import SendLinkToDriver from './SendLinkToDriver'
import { ReservationFormValues } from '../../ReservationContainer'
import { CargoNameListRow } from '../../../../api/driverUpdateAPI'
import UserStore from '../../../../stores/UserStore'
import FinalPrice from '../../../occasionalCustomer/Reservation/FinalPrice'
import { useTranslation } from 'react-i18next'
import userStore from '../../../../stores/UserStore'

const Confirmation = observer((props: ReservationComponentProps) => {
  const { onMakeReservation, action } = props
  const { t } = useTranslation()

  const {
    cabins,
    meals,
    services,
    powerConnectionRequired,
    booking,
    selectedDeparture: {
      arrivalPortExtCode,
      departurePortExtCode,
      departureDatetime = booking?.cargoJourneys?.[0].departureTime
    },
    vehicleTemplateName,
    findPortNameByCode,
    isEditable
  } = reservationStore

  const {
    watch,
    formState: { isValid }
  } = useFormContext<ReservationFormValues>()

  const canEditBooking = action !== Action.Edit || isEditable

  const formDrivers: CargoNameListRow[] = watch('drivers') || []
  const driverList = formDrivers.map((driver, i) => (
    <DriverListItem key={i} driver={driver} index={i} editable={canEditBooking} />
  ))

  /**
   * @param {ExtraService[]} serviceArray - Array of services
   * @param {string} title - Row title
   * @param {JSX.Element} icon - Row icon
   */
  const mapServices = (
    serviceArray: ExtraService[],
    title: string,
    icon: JSX.Element
  ) =>
    serviceArray
      .filter((service) => Number(service.quantity) > 0)
      .map((service, i) => {
        const { categoryNames, quantity } = service
        return (
          <div className={css.box} key={i}>
            {title} {i + 1}: {categoryNames!.displayName} / {quantity}
            {icon}
          </div>
        )
      })

  const cabinList = mapServices(cabins, t('cabin'), <MdHotel />)
  const serviceList = mapServices(services, t('other_services'), <MdRoomService />)
  const mealList = mapServices(meals, t('meals'), <MdRestaurant />)

  const [phone, email, vehicleRegistrationNumber, trailerRegistrationNumber] = watch(
    ['phone', 'email', 'vehicleRegistrationNumber', 'trailerRegistrationNumber']
  )

  const shouldSend = phone || email
  const cargoJourney = booking?.cargoJourneys?.[0]

  const computedDeparturePortExtCode =
    departurePortExtCode || cargoJourney?.departurePortExtCode
  const computedArrivalPortExtCode =
    arrivalPortExtCode || cargoJourney?.arrivalPortExtCode

  return (
    <div className={css.componentBodyWrapper}>
      <div className={css.summary}>
        <div className={css.summaryHeader}>
          <div className={css.title}>Reservation summary</div>
          <div className={css.line} />
          <div className={css.flexBetween}>
            {findPortNameByCode(computedDeparturePortExtCode) || ''}
            &nbsp;-&nbsp;{findPortNameByCode(computedArrivalPortExtCode) || ''}
            {departureDatetime &&
              ` / ${getDate(departureDatetime)} / ${getTime(departureDatetime)}`}
            {vehicleRegistrationNumber && ' / ' + vehicleRegistrationNumber}
            {trailerRegistrationNumber && ' / ' + trailerRegistrationNumber}
            <MdEvent />
          </div>
        </div>
        {vehicleTemplateName && (
          <div className={css.box}>
            {vehicleTemplateName}
            <FaTruck />
          </div>
        )}
        {powerConnectionRequired && <div className={css.box}>Power connection</div>}
        {driverList}
        {cabinList}
        {mealList}
        {serviceList}
        <div className={css.box}>
          <SendLinkToDriver drivers={formDrivers} />
          {UserStore.isOccasional && <FinalPrice />}
        </div>
      </div>
      <div className={css.flexWrapper}>
        <Alert className={css.alert} level={ErrorLevel.warning}>
          {t('edit_help_message')}
        </Alert>
        {UserStore.isOccasional ? (
          canEditBooking && (
            <Button
              onClick={onMakeReservation}
              disabled={!isValid || ErrorStore.isLoading}
            >
              {t(
                shouldSend ? 'make_reservation_and_send_login' : 'make_reservation'
              )}
            </Button>
          )
        ) : (
          <Button onClick={onMakeReservation} disabled={ErrorStore.isLoading}>
            {t(
              action === Action.Edit
                ? shouldSend
                  ? 'save_changes_and_send_login'
                  : 'save_changes'
                : shouldSend
                ? 'make_reservation_and_send_login'
                : 'make_reservation'
            )}
          </Button>
        )}
      </div>
    </div>
  )
})

export default Confirmation
