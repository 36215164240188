import React, { useEffect, useState, useRef, RefObject } from 'react'
import { createRoot } from 'react-dom/client'
import ReservationStore from '../../../stores/ReservationStore'

import { InitiateExtEcommercePaymentResponse } from '../../../api/externalPaymentsAPI'
import { observer } from 'mobx-react'
import { getQueryParams } from '../../../utils/helpers'
import { Text } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

const renderPaymentForm = (
  paymentData: InitiateExtEcommercePaymentResponse,
  formRef: RefObject<HTMLFormElement>
) => {
  return new Promise<void>((resolve) => {
    const form = (
      <form ref={formRef} action={paymentData?.postRedirect?.url}>
        {paymentData?.postRedirect?.parameters.map((param, i) => (
          <input
            key={i}
            type="text"
            name={param.name}
            value={param.value}
            readOnly
            hidden
          />
        ))}
      </form>
    )

    const root = createRoot(
      document.getElementById('paymentFormContainer') as HTMLElement
    )
    root.render(form)
    resolve()
  })
}

const Payment: React.FC = observer(() => {
  const location = useLocation()
  const formRef = useRef<HTMLFormElement>(null)
  const [paymentData, setPaymentData] =
    useState<InitiateExtEcommercePaymentResponse | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const startPayment = async () => {
      const bookingCode = getQueryParams(location.search, 'bookingCode')

      if (bookingCode) {
        const [data, error] = await ReservationStore.startPayment(bookingCode)

        if (data) {
          setPaymentData(data)
        } else if (error) {
          setError(error)
        }
      }
    }

    startPayment().catch(console.error)
  }, [location])

  useEffect(() => {
    const renderFormAndSubmit = async () => {
      if (paymentData) {
        await renderPaymentForm(paymentData, formRef)

        if (formRef) {
          formRef.current?.submit()
        }
      }
    }

    renderFormAndSubmit().catch(console.error)
  }, [paymentData])

  if (error) {
    return <Text>{error}</Text>
  }
  return <div id="paymentFormContainer" />
})

export default Payment
