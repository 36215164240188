import React from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

interface Props extends React.SVGAttributes<SVGElement> {
  active: boolean
}

const Arrow: React.FC<Props> = ({ active, ...props }) =>
  active ? <MdKeyboardArrowUp {...props} /> : <MdKeyboardArrowDown {...props} />

export default Arrow
