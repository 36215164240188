import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import userStore from '../../../../stores/UserStore'
import { calculateVehicleMaxLength } from '../../../../utils/bookingHelpers'
import reservationStore from '../../../../stores/ReservationStore'
import { toNumber } from '../../../../utils/helpers'
import { ReservationFormValues } from '../../ReservationContainer'
import FormInput from '../../../../components/FormControlled/FormInput'
import BaseFormLabel from '../../../../components/Form/BaseFormLabel'
import { ErrorIcon } from '../../../../icons'

const VehicleLengthField = observer(() => {
  const { control, watch, trigger } = useFormContext<ReservationFormValues>()
  const { booking, selectedDeparture, findVehicleTemplateByCode } = reservationStore
  const vehicleTemplate = findVehicleTemplateByCode(
    watch('vehicleTemplateCategoryExtCode')
  )
  const { t } = useTranslation()

  const { isOccasional } = userStore
  const maxLength = calculateVehicleMaxLength({
    vehicleTemplate,
    booking,
    selectedDeparture,
    isOccasional
  })

  const onChange = (value: string) => toNumber(value, 0, maxLength)

  const minLength = vehicleTemplate?.defaults?.lengthDefaults?.minValue || 0
  const minLengthText = `Minimal length for this vehicle category is: ${minLength}m`
  const maxLengthText = `Maximal available length for this vehicle category is: ${maxLength}m`

  const tooltipText = !maxLength
    ? t('no_departures_selected')
    : !vehicleTemplate
    ? 'Please choose the vehicle category first'
    : maxLengthText + '. ' + minLengthText

  useEffect(() => {
    if (maxLength) {
      trigger('vehicleLength')
    }
  }, [maxLength, vehicleTemplate])

  return (
    <FormInput
      name="vehicleLength"
      label={
        <BaseFormLabel
          tooltipText={tooltipText}
          icon={ErrorIcon}
          label={t('length_m')}
        />
      }
      control={control}
      rules={{
        required: true,
        max: { message: maxLengthText, value: maxLength },
        min: { message: minLengthText, value: minLength }
      }}
      setValueAs={onChange}
    />
  )
})

export default VehicleLengthField
