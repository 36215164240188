import React from 'react'
import { MdAddCircle, MdRemoveCircle } from 'react-icons/md'

import css from './Amount.module.css'

interface Props extends React.HTMLProps<HTMLDivElement> {
  value: number
  handleChange: (value: number) => void
}

const Amount: React.FC<Props> = ({ value, handleChange, ...rest }) => (
  <div className={css.wrapper} {...rest}>
    <MdRemoveCircle
      className={css.decreaseIcon}
      onClick={() => handleChange(value - 1)}
    />
    <div className={css.value}>{value}</div>
    <MdAddCircle
      className={css.increaseIcon}
      onClick={() => handleChange(value + 1)}
    />
  </div>
)

export default Amount
