/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Booked passenger and cargo space per booking row status. This only covers booking rows with a booking status set, not for ordinary (null status) booking rows. */
export interface BookedSpacePerBookingRowStatus {
  /**
   * booking row status code
   * @minLength 0
   * @maxLength 5
   */
  bookingRowStatusCode?: string
  /** Information about available space */
  bookedPassengerSpace?: CardeckSpace
  /** Information about available space */
  bookedCargoSpace?: CardeckSpace
}

/** Information about available space */
export interface CardeckSpace {
  /** Low available space */
  low: number
  /** Medium available space */
  medium: number
  /** High available space */
  high: number
}

export interface CollectionLinks {
  self?: Link
  first?: Link
  next?: Link
  prev?: Link
  last?: Link
}

export interface CollectionMeta {
  /**
   * Number of objects returned in collection
   * @format int64
   */
  totalCount?: number
  /**
   * Current count of objects returned
   * @format int32
   */
  currentCount?: number
  /**
   * Number of items per page
   * @format int32
   */
  pageSize?: number
  /**
   * Number of pages returned in collection
   * @format int32
   */
  totalPages?: number
  /**
   * Current page number
   * @format int32
   */
  currentPage?: number
}

export interface Link {
  /** Full url. */
  href?: string
}

export interface PortDepartureInformationResponse {
  /**
   * Port code
   * @minLength 0
   * @maxLength 5
   */
  departurePortCode: string
  /**
   * Port code
   * @minLength 0
   * @maxLength 5
   */
  arrivalPortCode: string
  /**
   * The scheduled departure date and time
   * @format date-time
   */
  departureDate: string
  /**
   * The scheduled arrival date and time
   * @format date-time
   */
  arrivalDate: string
  /**
   * vessel code used in carres
   * @minLength 0
   * @maxLength 10
   */
  vesselCode: string
  /**
   * Booked passengers
   * @format int32
   */
  passengerCountBooked: number
  /**
   * Available passengers
   * @format int32
   */
  passengerCountAvailable: number
  /** Information about available space */
  availablePassengerSpace: CardeckSpace
  /** Information about available space */
  bookedPassengerSpace: CardeckSpace
  /** Information about available space */
  availableCargoSpace: CardeckSpace
  /** Information about available space */
  bookedCargoSpace: CardeckSpace
  /** Information about available space */
  availableProtectedSpace: CardeckSpace
  /** Information about available space */
  bookedProtectedSpace: CardeckSpace
  /** Information about available space */
  initialProtectedSpace: CardeckSpace
  /** Information about available space */
  totalAvailableVehicleLength: CardeckSpace
  /** Information about available space */
  totalBookedVehicleLength: CardeckSpace
  /** A collection of booked passenger and cargo spaces per booking row status */
  bookedSpacePerBookingRowStatus: BookedSpacePerBookingRowStatus[]
}

/** A collection of Cargo Departures Information */
export interface PortDepartureInformationResponseCollection {
  _links?: CollectionLinks
  _meta?: CollectionMeta
  departures?: PortDepartureInformationResponse[]
}

export interface Error400 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error400RequiredAction
}

export interface Error401 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error401RequiredAction
}

export interface Error403 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error403RequiredAction
  fields?: string[]
  resourceReference?: ResourceReference
}

export interface ResourceIdentifier {
  identifierType: ResourceIdentifierIdentifierType
  /**
   * @minLength 1
   * @maxLength 1000
   */
  value: string
}

export interface ResourceReference {
  resourceType: ResourceReferenceResourceType
  /**
   * @maxItems 2147483647
   * @minItems 1
   */
  identifiers: ResourceIdentifier[]
}

export interface Error406 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error406RequiredAction
}

export interface Error429 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error429RequiredAction
}

/** Totals for departure leg */
export interface DepartureLegTotalsResponse {
  passengerCount: DepartureLegTotalsResponsePassengerCount
  vehicleCount: DepartureLegTotalsResponseVehicleCount
  /** Total lengths of booked vehicles */
  lengthOfBookedVehicles: DepartureLegTotalsResponseLengthOfBookedVehicles
  /** Total lengths of checked in vehicles */
  lengthOfCheckedInVehicles: DepartureLegTotalsResponseLengthOfCheckedInVehicles
}

export interface DepartureLegTotalsResponsePassengerCount {
  /**
   * @format int32
   * @min 0
   * @max 999999999
   */
  booked: number
  /**
   * @format int32
   * @min 0
   * @max 999999999
   */
  checkedIn: number
  /**
   * @format int32
   * @min 0
   * @max 999999999
   */
  boarded: number
  /**
   * @format int32
   * @min 0
   * @max 999999999
   */
  disembarked: number
}

export interface DepartureLegTotalsResponseVehicleCount {
  /**
   * @format int32
   * @min 0
   * @max 999999999
   */
  booked: number
  /**
   * @format int32
   * @min 0
   * @max 999999999
   */
  checkedIn: number
}

/** Total lengths of booked vehicles */
export interface DepartureLegTotalsResponseLengthOfBookedVehicles {
  /**
   * total length of low vehicles
   * @min 0
   * @exclusiveMin false
   */
  low: number
  /**
   * total length of medium vehicles
   * @min 0
   * @exclusiveMin false
   */
  medium: number
  /**
   * total length of high vehicles
   * @min 0
   * @exclusiveMin false
   */
  high: number
}

/** Total lengths of checked in vehicles */
export interface DepartureLegTotalsResponseLengthOfCheckedInVehicles {
  /**
   * total length of low vehicles
   * @min 0
   * @exclusiveMin false
   */
  low: number
  /**
   * total length of medium vehicles
   * @min 0
   * @exclusiveMin false
   */
  medium: number
  /**
   * total length of high vehicles
   * @min 0
   * @exclusiveMin false
   */
  high: number
}

export interface Error404 {
  debugErrorCode?: string
  debugMessage?: string
  /** @format int32 */
  httpStatusCode?: number
  transactionId?: string
  requiredAction?: Error404RequiredAction
  fields?: string[]
  resourceReference?: ResourceReference
}

export enum Error400RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error401RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error403RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum ResourceIdentifierIdentifierType {
  ACCOUNTING_JOB_NUMBER = 'ACCOUNTING_JOB_NUMBER',
  ACCOUNTING_SYSTEM_CODE = 'ACCOUNTING_SYSTEM_CODE',
  ACTOR_CODE = 'ACTOR_CODE',
  AGENT_CODE = 'AGENT_CODE',
  APISYS_CODE = 'APISYS_CODE',
  APP_CODE = 'APP_CODE',
  APP_INSTANCE_CODE = 'APP_INSTANCE_CODE',
  APP_INSTANCE_LOCALE_CODE = 'APP_INSTANCE_LOCALE_CODE',
  AUTOMAT_CODE = 'AUTOMAT_CODE',
  BOOKING_CODE = 'BOOKING_CODE',
  BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP_CODE = 'BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP_CODE',
  BOOKING_ROW_ITEM_REFERENCE = 'BOOKING_ROW_ITEM_REFERENCE',
  BOOKING_VERSION_ID = 'BOOKING_VERSION_ID',
  BOOKING_VERSION_NUMBER = 'BOOKING_VERSION_NUMBER',
  BROCHURE_CODE = 'BROCHURE_CODE',
  CARGO_TYPE_CODE = 'CARGO_TYPE_CODE',
  CATEGORY_CODE = 'CATEGORY_CODE',
  CATEGORY_GROUP_CODE = 'CATEGORY_GROUP_CODE',
  CATEGORY_STRUCT_GROUP_CODE = 'CATEGORY_STRUCT_GROUP_CODE',
  CLUB_CODE = 'CLUB_CODE',
  CLUB_LEVEL = 'CLUB_LEVEL',
  CONSIGNEE_CODE = 'CONSIGNEE_CODE',
  COUNTRY_CODE = 'COUNTRY_CODE',
  COUNTRY_EXT_CODE = 'COUNTRY_EXT_CODE',
  CURRENCY_CODE = 'CURRENCY_CODE',
  CUSTOMER_CODE = 'CUSTOMER_CODE',
  CUSTOMER_PRIVILEGE_GROUP_CODE = 'CUSTOMER_PRIVILEGE_GROUP_CODE',
  CUSTOMER_SUB_LOGIN_CODE = 'CUSTOMER_SUB_LOGIN_CODE',
  DEFAULT_ARRIVAL_PORT_CODE = 'DEFAULT_ARRIVAL_PORT_CODE',
  DEPARTURE_CODE = 'DEPARTURE_CODE',
  EMAIL_TEMPLATE_CODE = 'EMAIL_TEMPLATE_CODE',
  EINVOICE_TYPE_CODE = 'EINVOICE_TYPE_CODE',
  ETICKET_CODE = 'ETICKET_CODE',
  ETICKET_TYPE_CODE = 'ETICKET_TYPE_CODE',
  EXTERNAL_ACCESS_REFERENCE_NUMBER = 'EXTERNAL_ACCESS_REFERENCE_NUMBER',
  EXTERNAL_ACCESS_REFERENCE_TYPE = 'EXTERNAL_ACCESS_REFERENCE_TYPE',
  EXTERNAL_ACCESS_RESOURCE_CODE = 'EXTERNAL_ACCESS_RESOURCE_CODE',
  EXTERNAL_ACCESS_RESOURCE_GRANT_ACCESS_ID = 'EXTERNAL_ACCESS_RESOURCE_GRANT_ACCESS_ID',
  EXTERNAL_ACCESS_RESOURCE_TYPE = 'EXTERNAL_ACCESS_RESOURCE_TYPE',
  GUEST_TYPE_CODE = 'GUEST_TYPE_CODE',
  INVOICING_INTERVAL_CODE = 'INVOICING_INTERVAL_CODE',
  INVOICING_JOB_NUMBER = 'INVOICING_JOB_NUMBER',
  JOURNEY_CODE = 'JOURNEY_CODE',
  JOURNEY_GROUP_CODE = 'JOURNEY_GROUP_CODE',
  JOURNEY_NAME_LIST_REFERENCE = 'JOURNEY_NAME_LIST_REFERENCE',
  LANGUAGE_CODE = 'LANGUAGE_CODE',
  METHOD_OF_PAYMENT_CODE = 'METHOD_OF_PAYMENT_CODE',
  MOBILE_DEVICE_CODE = 'MOBILE_DEVICE_CODE',
  MOBILE_KEY_CODE = 'MOBILE_KEY_CODE',
  MOBILE_KEY_LOCK_TYPE = 'MOBILE_KEY_LOCK_TYPE',
  NAME_LIST_ROW_NUMBER = 'NAME_LIST_ROW_NUMBER',
  NATIONALITY_CODE = 'NATIONALITY_CODE',
  PORT_CODE = 'PORT_CODE',
  PORT_EXT_CODE = 'PORT_EXT_CODE',
  PRODUCT_CODE = 'PRODUCT_CODE',
  PAYMENT_REFERENCE = 'PAYMENT_REFERENCE',
  RECRUITMENT_METHOD_CODE = 'RECRUITMENT_METHOD_CODE',
  SHIPPER_CODE = 'SHIPPER_CODE',
  SMS_TEMPLATE_CODE = 'SMS_TEMPLATE_CODE',
  STATE_CODE = 'STATE_CODE',
  TAX_OFFICE_CODE = 'TAX_OFFICE_CODE',
  TERMS_AND_CONDITIONS_TEXT_GROUP_CODE = 'TERMS_AND_CONDITIONS_TEXT_GROUP_CODE',
  TERMS_OF_PAYMENT_CODE = 'TERMS_OF_PAYMENT_CODE',
  TERMINAL_CODE = 'TERMINAL_CODE',
  TITLE_CODE = 'TITLE_CODE',
  TRANSACTION_NUMBER = 'TRANSACTION_NUMBER',
  TRAVEL_DOCUMENT_TYPE_CODE = 'TRAVEL_DOCUMENT_TYPE_CODE',
  VEHICLE_MAKE_MODEL_CODE = 'VEHICLE_MAKE_MODEL_CODE',
  VOUCHER_CODE = 'VOUCHER_CODE',
  UNKNOWN = 'UNKNOWN'
}

export enum ResourceReferenceResourceType {
  ACCOUNTING_JOB = 'ACCOUNTING_JOB',
  ACCOUNTING_SYSTEM = 'ACCOUNTING_SYSTEM',
  ACTOR = 'ACTOR',
  AGENT = 'AGENT',
  APISYS = 'APISYS',
  APP = 'APP',
  APP_INSTANCE = 'APP_INSTANCE',
  APP_INSTANCE_LOCALE = 'APP_INSTANCE_LOCALE',
  AUTOMAT = 'AUTOMAT',
  BOOKING = 'BOOKING',
  BOOKING_ROW_ITEM = 'BOOKING_ROW_ITEM',
  BOOKING_NAME_LIST_ROW = 'BOOKING_NAME_LIST_ROW',
  BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP = 'BOOKING_NAME_LIST_ROW_MOBILE_DEVICE_OTP',
  BOOKING_VERSION = 'BOOKING_VERSION',
  BROCHURE_PRODUCT = 'BROCHURE_PRODUCT',
  CARGO_TYPE = 'CARGO_TYPE',
  CATEGORY_STRUCT = 'CATEGORY_STRUCT',
  CATEGORY_STRUCT_GROUP = 'CATEGORY_STRUCT_GROUP',
  CLUB = 'CLUB',
  COUNTRY = 'COUNTRY',
  CURRENCY = 'CURRENCY',
  CUSTOMER = 'CUSTOMER',
  CUSTOMER_PRIVILEGE_GROUP = 'CUSTOMER_PRIVILEGE_GROUP',
  CUSTOMER_SUB_LOGIN = 'CUSTOMER_SUB_LOGIN',
  DEPARTURE = 'DEPARTURE',
  EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
  EINVOICE = 'EINVOICE',
  ETICKET = 'ETICKET',
  EXTERNAL_ACCESS_REFERENCE = 'EXTERNAL_ACCESS_REFERENCE',
  EXTERNAL_ACCESS_RESOURCE = 'EXTERNAL_ACCESS_RESOURCE',
  EXTERNAL_ACCESS_RESOURCE_GRANT = 'EXTERNAL_ACCESS_RESOURCE_GRANT',
  GUEST_TYPE = 'GUEST_TYPE',
  INVOICING_INTERVAL = 'INVOICING_INTERVAL',
  INVOICING_JOB = 'INVOICING_JOB',
  JOURNEY = 'JOURNEY',
  JOURNEY_GROUP = 'JOURNEY_GROUP',
  JOURNEY_NAME_LIST_REFERENCE = 'JOURNEY_NAME_LIST_REFERENCE',
  LANGUAGE = 'LANGUAGE',
  METHOD_OF_PAYMENT = 'METHOD_OF_PAYMENT',
  MOBILE_DEVICE = 'MOBILE_DEVICE',
  MOBILE_KEY = 'MOBILE_KEY',
  MOBILE_KEY_LOCK_TYPE = 'MOBILE_KEY_LOCK_TYPE',
  PAYMENT = 'PAYMENT',
  PORT = 'PORT',
  RECRUITMENT_METHOD = 'RECRUITMENT_METHOD',
  SMS_TEMPLATE = 'SMS_TEMPLATE',
  STATE = 'STATE',
  TAX_OFFICE = 'TAX_OFFICE',
  TERMS_AND_CONDITIONS_TEXT_GROUP = 'TERMS_AND_CONDITIONS_TEXT_GROUP',
  TERMS_OF_PAYMENT = 'TERMS_OF_PAYMENT',
  TERMINAL = 'TERMINAL',
  TITLE = 'TITLE',
  TRAVEL_DOCUMENT = 'TRAVEL_DOCUMENT',
  VEHICLE_MAKE_MODEL = 'VEHICLE_MAKE_MODEL',
  VOUCHER = 'VOUCHER',
  UNKNOWN = 'UNKNOWN'
}

export enum Error406RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error429RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export enum Error404RequiredAction {
  CHANGE_PASSWORD = 'CHANGE_PASSWORD'
}

export type GetCargoDeparturesData = PortDepartureInformationResponseCollection

export type GetCargoDeparturesAndArrivalsData =
  PortDepartureInformationResponseCollection

export type GetCargoArrivalsData = PortDepartureInformationResponseCollection

export type GetDepartureLegTotalsData = DepartureLegTotalsResponse

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType
} from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL:
        axiosConfig.baseURL ||
        'https://api-test-rec.carus.com/departure-info/rectest/v1'
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] =
        property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        )
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    })
  }
}

/**
 * @title Port Departure Information API
 * @version 11.0.0
 * @license Carus License (https://www.carus.com)
 * @termsOfService https://www.carus.com
 * @baseUrl https://api-test-rec.carus.com/departure-info/rectest/v1
 * @contact Carus <support@carus.com> (https://www.carus.com)
 *
 * Methods to search departure information for ports
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  departureInfo = {
    /**
     * No description
     *
     * @tags PortDepartureInformation
     * @name GetCargoDepartures
     * @summary Get list of all departures for specified port
     * @request GET:/departure-info/v1/ports/{portCode}/departures
     * @secure
     * @response `200` `GetCargoDeparturesData` List of departures
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoDepartures: (
      portCode: string,
      query: {
        /**
         * Limit the search after (or equal to) this date (fromDate together with fromHours and fromMins provide the date-time to search from)
         * @format date
         */
        fromDate: string
        /**
         * Limit the search before (or equal to) this date (toDate together with toHours and toMins provide the date-time to search to)
         * @format date
         */
        toDate: string
        /** Limit the search by the code of vessel */
        vesselCode?: string
        /**
         * Limit the search after (or equal to) this hour of the day on the date specified (fromDate together with fromHours and fromMins provide the date-time to search from). I.e. specifies the hours part of the time of day for the start of the date range.
         * @format int32
         */
        fromHours?: number
        /**
         * Limit the search after (or equal to) this many minutes past the hour of the day on the date specified (fromDate together with fromHours and fromMins provide the date-time to search from) I.e. specifies the minutes part of the time of day for the start of the date range.
         * @format int32
         */
        fromMins?: number
        /**
         * Limit the search before (or equal to) this hour of the day on the date specified (toDate together with toHours and toMins provide the date-time to search to) I.e. specifies the hours part of the time of day for the end of the date range.
         * @format int32
         */
        toHours?: number
        /**
         * Limit the search before (or equal to) this many minutes past the hour of the day on the date specified (toDate together with toHours and toMins provide the date-time to search to) I.e. specifies the minutes part of the time of day for the end of the date range.
         * @format int32
         */
        toMins?: number
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetCargoDeparturesData,
        Error400 | Error401 | Error403 | Error406 | Error429
      >({
        path: `/departure-info/v1/ports/${portCode}/departures`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags PortDepartureInformation
     * @name GetCargoDeparturesAndArrivals
     * @summary Get list of all departures and arrivals for specified port
     * @request GET:/departure-info/v1/ports/{portCode}/departures-and-arrivals
     * @secure
     * @response `200` `GetCargoDeparturesAndArrivalsData` List of departures and arrivals
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoDeparturesAndArrivals: (
      portCode: string,
      query: {
        /**
         * Limit the search after (or equal to) this date (fromDate together with fromHours and fromMins provide the date-time to search from)
         * @format date
         */
        fromDate: string
        /**
         * Limit the search before (or equal to) this date (toDate together with toHours and toMins provide the date-time to search to)
         * @format date
         */
        toDate: string
        /** Limit the search by the code of vessel */
        vesselCode?: string
        /**
         * Limit the search after (or equal to) this hour of the day on the date specified (fromDate together with fromHours and fromMins provide the date-time to search from). I.e. specifies the hours part of the time of day for the start of the date range.
         * @format int32
         */
        fromHours?: number
        /**
         * Limit the search after (or equal to) this many minutes past the hour of the day on the date specified (fromDate together with fromHours and fromMins provide the date-time to search from) I.e. specifies the minutes part of the time of day for the start of the date range.
         * @format int32
         */
        fromMins?: number
        /**
         * Limit the search before (or equal to) this hour of the day on the date specified (toDate together with toHours and toMins provide the date-time to search to) I.e. specifies the hours part of the time of day for the end of the date range.
         * @format int32
         */
        toHours?: number
        /**
         * Limit the search before (or equal to) this many minutes past the hour of the day on the date specified (toDate together with toHours and toMins provide the date-time to search to) I.e. specifies the minutes part of the time of day for the end of the date range.
         * @format int32
         */
        toMins?: number
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetCargoDeparturesAndArrivalsData,
        Error400 | Error401 | Error403 | Error406 | Error429
      >({
        path: `/departure-info/v1/ports/${portCode}/departures-and-arrivals`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags PortDepartureInformation
     * @name GetCargoArrivals
     * @summary Get list of all arrivals for specified port
     * @request GET:/departure-info/v1/ports/{portCode}/arrivals
     * @secure
     * @response `200` `GetCargoArrivalsData` List of arrivals
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getCargoArrivals: (
      portCode: string,
      query: {
        /**
         * Limit the search after (or equal to) this date (fromDate together with fromHours and fromMins provide the date-time to search from)
         * @format date
         */
        fromDate: string
        /**
         * Limit the search before (or equal to) this date (toDate together with toHours and toMins provide the date-time to search to)
         * @format date
         */
        toDate: string
        /** Limit the search by the code of vessel */
        vesselCode?: string
        /**
         * Limit the search after (or equal to) this hour of the day on the date specified (fromDate together with fromHours and fromMins provide the date-time to search from). I.e. specifies the hours part of the time of day for the start of the date range.
         * @format int32
         */
        fromHours?: number
        /**
         * Limit the search after (or equal to) this many minutes past the hour of the day on the date specified (fromDate together with fromHours and fromMins provide the date-time to search from) I.e. specifies the minutes part of the time of day for the start of the date range.
         * @format int32
         */
        fromMins?: number
        /**
         * Limit the search before (or equal to) this hour of the day on the date specified (toDate together with toHours and toMins provide the date-time to search to) I.e. specifies the hours part of the time of day for the end of the date range.
         * @format int32
         */
        toHours?: number
        /**
         * Limit the search before (or equal to) this many minutes past the hour of the day on the date specified (toDate together with toHours and toMins provide the date-time to search to) I.e. specifies the minutes part of the time of day for the end of the date range.
         * @format int32
         */
        toMins?: number
        /**
         * Page size in a collection. Default value - 10
         * @format int32
         */
        pageSize?: number
        /**
         * Starting point for this page in a collection
         * @format int32
         */
        pageNum?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        GetCargoArrivalsData,
        Error400 | Error401 | Error403 | Error406 | Error429
      >({
        path: `/departure-info/v1/ports/${portCode}/arrivals`,
        method: 'GET',
        query: query,
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags DepartureLegTotals
     * @name GetDepartureLegTotals
     * @summary Get totals for specified departure leg.
     * @request GET:/departure-info/v1/departure-legs/departure-code/{departureCode}/totals
     * @secure
     * @response `200` `GetDepartureLegTotalsData` Totals
     * @response `400` `Error400` Cannot create object based on provided data.
     * @response `401` `Error401` Client is not authenticated.
     * @response `403` `Error403` Request is not allowed by authenticated client.
     * @response `404` `Error404` Returned if the departure leg is not found.
     * @response `406` `Error406` Content type provided by client in Accept-header is not allowed
     * @response `429` `Error429` Client has tried too many requests and exceeded rate-limits.
     */
    getDepartureLegTotals: (departureCode: string, params: RequestParams = {}) =>
      this.request<
        GetDepartureLegTotalsData,
        Error400 | Error401 | Error403 | Error404 | Error406 | Error429
      >({
        path: `/departure-info/v1/departure-legs/departure-code/${departureCode}/totals`,
        method: 'GET',
        secure: true,
        ...params
      })
  }
}
