import React from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import authStore from '../../stores/AuthStore'
import { useTranslation } from 'react-i18next'
import Login from './components/Login/Login'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

const LoginContainer: React.FC = observer(() => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  if (authStore.isAuthenticated) {
    navigate('/')
    return null
  }

  return (
    <>
      <Header title={t('login')} />
      <Login />
      <Footer />
    </>
  )
})

export default LoginContainer
