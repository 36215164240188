import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box
} from '@chakra-ui/react'
import { FaceIcon } from '../icons'
import DriverAndServices from '../views/reservation/components/DriverAndServices/DriverAndServices'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  toggleActive: (index: number) => void
  driverListInvalid: boolean
  action: string
  active: number
  onMakeReservation: () => Promise<void>
  isExpanded?: boolean
}

const DriverServiceAccordion: FunctionComponent<Props> = ({
  toggleActive,
  driverListInvalid,
  action,
  active,
  onMakeReservation,
  isExpanded = false
}) => {
  const handleToggle = () => {
    toggleActive(2)
  }

  const { t } = useTranslation()

  return (
    <>
      <AccordionButton
        onClick={handleToggle}
        bgColor={(isExpanded && 'baseCyan') || undefined}
      >
        <FaceIcon fill={(driverListInvalid && 'red') || undefined} />
        <Box
          flex="1"
          textAlign="left"
          ml={3}
          fontSize="md"
          py={2}
          color={(driverListInvalid && 'red') || undefined}
        >
          {t('driver_information_and_services')}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <DriverAndServices
          id={2}
          action={action}
          active={active === 2}
          onMakeReservation={onMakeReservation}
          toggleActive={toggleActive}
          isInvalid={driverListInvalid}
        />
      </AccordionPanel>
    </>
  )
}

export default DriverServiceAccordion
