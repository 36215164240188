import classNames from 'classnames'
import React from 'react'

import css from './Label.module.css'

interface Props extends React.HTMLProps<HTMLLabelElement> {
  vertical?: boolean
  slim?: boolean
  wide?: boolean
  quarter?: boolean
  full?: boolean
  half?: boolean
}

const Label: React.FC<Props> = ({
  vertical,
  wide,
  full,
  slim,
  quarter,
  children,
  className,
  ...props
}) => (
  <label
    {...props}
    className={classNames(className, css.component, {
      [css.vertical]: vertical,
      [css.slim]: slim,
      [css.wide]: wide,
      [css.full]: full,
      [css.quarter]: quarter
    })}
  >
    {children}
  </label>
)

export default Label
