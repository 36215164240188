export enum AllotmentStatus {
  full = 'full',
  available = 'available',
  empty = 'empty'
}

export enum ReservationSteps {
  departure = 0,
  cargo = 1,
  driver = 2,
  confirmation = 3,
  payment = 4
}

export const EDIT = 'edit'
export const CARGOWEB = 'FWEB_ELI'
export const DEFAULT_PRODUCT_CODE = 'ELI_CARGO'

export const ONE_WEEK_IN_MS = 604800000

export const emailPattern = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

export const strongPasswordPattern = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
)

export const regNumberPatternExp = /[^a-zA-ZåäöüßÅÄÖÜ0-9]+/g

export const vehicleRegNumberPatternExp = /[^a-zA-ZåäöüßÅÄÖÜ0-9-]+/g

// Matches this pattern +1234567890, + is optional
export const numberPatternExp = /^(\+)?[0-9]*$/

export const onlyLettersPatternExp = /^[a-zA-ZåäöüßÅÄÖÜ\s]*$/

export const cargoTypes = [
  'Empty',
  'General cargo',
  'Paper',
  'Steel',
  'Temperature controlled cargo',
  'Waste',
  'Wood'
]

export const countryCodeFI = 'FI'

export const categoryRestrictions = {
  ELI_CARGO2: ['MEALS', 'CABINS'],
  ELI_CARGO3: ['CABINS'],
  ELI_CARGO4: ['MEALS'],
  PRELIMINARY: ['MEALS', 'CABINS', 'SERVICES']
}

export const SEARCH_DATE_RANGE_FROM = 0
export const SEARCH_DATE_RANGE_TO = 30

export const paymentMethods = {
  // onlineBank: [
  //   { id: '50', src: '/aktia.svg', alt: 'aktia' },
  //   { id: '5', src: '/alandsbanken.svg', alt: 'alandsbanken' },
  //   { id: '3', src: '/danske-bank.svg', alt: 'danske-bank' },
  //   { id: '6', src: '/handelsbanken.svg', alt: 'handelsbanken' },
  //   { id: '1', src: '/nordea.svg', alt: 'nordea' },
  //   { id: '61', src: '/oma-saastopankki.svg', alt: 'oma-saastopankki' },
  //   { id: '2', src: '/osuuspankki.svg', alt: 'osuuspankki' },
  //   { id: '51', src: '/pop-pankki.svg', alt: 'pop-pankki' },
  //   { id: '10', src: '/s-pankki.svg', alt: 's-pankki' },
  //   { id: '52', src: '/saastopankki.svg', alt: 'saastopankki' }
  // ],
  creditCard: [
    { id: '56', src: '/american-express.svg', alt: 'american-express' },
    { id: '54', src: '/mastercard.svg', alt: 'mastercard' },
    { id: '53', src: '/visa.svg', alt: 'visa' }
  ]
}

export const currentUrl = window.location.protocol + '//' + window.location.host

export const paymentReturnURL =
  currentUrl === 'http://localhost:3000'
    ? 'https://cargo.eckeroline.com'
    : currentUrl

export const primaryNationalities = ['EE', 'FI', 'PL', 'LT', 'LV', 'RU', 'ALI']

export const betaTestUsers = ['R00102', 'R02284', 'T00531', 'R01779', 'R00958', 'R02326']
