import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getParameterArrayFromURLParameters } from '../../../utils/helpers'
import ReservationStore from '../../../stores/ReservationStore'
import { Center, Flex, Heading, Image, Link, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Parameter } from '../../../api/externalPaymentsAPI'

type ResultProps = {
  success: boolean
  bookingCode: string | null
}

const Result = ({ success, bookingCode }: ResultProps) => {
  const { t } = useTranslation()

  if (success && bookingCode) {
    return (
      <Flex direction="column" justify="center">
        <Center>
          <Image boxSize="100px" src="/thumb_up.svg" alt="ok" mb={5} />
        </Center>
        <Heading as="h2" size="2xl" mb={5}>
          {t('thank_you')}!
        </Heading>
        <Center mb={3}>
          <Text fontSize="xl">{t('your_booking_number')}</Text>
        </Center>
        <Center>
          <Link href={`/confirmation?action=edit&bookingCode=${bookingCode}`}>
            <Text color="blue.700" fontSize="2xl">
              {bookingCode}
            </Text>
          </Link>
        </Center>
      </Flex>
    )
  }

  return (
    <Flex direction="column" justify="center">
      <Heading as="h2" size="2xl" mb={5}>
        {t('payment_failed_sorry')}!
      </Heading>
      <Center mb={3}>
        <Text fontSize="xl">{t('something_went_wrong')}</Text>
      </Center>
    </Flex>
  )
}

const Success: React.FC = () => {
  const { search } = useLocation()
  const [paymentSucceeded, setPaymentSucceeded] = useState<boolean | null>(null)
  const [bookingCode, setBookingCode] = useState<string | null>(null)
  const { t } = useTranslation()

  const completePayment = async (
    transactionNumber: number,
    parameters: Parameter[]
  ) => {
    const { paymentSucceeded, bookingCode } = await ReservationStore.completePayment(
      transactionNumber,
      { parameters }
    )
    setPaymentSucceeded(paymentSucceeded)
    setBookingCode(bookingCode)
  }

  useEffect(() => {
    const parameters = getParameterArrayFromURLParameters(search)

    try {
      const carusTransactionNumber = parameters.find(
        (x) => x.name === 'carus_transnr'
      )

      if (carusTransactionNumber?.value) {
        const transactionNr = parseInt(carusTransactionNumber.value)
        completePayment(transactionNr, parameters)
      }
    } catch (err) {
      console.error(err)
    }
  }, [search])

  return paymentSucceeded === null ? (
    <Text>{t('loading')}</Text>
  ) : (
    <Result success={paymentSucceeded} bookingCode={bookingCode} />
  )
}

export default Success
