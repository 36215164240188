import React, { useEffect, useState } from 'react'
import { getParameterArrayFromURLParameters } from '../../../utils/helpers'
import { useLocation } from 'react-router-dom'
import { Parameter } from '../../../api/externalPaymentsAPI'
import { Center, Flex, Link, Skeleton, Text } from '@chakra-ui/react'
import ReservationStore from '../../../stores/ReservationStore'
import Button from '../../../components/Button/Button'
import { useTranslation } from 'react-i18next'

const Cancel: React.FC = () => {
  const { search } = useLocation()
  const [bookingCode, setBookingCode] = useState('')

  const { t } = useTranslation()

  const completePayment = async (
    transactionNumber: number,
    parameters: Parameter[]
  ) => {
    const { bookingCode } = await ReservationStore.completePayment(
      transactionNumber,
      {
        parameters
      }
    )

    setBookingCode(bookingCode)
  }

  useEffect(() => {
    const parameters = getParameterArrayFromURLParameters(search)

    try {
      const carusTransactionNumber = parameters.find(
        (x) => x.name === 'carus_transnr'
      )

      if (carusTransactionNumber?.value) {
        const transactionNr = parseInt(carusTransactionNumber.value)
        completePayment(transactionNr, parameters)
      }
    } catch (err) {
      console.error(err)
    }
  }, [search])

  return (
    <Flex direction="column" justify="center">
      <Center mb={5}>
        <Text fontSize="xl">{t('payment_failed_or_cancelled')}</Text>
      </Center>
      <Skeleton mb={5} isLoaded={!!bookingCode}>
        <Center>
          <Text fontSize="large">{t('choose_continue_reservation')}</Text>
        </Center>
      </Skeleton>
      <Center gap={5}>
        <Skeleton isLoaded={!!bookingCode}>
          <Link href={`/confirmation?action=edit&bookingCode=${bookingCode}`}>
            <Button>{t('continue')}</Button>
          </Link>
        </Skeleton>
        <Skeleton isLoaded={!!bookingCode}>
          <Link href="/">
            <Button>{t('discard')}</Button>
          </Link>
        </Skeleton>
      </Center>
    </Flex>
  )
}

export default Cancel
