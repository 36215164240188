import { addDays, endOfDay, format, isPast, parse, startOfDay } from 'date-fns'
import { forEach, groupBy } from 'lodash'
import {
  action,
  computed,
  configure,
  makeAutoObservable,
  observable,
  reaction,
  runInAction,
  toJS
} from 'mobx'

import {
  CargoAvailabilityCabin,
  CargoAvailabilityMisc,
  CargoAvailabilityMiscAvailableStatusCode,
  CargoAvailabilitySearchResult,
  CargoAvailabilityVehicle,
  CargoBooking,
  CargoCountry,
  CargoDeparture,
  CargoExtCategory,
  CargoExtCategoryAvailabilityGroupType,
  CargoExtPort,
  CargoGender,
  CargoJourney,
  CargoJourneyBookingRowAvailabilityGroupType,
  CargoNameListRow,
  CargoVehicleUnit,
  CreateCargoAvailabilityRequest,
  CreateCargoBookingRequest,
  CreateCargoBookingRowVehiclePropsRequest,
  CreateCargoJourneyBookingRowRequest,
  UpdateCargoBookingContactDetailRequest,
  UpdateCargoBookingRequest,
  UpdateCargoBookingRowVehiclePropsRequest,
  UpdateCargoJourneyBookingRowRequest
} from '../api/cargoSelfServiceAPI'
import { Action, ExtraService } from '../services/types'
import { DriverTokenResponse, GenerateDriverToken } from '../api/driverUpdateAPI'
import {
  ApisysPayeeInformation,
  CompleteExtEcommercePaymentRequest,
  CompleteExtEcommercePaymentResponse,
  InitiateExtEcommercePaymentRequest,
  InitiateExtEcommercePaymentResponse
} from '../api/externalPaymentsAPI'
import {
  cargoTypes,
  categoryRestrictions,
  DEFAULT_PRODUCT_CODE,
  paymentReturnURL,
  primaryNationalities
} from '../utils/constants'
import {
  calendarDays,
  driversToNameList,
  firstDayOfCalendar,
  getPaytrailCompatibleLocale,
  lastDayOfCalendar,
  loadDriversFromLocalStorage,
  pickByKeyValues,
  reqErrorHandler,
  saveDriversToLocalStorage,
  sumCategorySpecificationQuantityByExtCategoryCode,
  sumObjects
} from '../utils/helpers'
import ErrorStore from './ErrorStore'
import initI18n, { LangKeyword } from '../locales'
import userStore from './UserStore'
import occasionalCustomerStore from './OccasionalCustomerStore'
import {
  convertToCargoRegNumbers,
  decipherBookingRegNumbers,
  handleBookingRegistrationNumbers,
  handleCargoDescriptions,
  handlePaperService,
  isValidPrice
} from '../utils/bookingHelpers'
import api from '../services/api'
import { ReservationFormValues } from '../views/reservation/ReservationContainer'

configure({
  enforceActions: 'always'
})

class ReservationStore {
  @observable ports: CargoExtPort[] = []
  @observable departureCountryCode: string = ''
  @observable arrivalCountryCode: string = ''
  @observable departurePort?: CargoExtPort
  @observable arrivalPort?: CargoExtPort
  @observable departures: CargoDeparture[] = []
  @observable departureCode: string = ''
  @observable bookingCode: string = '14150093'
  @observable journeyCode: string = ''
  @observable from: Date = new Date()
  @observable to: Date = new Date()
  @observable calendarDepartures: Array<CargoDeparture | null> = []
  @observable powerConnectionRequired: boolean = false
  @observable savedDrivers: CargoNameListRow[] = []
  @observable drivers: CargoNameListRow[] = []
  @observable
  genders: (Required<CargoGender> & { genderName: LangKeyword })[] = [
    { genderCode: 'M', genderName: 'Male' },
    { genderCode: 'F', genderName: 'Female' }
  ]
  @observable nationalities: CargoCountry[] = []
  @observable vehicle: CargoVehicleUnit = {}
  @observable externalNote: string | undefined = ''
  @observable internalNote: string | undefined = ''
  @observable trailer: CargoVehicleUnit = {}
  @observable departureCategories: CargoExtCategory[] = []
  @observable cargoType: string = ''
  @observable customCargoType: string = ''
  @observable reservedLength: number = 0
  @observable extraServices: ExtraService[] = []
  @observable journeys: CargoJourney[] = []
  @observable month: Date = new Date()
  @observable availableServices: CargoAvailabilitySearchResult = {}
  @observable loadWeight: number | undefined = undefined
  @observable booking: CargoBooking = {}
  @observable journeyPrice?: number
  @observable payee?: ApisysPayeeInformation
  @observable preSelectedPaymentMethod?: string
  @observable consignee?: string

  bookings: CargoBooking[] = []

  private autorunDisposer: () => void = () => {}

  constructor() {
    makeAutoObservable(this)
    this.fetchDeparturePorts()
  }

  @computed
  get departureCountries(): string[] {
    return [
      ...new Set(
        this.ports.reduce<string[]>(
          (a, x) => (x.countryExtCode ? [...a, x.countryExtCode] : a),
          []
        )
      )
    ]
  }

  @computed
  get allDepartures(): Record<string, CargoDeparture> {
    return this.departures.reduce(
      (departures, departure) => ({
        ...departures,
        [departure.departureCode!]: departure
      }),
      {} as Record<string, CargoDeparture>
    )
  }

  @computed
  get vehicleTemplateName(): string {
    const selectedVehicleTemplate: CargoExtCategory | undefined =
      this.vehicleTemplates.find(
        (vehicleTemplate) => vehicleTemplate.categoryExtCode === this.vehicleTemplate
      )
    return selectedVehicleTemplate && selectedVehicleTemplate.categoryExtCode
      ? selectedVehicleTemplate.categoryExtCode
      : ''
  }

  @computed
  get vehicleRegistrationNumber(): string | undefined {
    return this.vehicle.registrationNumber
  }

  get trailerRegistrationNumber(): string | undefined {
    return this.trailer.registrationNumber
  }

  get allotmentAvailable(): number {
    if (
      Array.isArray(this.booking.cargoJourneys) &&
      this.booking.cargoJourneys[0].departureCode === this.departureCode
    ) {
      return (
        this.selectedDeparture.customerAllotments!.allotments!.highSpaceAllotment!
          .availableLength! + this.reservedLength
      )
    }

    return this.selectedDeparture.customerAllotments!.allotments!.highSpaceAllotment!
      .availableLength!
  }

  filterExtraServices(type: CargoExtCategoryAvailabilityGroupType) {
    return (
      this.extraServices.filter(
        (category) => category.availabilityGroupType === type
      ) || []
    )
  }

  filterByAvailable = <
    H extends CargoExtCategory,
    N extends {
      availableStatusCode?: string
      extCategoryCode?: string
    }
  >(
    needles: H[],
    haystack: N[]
  ): H[] =>
    haystack.reduce((collection, item) => {
      // Using a string here because the needle stack types have all their own enum
      if (item.availableStatusCode === 'NEVER_AVAILABLE') {
        return collection
      }

      const newItem = needles.find((y) => y.categoryExtCode === item.extCategoryCode)

      if (!newItem) {
        return collection
      }

      return [...collection, newItem]
    }, [] as H[])

  @computed
  get cabins(): ExtraService[] {
    const { cabinsAvailability } = this.availableServices
    if (!cabinsAvailability || !cabinsAvailability.cabin) {
      return []
    }

    const allCabins = this.filterExtraServices(
      CargoExtCategoryAvailabilityGroupType.LODGING
    )

    return this.filterByAvailable<ExtraService, CargoAvailabilityCabin>(
      allCabins,
      cabinsAvailability.cabin
    )
  }

  @computed
  get meals(): ExtraService[] {
    const { miscAvailability } = this.availableServices

    if (!miscAvailability || !miscAvailability.misc) {
      return []
    }

    const miscServices = this.filterExtraServices(
      CargoExtCategoryAvailabilityGroupType.MISC
    )

    const availableMeals = miscAvailability.misc.filter(
      (x) => /MEAL/.test(x.extCategoryCode || '') && x.availableAmount! > 0
    )

    return this.filterByAvailable<ExtraService, CargoAvailabilityMisc>(
      miscServices,
      availableMeals
    )
  }

  @computed
  get services() {
    const { miscAvailability } = this.availableServices
    if (!miscAvailability || !miscAvailability.misc) {
      return []
    }

    const miscServices = this.filterExtraServices(
      CargoExtCategoryAvailabilityGroupType.MISC
    )
    const otherServices = miscAvailability.misc.filter(
      (x) =>
        !/MEAL/.test(x.extCategoryCode || '') &&
        x.extCategoryCode !== 'POW' &&
        x.extCategoryCode !== 'TAV2'
    )

    return this.filterByAvailable<ExtraService, CargoAvailabilityMisc>(
      miscServices,
      otherServices
    )
  }

  @computed
  get powerConnectionAvailable() {
    const { miscAvailability } = this.availableServices
    if (this.vehicle.extCategoryCode === 'VANCH') {
      return false
    }
    if (!miscAvailability || !miscAvailability.misc) {
      return false
    }
    const pow = miscAvailability.misc.find((x) => x.extCategoryCode === 'POW')
    return !(
      !pow ||
      pow.availableStatusCode ===
        CargoAvailabilityMiscAvailableStatusCode.NEVER_AVAILABLE
    )
  }

  @computed
  get selectedDeparture(): CargoDeparture {
    const defaults = {
      customerAllotments: {
        bookingRestrictedToAllotment: false,
        allotments: {
          highSpaceAllotment: {
            availableLength: 0
          }
        }
      }
    }
    if (!this.departureCode) {
      return defaults
    }

    const { customerAllotments, ...rest } =
      this.allDepartures[this.departureCode] || defaults

    return {
      customerAllotments: customerAllotments || defaults.customerAllotments,
      ...rest
    }
  }

  @computed
  get isCargoInfoValid() {
    return !!(
      this.vehicleTemplate?.categoryExtCode &&
      this.vehicleRegistrationNumber !== 'ABC-123' &&
      this.vehicleRegistrationNumber !== '' &&
      typeof this.loadWeight === 'number' &&
      this.cargoType
    )
  }

  @computed
  get productRestrictions(): string[] {
    if (this.booking && this.booking.bookingStatusCode === 'PS_RQ') {
      const preliminary = 'PRELIMINARY'
      return categoryRestrictions[preliminary]
    } else if (this.booking && this.booking.extProductCode) {
      const { extProductCode } = this.booking
      return categoryRestrictions[extProductCode] || []
    }
    return []
  }

  getVehicleTemplateDefaults(extCategoryCode: string) {
    let defaults: {} = { height: 0, length: 0, width: 0 }
    const template = this.findVehicleTemplateByCode(extCategoryCode)

    if (template && template.defaults) {
      const { heightDefaults, lengthDefaults, weightDefaults, widthDefaults } =
        template.defaults

      if (heightDefaults && lengthDefaults && weightDefaults && widthDefaults) {
        defaults = {
          height: heightDefaults.defaultValue,
          length: lengthDefaults.defaultValue,
          tareWeight: weightDefaults.defaultValue,
          width: widthDefaults.defaultValue
        }
      }
    }

    return defaults
  }

  @action
  setVehicleTemplate(extCategoryCode: string) {
    this.vehicle.extCategoryCode = extCategoryCode
    this.vehicle = {
      ...this.vehicle,
      ...this.getVehicleTemplateDefaults(extCategoryCode)
    }
  }

  @action
  setDepartures(departures: CargoDeparture[]) {
    this.departures = departures
  }

  get availabilityRequestBody(): CreateCargoAvailabilityRequest {
    return {
      currencyExtCode: 'EUR',
      extProductCode: DEFAULT_PRODUCT_CODE,
      includeNotPossible: false,
      includeSoldOut: false,
      miscAvailability: { included: true },
      cabinAvailability: { included: true },
      vehicleAvailability: {
        cargoCategoriesOnly: false,
        expandSpecifications: false,
        includeDriverless: true,
        includeTrailers: true,
        includeTrucks: true,
        included: true,
        quantity: 1,
        specificationQuantity: 0
      }
    }
  }

  @action
  fetchAvailableServices = () => {
    ErrorStore.addLoader()
    api.cargoSelfService
      .postCargoAvailabilityTimeAndPriceAvailability(
        this.departureCode,
        this.journeyCode,
        this.availabilityRequestBody
      )
      .then(({ data }) => {
        runInAction(() => {
          ErrorStore.removeLoader()
          this.availableServices = data
        })
      })
      .catch((error) => {
        console.error(error)
        ErrorStore.removeLoader()
      })
  }

  get startDate() {
    return {
      startDate: format(this.from, 'YYYY-MM-DD'),
      startHours: Number(format(this.from, 'HH')),
      startMinutes: Number(format(this.from, 'MM'))
    }
  }

  vehicleProperties(
    update: true,
    values: ReservationFormValues
  ): UpdateCargoBookingRowVehiclePropsRequest
  vehicleProperties(
    update: false,
    values: ReservationFormValues
  ): CreateCargoBookingRowVehiclePropsRequest
  vehicleProperties(isUpdate = false, values: ReservationFormValues) {
    const registrationNumbers = handleBookingRegistrationNumbers(
      toJS(
        this.booking.cargoJourneys?.[0]?.journeyBookingRows?.[0]?.vehicleProperties
          ?.registrationNumbers
      ),
      {
        vehicleRN: this.vehicleRegistrationNumber,
        vehicleCountryCode: values.vehicleRegistrationNumberCountryCode,
        trailerRN: this.trailerRegistrationNumber,
        trailerCountryCode: values.trailerRegistrationNumberCountryCode
      }
    )

    return {
      cargoDescriptions: handleCargoDescriptions(this.cargoType, {
        isUpdate,
        oldDescriptions:
          this.booking.cargoJourneys?.[0]?.journeyBookingRows?.[0]?.vehicleProperties
            ?.cargoDescriptions,
        loadWeight: this.loadWeight || 0
      }),
      categoryQuantity: 1,
      driverCount: this.drivers.length || 1,
      height: this.vehicle.height,
      length: this.vehicle.length,
      loadWeight: this.loadWeight,
      registrationNumbers: convertToCargoRegNumbers(registrationNumbers),
      tareWeight: this.vehicle.tareWeight,
      width: this.vehicle.width,
      customerCodeShipper: userStore.customer.customerCode,
      customerCodeConsignee: userStore.customer.customerCode,
      consignee: this.consignee
    }
  }

  getMealsRows = (useRestrictions = false) => {
    if (useRestrictions && this.productRestrictions.includes('MEALS')) {
      return []
    }
    return this.meals
      .filter((x) => x.quantity > 0)
      .map((x) => ({
        extCategoryCode: x.categoryExtCode,
        miscProperties: { categoryQuantity: x.quantity }
      }))
  }

  getServiceRows = () => {
    return this.services
      .filter((x: any) => x.quantity > 0)
      .map((x: any) => ({
        extCategoryCode: x.categoryExtCode,
        miscProperties: { categoryQuantity: x.quantity }
      }))
  }

  getJourneyBookingRows(
    update: true,
    values: ReservationFormValues
  ): UpdateCargoJourneyBookingRowRequest[]
  getJourneyBookingRows(
    update: false,
    values: ReservationFormValues
  ): CreateCargoJourneyBookingRowRequest[]
  getJourneyBookingRows(update: boolean, values: ReservationFormValues) {
    const vehicleRow = {
      bookingRowNumber: update
        ? this.booking.cargoJourneys &&
          this.booking.cargoJourneys[0] &&
          this.booking.cargoJourneys[0].journeyBookingRows &&
          this.booking.cargoJourneys[0].journeyBookingRows![0] &&
          this.booking.cargoJourneys[0].journeyBookingRows![0]!.bookingRowNumber
        : undefined,
      extCategoryCode: this.vehicle.extCategoryCode || '',
      externalNote: this.externalNote,
      internalNote: this.internalNote,
      vehicleProperties: this.vehicleProperties(update as true, values)
    }
    const mealRows = this.getMealsRows(update)
    const cabinRows = this.getCabinRows(update)
    const serviceRows = this.getServiceRows()
    const tav2 = handlePaperService(
      this.availableServices?.miscAvailability?.misc,
      this.cargoType
    )
    const connectedBookingRows = [...mealRows, ...cabinRows, ...serviceRows, ...tav2]
    const powerRow = {
      extCategoryCode: 'POW',
      miscProperties: { categoryQuantity: 1 }
    }
    this.powerConnectionRequired &&
      this.powerConnectionAvailable &&
      connectedBookingRows.push(powerRow)

    const entity: UpdateCargoJourneyBookingRowRequest = {
      connectedBookingRows,
      ...vehicleRow
    }

    if (update) {
      entity.bookingRowNumber = 1
    }
    return [entity]
  }

  get driverCount(): number {
    return this.drivers.length
  }

  getDriverByIndex = (index: number): CargoNameListRow => {
    return (index < this.driverCount && this.drivers[index]) || {}
  }

  getDriverGenderByIndex = (index: number): string => {
    const { genderCode } = this.getDriverByIndex(index)
    return genderCode || 'M'
  }

  getCabinRows = (useRestriction = false): CreateCargoJourneyBookingRowRequest[] => {
    if (useRestriction && this.productRestrictions.includes('CABINS')) {
      return []
    }

    const cabin = this.cabins.find((x) => x.quantity > 0)
    const cabinRows: CreateCargoJourneyBookingRowRequest[] = []

    if (!cabin) {
      return cabinRows
    }

    for (let n = 0; n < cabin.quantity; n++) {
      const gender = this.getDriverGenderByIndex(n)

      cabinRows.push({
        categorySpecificationCode: `${gender}`,
        categorySpecificationQuantity: 1,
        extCategoryCode: cabin.categoryExtCode || '',
        cabinProperties: { categoryQuantity: 1 }
      })
    }

    return cabinRows
  }

  @computed
  cargoBookingBody(values: ReservationFormValues): CreateCargoBookingRequest {
    return {
      bookingContacts: [
        {
          autoSms: false,
          contactInfo: userStore.computedEmail,
          contactType: 'EMAIL',
          sendInfo: false
        }
      ],
      cargoJourney: {
        journeyBookingRows: this.getJourneyBookingRows(false, values)
      },
      countryExtCode: userStore.customer.countryExtCode,
      currencyExtCode: userStore.customer.currencyExtCode,
      extProductCode: DEFAULT_PRODUCT_CODE,
      externalNote: '',
      isTermsAndConditionsAccepted: true,
      languageExtCode: userStore.customer.languageExtCode,
      lastName: userStore.customer.name,
      namelist: driversToNameList(this.drivers)
    }
  }

  @computed
  updateCargoBookingBody(
    values: ReservationFormValues
  ): UpdateCargoBookingRequest | any {
    const {
      bookingReference,
      countryExtCode,
      currencyExtCode,
      extProductCode,
      externalNote,
      extraBookingLines,
      internalNote,
      languageExtCode,
      lastName,
      middleName,
      phoneArea,
      phoneNumber,
      postalCode,
      postalDistrict,
      promotionCode,
      stateCode,
      streetAddress,
      titleCode,
      bookingContacts
    } = this.booking

    const updateBookingContacts: UpdateCargoBookingContactDetailRequest =
      bookingContacts && bookingContacts[0]
        ? { ...bookingContacts[0], contactInfo: userStore.email }
        : {
            autoSms: false,
            contactInfo: userStore.computedEmail,
            contactType: 'EMAIL',
            sendInfo: false,
            contactRowNumber: 1
          }
    return {
      bookingContacts: [updateBookingContacts],
      bookingReference,
      cargoJourney: {
        departureCode: this.departureCode,
        journeyBookingRows: this.getJourneyBookingRows(true, values),
        journeyCode: {
          value: this.journeyCode
        }
      },
      countryExtCode,
      currencyExtCode,
      extProductCode,
      externalNote,
      extraBookingLines,
      internalNote,
      isTermsAndConditionsAccepted: true,
      languageExtCode,
      lastName,
      middleName,
      phoneArea,
      phoneNumber,
      postalCode,
      postalDistrict,
      promotionCode,
      stateCode,
      streetAddress,
      titleCode,
      namelist: driversToNameList(this.drivers)
    }
  }

  getSelfByAction = async (currentAction: Action, values: ReservationFormValues) => {
    if (currentAction === Action.Edit) {
      const { data: { self } = {} } =
        await api.cargoSelfService.updateCargoBookingOnDepartureWithVersionCode(
          this.bookingCode,
          this.updateCargoBookingBody(values)
        )
      return self
    }
    const { data: { self } = {} } =
      await api.cargoSelfService.createCargoNewCargoBooking(
        this.departureCode,
        this.journeyCode,
        this.cargoBookingBody(values)
      )
    return self
  }

  @action
  makeReservation = async (
    currentAction: Action,
    values: ReservationFormValues
  ): Promise<[string, null] | [null, string]> => {
    if (currentAction === Action.Edit) {
      ErrorStore.setSaving(true)
    } else {
      ErrorStore.addLoader()
    }

    try {
      if (currentAction === Action.Edit) {
        ErrorStore.setSaving(false)
      } else {
        ErrorStore.removeLoader()
      }
      saveDriversToLocalStorage(toJS(this.drivers))
      const self = await this.getSelfByAction(currentAction, values)

      if (self) {
        const bookingCode = (typeof self === 'object' ? self.href || '' : self)
          .split('/')
          .pop()
        return [bookingCode as string, null]
      }

      return [null, 'Something went wrong']
    } catch (error) {
      console.error(error)
      ErrorStore.removeLoader()
      return [null, reqErrorHandler(error)]
    }
  }

  @action
  makePreliminaryBooking = async (departureCode: any, journeyCode: any) => {
    try {
      ErrorStore.addLoader()

      const body: CreateCargoBookingRequest = {
        bookingContacts: [
          {
            autoSms: false,
            contactInfo: userStore.computedEmail,
            contactType: 'EMAIL',
            sendInfo: false
          }
        ],
        bookingStatusCode: 'PS_RQ',
        cargoJourney: {
          journeyBookingRows: [
            {
              extCategoryCode: 'TRKC',
              vehicleProperties: {
                categoryQuantity: 1,
                length: 17,
                loadWeight: 0,
                customerCodeShipper: userStore.customer.customerCode,
                customerCodeConsignee: userStore.customer.customerCode,
                registrationNumbers: [{ registrationNumberCode: 'ABC-123' }]
              }
            }
          ]
        },
        countryExtCode: userStore.customer.countryExtCode,
        currencyExtCode: userStore.customer.currencyExtCode,
        extProductCode: DEFAULT_PRODUCT_CODE,
        isTermsAndConditionsAccepted: true,
        languageExtCode: userStore.customer.languageExtCode,
        lastName: userStore.customer.name
      }

      return api.cargoSelfService.createCargoNewCargoBooking(
        departureCode,
        journeyCode,
        body
      )
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    } finally {
      ErrorStore.removeLoader()
    }
  }

  @action
  generateDriverToken = async ({
    email,
    mobile,
    bookingCode,
    expiryTime = addDays(new Date(), 2).toISOString()
  }: {
    email?: string
    mobile?: string
    bookingCode: string
    expiryTime?: string
  }): Promise<[DriverTokenResponse, null] | [null, string]> => {
    try {
      ErrorStore.addLoader()

      const body: GenerateDriverToken = { expiryTime }

      if (email) {
        body.sendEmail = {
          emailAddress: email,
          emailTemplateCode: 'ELI_DRVLNK'
        }
      }

      if (mobile) {
        body.sendSms = {
          phoneNumber: mobile,
          smsTemplateCode: 'ELI_DRVSMS'
        }
      }

      const { data } = await api.driverUpdate.generateDriverToken(bookingCode, body)
      return [data, null]
    } catch (error) {
      console.error(error)
      return [null, reqErrorHandler(error)]
    } finally {
      ErrorStore.removeLoader()
    }
  }

  @action
  startPayment = async (
    bookingCode: string
  ): Promise<[InitiateExtEcommercePaymentResponse, null] | [null, string]> => {
    try {
      ErrorStore.addLoader()

      const booking = await this.loadReservation(bookingCode)

      if (booking?.bookingBalance) {
        const paymentInfo: InitiateExtEcommercePaymentRequest = {
          acceptCreditCardFee: true,
          amount: {
            amount: booking.bookingBalance,
            currencyExtCode: 'EUR'
          },
          methodOfPaymentCode: 'WEBPAYT',
          paymentLanguageCode: getPaytrailCompatibleLocale(initI18n.language),
          returnUrls: {
            cancel: `${paymentReturnURL}/payment/cancel`,
            decline: `${paymentReturnURL}/payment/cancel`,
            error: `${paymentReturnURL}/payment/cancel`,
            success: `${paymentReturnURL}/payment/success`
          },
          payee: this.payee,
          preSelectedPaymentMethod: this.preSelectedPaymentMethod
        }

        const { data: response } = await api.extPayments.initiateExtEcommercePayment(
          bookingCode,
          paymentInfo
        )

        return [response, null]
      } else {
        throw new Error()
      }
    } catch (error) {
      const err = reqErrorHandler(error)
      console.error(err)
      return [null, err]
    } finally {
      ErrorStore.removeLoader()
    }
  }

  @action
  completePayment = async (
    transactionNumber: number,
    body: CompleteExtEcommercePaymentRequest
  ): Promise<CompleteExtEcommercePaymentResponse> => {
    try {
      ErrorStore.addLoader()

      return api.extPayments
        .completeExtEcommercePayment(transactionNumber, ':complete', body)
        .then((res) => res.data)
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    } finally {
      ErrorStore.removeLoader()
    }
  }

  @action
  setBookingCode(bookingCode: string) {
    this.bookingCode = bookingCode
  }

  @action
  setJourneyCode(journeyCode: string) {
    this.journeyCode = journeyCode
  }

  @action
  setDepartureCode(departureCode: string) {
    this.departureCode = departureCode
  }

  @action
  setCargoType = (cargoType: string) => {
    this.cargoType = cargoType
  }

  @action
  setLength(length: number) {
    this.vehicle.length = length
  }

  @action
  setWeight(weight: number | undefined) {
    this.loadWeight = weight
  }

  @action
  setHeight(height: number) {
    this.vehicle.height = height
  }

  @action
  setWidth(width: number) {
    this.vehicle.width = width
  }

  @action
  setVehicle(vehicle: CargoVehicleUnit) {
    this.vehicle = vehicle
  }

  @action
  setTrailer(trailer: CargoVehicleUnit) {
    this.vehicle = trailer
  }

  @action
  setPowerConnectionRequired(value: boolean) {
    this.powerConnectionRequired = value
  }

  @action
  togglePowerConnectionRequired() {
    this.powerConnectionRequired = !this.powerConnectionRequired
  }

  @action
  setExternalNote(comment: string) {
    this.externalNote = comment
  }

  @action
  setInternalNote(comment: string) {
    this.internalNote = comment
  }

  @action
  setBooking(booking: CargoBooking) {
    this.booking = booking
  }

  @action
  setExtraServices(extraServices: ExtraService[]) {
    this.extraServices = extraServices
  }

  @action
  setDepartureCategories(departureCategories: CargoExtCategory[]) {
    this.departureCategories = departureCategories
  }

  @action
  fetchDeparturePorts = () =>
    api.cargoSelfService.getAllCargoPorts().then(({ data }) => {
      runInAction(() => {
        this.ports = data.ports || []
        this.departureCountryCode = ''
        this.setDepartureCountry(this.departureCountries[0])
      })
    })

  get selectedDate() {
    return this.from
  }

  @action
  setSelectedDate(date: Date) {
    this.from = startOfDay(date)
    this.to = endOfDay(date)
    this.setMonth(date)

    this.departureCode = ''
  }

  @action
  resetSelectedDate() {
    this.from = new Date()
    this.to = new Date()
    this.setMonth(new Date())
  }

  groupByDate = (
    departures: CargoDeparture[]
  ): { [datetime: string]: CargoDeparture[] } => {
    return groupBy(departures, (departure: CargoDeparture) =>
      format(departure.departureDatetime!, 'YYYY-MM-DD')
    )
  }

  @action
  fetchNationalities = async () => {
    await api.cargoSelfService
      .getAllCargoCountries({ pageSize: 1000 })
      .then(({ data }) => {
        runInAction(() => {
          if (!data.countries) {
            this.nationalities = []
            return
          }

          const [primary, secondary] = pickByKeyValues<CargoCountry>(
            data.countries,
            'countryExtCode',
            primaryNationalities
          )

          const sortedPrimary = primary.sort((a, b) => {
            return (
              primaryNationalities.indexOf(a.countryExtCode!) -
              primaryNationalities.indexOf(b.countryExtCode!)
            )
          })

          this.nationalities = [...sortedPrimary, ...secondary]
        })
      })
  }

  @action
  fetchDepartureCategories = () =>
    api.cargoSelfService
      .getCargoAllExtCategories({ pageSize: 1000 })
      .then(({ data: response }) => {
        this.setDepartureCategories(response.extCategories || [])
        const extraServices = (response.extCategories || []).map((item) => ({
          ...item,
          quantity: 0
        }))
        this.setExtraServices(extraServices)
        return response
      })

  fetchDepartures = (
    from: string,
    to: string,
    showAllDepartures?: boolean,
    departurePortExtCode?: string,
    arrivalPortExtCode?: string,
    sort?: string[],
    fields?: string
  ) =>
    api.cargoSelfService.getCargoApiAllDeparturesToCustomer(
      {
        departureDateFromDate: from,
        departureDateFromHours: 0,
        departureDateFromMins: 0,
        departureDateToDate: to,
        departureDateToHours: 23,
        departureDateToMins: 59,
        showAllDepartures,
        arrivalPortExtCode,
        sort,
        fields,
        departurePortExtCode
      },
      { headers: { 'Cache-Control': 'no-cache', pragma: 'no-cache' } }
    )

  fetchDeparturesFromCountry = async (from: Date, to: Date) => {
    const ports = this.ports.filter(
      ({ countryExtCode }) => this.departureCountryCode === countryExtCode
    )

    const formattedFrom = format(from, 'YYYY-MM-DD')
    const formattedTo = format(to, 'YYYY-MM-DD')

    const departuresPromises = ports.map((port) =>
      this.fetchDepartures(formattedFrom, formattedTo, true, port.portExtCode).then(
        ({ data: cargoDepartures }) => cargoDepartures || []
      )
    )
    const departures = await Promise.all(departuresPromises)
    return departures
      .reduce<CargoDeparture[]>(
        (cum, cur) => [...cum, ...(cur?.cargoDepartures || [])],
        []
      )
      .sort((a, b) => (a.departureDatetime! < b.departureDatetime! ? -1 : 1))
  }

  @action
  fetchCalendarDepartures = async (from: Date, to: Date) => {
    ErrorStore.addLoader()
    try {
      const departures = await this.fetchDeparturesFromCountry(from, to)
      const groupedDepartures = this.groupByDate(departures)
      const summedDepartures: any = {}

      forEach(groupedDepartures, (departure: CargoDeparture[], key: string) => {
        summedDepartures[key] = sumObjects(departure)
      })
      const groupedAndSummed = calendarDays(this.month).map(
        (day) => summedDepartures[format(day, 'YYYY-MM-DD')] || null
      )

      this.setCalendarDepartures(groupedAndSummed)

      ErrorStore.removeLoader()
    } catch (error) {
      runInAction(() => {
        ErrorStore.removeLoader()
        console.error(error)
      })
    }
  }

  @action
  setCalendarDepartures(calendarDepartures: Array<CargoDeparture | null>) {
    this.calendarDepartures = calendarDepartures
  }

  @action
  setDepartureCategory(extraService: ExtraService) {
    this.extraServices = [
      ...this.extraServices.map((item) =>
        item.categoryExtCode === extraService.categoryExtCode ? extraService : item
      )
    ]
  }

  findPortByCode = (portExtCode: string): CargoExtPort => {
    return this.ports.find((port) => port.portExtCode === portExtCode) || {}
  }

  findPortNameByCode = (portExtCode: string | undefined): string | undefined => {
    return this.findPortByCode(portExtCode || '').portName
  }

  @action
  setDepartureCountry = (countryCode: string = '') => {
    this.departureCountryCode = countryCode
    if (this.arrivalCountryCode === countryCode || this.arrivalCountryCode === '') {
      this.arrivalCountryCode =
        this.departureCountries.filter((x) => x !== countryCode)[0] || ''
    }
  }

  @action
  setArrivalCountry = (countryCode: string) => {
    this.arrivalCountryCode = countryCode
    if (this.departureCountryCode === countryCode) {
      this.departureCountryCode =
        this.departureCountries.filter((x) => x !== countryCode)[0] || ''
    }
  }

  @action
  setSelectedDeparture = (departureCode: string) => {
    this.departureCode = departureCode
  }

  @action
  reverseCountries() {
    const previousCountryCode = this.departureCountryCode
    this.departureCountryCode = this.arrivalCountryCode
    this.arrivalCountryCode = previousCountryCode
  }

  @computed
  get vehicleTemplates(): CargoExtCategory[] {
    const { vehicleAvailability } = this.availableServices
    if (!vehicleAvailability || !vehicleAvailability.vehicle) {
      return []
    }

    const allVehicles = this.departureCategories.filter(
      (category) =>
        category.availabilityGroupType ===
        CargoExtCategoryAvailabilityGroupType.VEHICLE
    )
    return this.filterByAvailable<CargoExtCategory, CargoAvailabilityVehicle>(
      allVehicles,
      vehicleAvailability.vehicle
    )
  }

  findVehicleTemplateByCode = (
    extCategoryCode?: string
  ): CargoExtCategory | undefined =>
    this.departureCategories.find(
      (category) => category.categoryExtCode === extCategoryCode
    )

  @computed
  get vehicleTemplate(): CargoExtCategory | undefined {
    const { extCategoryCode = '' } = this.vehicle
    return this.findVehicleTemplateByCode(extCategoryCode)
  }

  @action
  addDriver = () => {
    this.drivers = [
      ...this.drivers,
      {
        firstName: '',
        lastName: '',
        mobile: '',
        genderCode: 'M',
        nationalityExtCode: '',
        dateOfBirth: '',
        email: ''
      }
    ]
  }

  @action
  deleteDriver = (id: number) => {
    this.drivers.splice(id, 1)
  }

  @action
  setDriverInfo = (id: number, key: string, value: string) => {
    this.drivers[id][key] = value
  }

  @action
  reset = () => {
    this.resetSelectedDate()

    this.departures = []
    this.departureCode = ''
    this.booking = {}
    this.bookingCode = ''
    this.powerConnectionRequired = false
    this.externalNote = ''
    this.internalNote = ''
    this.cargoType = ''
    this.loadWeight = undefined
    this.reservedLength = 0
    this.customCargoType = ''
    this.drivers = [
      {
        firstName: '',
        lastName: '',
        nationalityExtCode: '',
        dateOfBirth: '',
        mobile: '',
        genderCode: 'M', // Defaults to male
        email: ''
      }
    ]
    this.vehicle = {
      length: 0,
      tareWeight: 0,
      registrationNumber: '',
      height: 0,
      width: 0,
      extCategoryCode: ''
    }
    this.trailer = {
      registrationNumber: ''
    }
  }

  loadReservation = async (bookingCode: string) => {
    ErrorStore.addLoader()
    // set the booking code
    this.setBookingCode(bookingCode)
    // fetch booking by code
    const { data: booking } = await api.cargoSelfService.getCargoBookingWithCode(
      bookingCode,
      {
        recallCancelled: false
      }
    )

    this.setBooking(booking)

    ErrorStore.removeLoader()

    return booking
  }

  @action
  setDepartureCountryByPortCode = (departurePortExtCode: string) => {
    const { countryExtCode } =
      this.ports.find((port) => port.portExtCode === departurePortExtCode) || {}

    if (countryExtCode) {
      this.setDepartureCountry(countryExtCode)
    }
  }

  @action
  decipherBookingData = async () => {
    const { cargoJourneys, namelist } = this.booking
    const [cargoJourney] = cargoJourneys || []

    // Driver information
    this.drivers = namelist
      ? namelist.map(({ dateOfBirth = '', ...rest }) => ({
          ...rest,
          dateOfBirth: (dateOfBirth || '').split('-').reverse().join(' . ')
        }))
      : []

    // Departure and journey details
    if (!(cargoJourneys && cargoJourney && cargoJourney.journeyBookingRows)) {
      return
    }

    const {
      departureTime,
      departurePortExtCode,
      departureCode,
      journeyCode,
      journeyPrice
    } = cargoJourney

    if (departurePortExtCode) {
      this.setDepartureCountryByPortCode(departurePortExtCode)
    }

    const departureDate = departureTime ? parse(departureTime) : new Date()

    this.setSelectedDate(departureDate) // triggers calendar autorun and departure selection
    this.setJourneyCode(journeyCode || '')
    this.setDepartureCode(departureCode || '') //  triggers fetchAvailableServices
    this.journeyPrice = journeyPrice

    await this.updateExtraServices()
  }

  @action
  updateExtraServices = async () => {
    const { cargoJourneys } = this.booking
    const [cargoJourney] = cargoJourneys || []

    const vehicle = cargoJourney.journeyBookingRows!.find(
      (x) =>
        x.availabilityGroupType ===
        CargoJourneyBookingRowAvailabilityGroupType.VEHICLE
    )

    if (!vehicle) {
      return
    }

    this.vehicle.extCategoryCode = vehicle.extCategoryCode
    this.externalNote = vehicle.externalNote
    this.internalNote = vehicle.internalNote

    if (vehicle.vehicleProperties) {
      const {
        cargoDescriptions,
        height,
        length,
        loadWeight,
        tareWeight,
        registrationNumbers,
        width
      } = vehicle.vehicleProperties

      if (Array.isArray(cargoDescriptions) && cargoDescriptions[0]) {
        const cargoType = cargoDescriptions[0].cargoDescription || ''
        this.cargoType = cargoType

        if (cargoTypes.indexOf(cargoType) === -1) {
          this.customCargoType = cargoType
        }
      }

      this.loadWeight = loadWeight || 0
      this.reservedLength = length || 0
      this.vehicle = {
        ...this.vehicle,
        height,
        length,
        tareWeight,
        width
      }

      const regNumbers = decipherBookingRegNumbers(registrationNumbers)

      this.vehicle.registrationNumber = regNumbers.vehicleRegNumber
      this.trailer.registrationNumber = regNumbers.trailerRegNumber
    }

    // ExtraServices services from connectedBookingRows
    const { connectedBookingRows } = vehicle
    if (!connectedBookingRows) {
      return
    }

    // Update extra services
    await this.fetchDepartureCategories()

    const extraServices = [
      ...this.extraServices.map((extraService) => {
        const connectedBookingRow = connectedBookingRows.find(
          ({ extCategoryCode }) => extraService.categoryExtCode === extCategoryCode
        )

        if (!connectedBookingRow) {
          return extraService
        }

        switch (extraService.availabilityGroupType) {
          case CargoExtCategoryAvailabilityGroupType.LODGING: {
            const { extCategoryCode } = connectedBookingRow
            const quantity = connectedBookingRows.reduce(
              sumCategorySpecificationQuantityByExtCategoryCode(extCategoryCode),
              0
            )
            return {
              ...extraService,
              quantity
            }
          }

          case CargoExtCategoryAvailabilityGroupType.MISC:
            if (connectedBookingRow.extCategoryCode === 'POW') {
              this.powerConnectionRequired = true
            } else if (connectedBookingRow.miscProperties) {
              return {
                ...extraService,
                quantity: connectedBookingRow.miscProperties.categoryQuantity || 0
              }
            }

            return extraService

          default:
            return extraService
        }
      })
    ]
    this.setExtraServices(extraServices)
  }

  @computed
  get bookedDeparture() {
    return (
      this.booking &&
      this.booking.cargoJourneys &&
      this.booking.cargoJourneys[0] &&
      this.booking.cargoJourneys[0].departureCode
    )
  }

  @computed
  get isEditable() {
    return !!this.booking && isValidPrice(this.booking.bookingBalance)
  }

  @action
  setMonth(month: Date) {
    this.month = month
  }

  @action
  setFrom(from: Date) {
    this.from = from
  }

  @action
  setTo(to: Date) {
    this.to = to
  }

  @action
  resetDepartures() {
    this.departures = []
  }

  @action
  loadSavedDrivers() {
    this.savedDrivers = loadDriversFromLocalStorage()
  }

  @action
  registerAutorun = () => {
    this.autorunDisposer = this.startAutorun()
  }

  @action
  disposeAutorun = () => {
    this.autorunDisposer()
  }

  @action
  setValues = (values: ReservationFormValues) => {
    this.drivers = values.drivers
    this.cargoType = values.cargoType
    this.externalNote = values.externalNote
    this.internalNote = values.internalNote
    this.loadWeight = +values.loadWeight
    this.trailer.registrationNumber = values.trailerRegistrationNumber
    this.powerConnectionRequired = values.powerConnection
    this.vehicle.length = values.vehicleLength
    this.vehicle.height = values.height
    this.vehicle.width = values.width
    this.vehicle.registrationNumber = values.vehicleRegistrationNumber
    this.consignee = values.consignee
  }

  fetchBooking = async (bookingCode: string) => {
    const booking = await this.loadReservation(bookingCode)

    const journeyBookingRow = booking?.cargoJourneys?.[0]?.journeyBookingRows?.[0]
    const props = journeyBookingRow?.vehicleProperties

    const { vehicle, trailer } = decipherBookingRegNumbers(
      props?.registrationNumbers
    )

    // Default values for the form
    return {
      drivers: toJS(this.drivers),
      vehicleLength: props?.length || 0,
      cargoType: props?.cargoDescriptions?.[0]?.cargoDescription,
      vehicleTemplateCategoryExtCode: journeyBookingRow?.extCategoryCode,
      height: props?.height,
      loadWeight: props?.loadWeight,
      width: props?.width,
      externalNote: journeyBookingRow?.externalNote,
      internalNote: journeyBookingRow?.internalNote,
      trailerRegistrationNumber: trailer?.registrationNumberCode,
      trailerRegistrationNumberCountryCode: trailer?.registrationCountryCode,
      vehicleRegistrationNumber: vehicle?.registrationNumberCode,
      vehicleRegistrationNumberCountryCode: vehicle?.registrationCountryCode,
      powerConnection: journeyBookingRow?.connectedBookingRows?.some(
        (row) => row.extCategoryCode === 'POW'
      ),
      consignee: props?.consignee
    }
  }

  public async updateDepartures() {
    this.resetDepartures()
    const departures = await this.fetchDeparturesFromCountry(this.from, this.to)
    this.setDepartures(departures)
    return departures
  }

  /**
   * Start autorun for departures.
   * Returns disposer function
   */
  private startAutorun() {
    // Update departures
    const disposeDepartures = reaction(
      // When date or departure country changes
      () => this.departureCountryCode + this.to + this.from,
      async () => {
        if (!this.departureCountryCode || !this.to || !this.from) {
          return
        }

        const departures = await this.updateDepartures()

        if (userStore.isOccasional) {
          // fetching the departures' allotment for occasional customers
          const departurePortExtCodes = departures.reduce<string[]>(
            (portCodes, { departurePortExtCode: code }) =>
              code && !portCodes.find((portCode) => portCode === code)
                ? [...portCodes, code]
                : portCodes,
            []
          )
          await occasionalCustomerStore.fetchDeparturesByPortCodes({
            portCodes: departurePortExtCodes,
            date: this.from
          })
        }
      }
    )

    // Update calendar
    const disposeCalendarDepartures = reaction(
      () => this.month,
      async () => {
        if (!this.month) {
          return
        }
        const firstDay = firstDayOfCalendar(this.month)
        const lastDay = lastDayOfCalendar(this.month)
        const today = new Date()
        await this.fetchCalendarDepartures(
          isPast(firstDay) ? today : firstDay,
          lastDay
        )
      }
    )

    // Reactions are run only when departure code changes
    const disposeServices = reaction(
      () => this.departureCode,
      async () => {
        if (!this.departureCode) {
          this.availableServices = {}
          return
        }

        await this.fetchDepartureCategories()

        if (this.booking.bookingCode) {
          await this.updateExtraServices()
        }

        this.fetchAvailableServices()
      }
    )

    const disposeBooking = reaction(
      () => this.booking,
      async () => {
        if (this.booking.bookingCode) {
          await this.decipherBookingData()
        }
      }
    )

    return () => {
      disposeDepartures()
      disposeCalendarDepartures()
      disposeServices()
      disposeBooking()
    }
  }
}

export default new ReservationStore()
