import React from 'react'

import css from './CopyToPasteboard.module.css'

type Props = {
  value: string
}

type State = {
  isCopied: boolean
}

class CopyToPasteboard extends React.Component<Props, State> {
  state = {
    isCopied: false
  }
  timeout?: NodeJS.Timer
  inputRef = React.createRef<HTMLInputElement>()

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (!this.inputRef.current) {
      return
    }
    this.inputRef.current.select()
    document.execCommand('copy')
    this.setState({ isCopied: true }, this.debounce)
  }

  debounce = () => {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      this.setState({ isCopied: false })
    }, 1000)
  }

  render() {
    return (
      <div className={css.container}>
        <input
          className={css.input}
          value={this.props.value}
          ref={this.inputRef}
          readOnly
        />
        <button className={css.button} onClick={this.handleClick}>
          {this.state.isCopied ? 'Copied to clipboard' : 'Copy to clipboard'}
        </button>
      </div>
    )
  }
}

export default CopyToPasteboard
