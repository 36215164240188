import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { IconContext } from "react-icons";
import { FaAdjust } from 'react-icons/fa';
import { Center } from '@chakra-ui/react';
import css from './DarkModeSwitch.module.css';
import classNames from 'classnames';

const DarkModeSwitch = () => {
  const [searchParams,setSearchParams ] = useSearchParams();
  const isDark = (searchParams.get('dark') === "true") ?? false;
  const toggleDarkMode = () => {
    setSearchParams({dark: (!isDark).toString()});
  }
  return (
    <Center>
        <IconContext.Provider value={{ className: classNames(css['dark-mode-switch'], {[css['dark']]:isDark, [css['flip-horizontal']]:isDark})}}>
            <FaAdjust onClick={toggleDarkMode}/>
        </IconContext.Provider>
      </Center>
  );
}
export default DarkModeSwitch;
