import classNames from 'classnames'
import { format, isPast, parse } from 'date-fns'
import { observer } from 'mobx-react'
import React from 'react'

import ErrorStore from '../../stores/ErrorStore'
import reservationStore from '../../stores/ReservationStore'
import { AllotmentStatus } from '../../utils/constants'
import { getTime, isAfterDays, resolveStatus } from '../../utils/helpers'
import { DownTimer } from '../DownTimer'
import Radio from '../Radio/Radio'
import Status, { EnumStatusIconType } from '../Status/Status'
import css from './Departures.module.css'
import { useTranslation } from 'react-i18next'

const Departures = observer(() => {
  const { t } = useTranslation()

  const departures = reservationStore.departures.map((departure, index) => {
    const {
      journeyCode,
      departureCode,
      departurePortExtCode,
      customerAllotments,
      departureDatetime
    } = departure

    const portName =
      reservationStore.findPortByCode(departurePortExtCode || '').portName || ''

    const releaseTime = customerAllotments?.releaseTime

    return (
      <label
        key={index}
        className={classNames(css.item, {
          [css.selected]: reservationStore.departureCode === departureCode,
          [css.empty]: resolveStatus(departure) === AllotmentStatus.empty
        })}
      >
        <span className={classNames(css.time)}>
          {getTime(departureDatetime!)} &nbsp;
          <span>{portName}</span>
          {departureCode === reservationStore.bookedDeparture && (
            <span>&nbsp; Current</span>
          )}
        </span>

        {releaseTime ? (
          <DownTimer
            timeTo={releaseTime as unknown as Date}
            className={css.downTimer}
          />
        ) : (
          <span className={css.downTimer}>--</span>
        )}

        <Status
          iconType={EnumStatusIconType.circle}
          className={css.icon}
          departure={departure}
        />
        <span className={css.allotment}>
          {customerAllotments?.allotments?.highSpaceAllotment?.availableLength ||
            '--'}
        </span>
        <Radio
          value={departureCode}
          checked={reservationStore.departureCode === departureCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            reservationStore.setJourneyCode(journeyCode || '')
            reservationStore.setSelectedDeparture(e.target.value)
          }}
          disabled={
            isAfterDays(parse(departureDatetime!)) ||
            (departureCode !== reservationStore.bookedDeparture &&
              (isPast(parse(departureDatetime!)) ||
                !customerAllotments ||
                customerAllotments.allotments!.highSpaceAllotment!
                  .availableLength === 0 ||
                ErrorStore.isLoading))
          }
        />
      </label>
    )
  })

  return (
    <div
      className={classNames(css.select, {
        [css.isLoading]: ErrorStore.isLoading
      })}
    >
      <div className={css.selectHeader}>{t('departures')}</div>
      <div className={css.selectDate}>
        {format(reservationStore.selectedDate, t('date_format'))}
      </div>
      <div
        className={classNames({
          [css.limited]: isAfterDays(reservationStore.selectedDate)
        })}
      >
        <ul className={css.list}>{departures}</ul>
      </div>
    </div>
  )
})

export default Departures
