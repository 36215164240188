import {
  FormControl,
  FormLabel,
  FormLabelProps,
  Select,
  SelectProps,
  Stack
} from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import BaseFormHelperText from '../BaseFormHelperText'

export type SelectItem = { value: string; label: React.ReactNode; data?: any }

type Props = {
  labelProps?: FormLabelProps
  label?: React.ReactNode
  name?: string
  helperText?: string
} & SelectProps

const BaseSelect = forwardRef<HTMLSelectElement, Props>((props, ref) => {
  const {
    label,
    helperText,
    children,
    name,
    value,
    onBlur,
    onChange,
    labelProps,
    ...rest
  } = props

  return (
    <FormControl isInvalid={props.isInvalid} isRequired={rest.isRequired}>
      {label && (
        <FormLabel
          htmlFor={name}
          {...labelProps}
          requiredIndicator={React.isValidElement(label) ? <></> : undefined}
        >
          {label}
        </FormLabel>
      )}
      <Stack direction="row">
        <Select
          id={name}
          name={name}
          {...rest}
          onChange={onChange}
          value={value}
          ref={ref}
        >
          {children}
        </Select>
      </Stack>
      {helperText && (
        <BaseFormHelperText invalid={props.isInvalid}>
          {helperText}
        </BaseFormHelperText>
      )}
    </FormControl>
  )
})

export default BaseSelect
